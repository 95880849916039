import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN, NODE_ENV } from "../config/env";
import { getAppVersion } from "./helper";
import { ENVIRONMENT } from "../constants/Enums";

mixpanel.init(MIXPANEL_TOKEN, {
  debug: true,
  ignore_dnt: true,
  // track_pageview: true,
});

const envCheck = NODE_ENV === ENVIRONMENT.PRODUCTION;

type registerTypes = {
  name: string;
  walletAddress: string;
};
const MixPanel = {
  identify: (id: string) => {
    if (envCheck) mixpanel.identify(id);
  },
  register: async ({ name, walletAddress }: registerTypes) => {
    const version = await getAppVersion();

    if (envCheck)
      mixpanel.register({
        name,
        walletAddress,
        origin: window.location.origin,
        version,
      });
  },
  alias: (id: string) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: async (name: string, props?: { [key: string]: any }) => {
    const version = await getAppVersion();

    if (envCheck) {
      mixpanel.track(name, {
        ...props,
        origin: window.location.origin,
        version,
      });
    }
  },
  reset: () => {
    if (envCheck) mixpanel.reset();
  },
};

export default MixPanel;
