import { SupportedChainType } from "./Types";

const supportedChains: SupportedChainType[] = [
  {
    name: "Ethereum",
    chainId: 1,
    rpc: "https://mainnet.infura.io/v3/de9db908d55647368373fdb42546104e",
    wssRpc: "wss://mainnet.infura.io/ws/v3/de9db908d55647368373fdb42546104e",
    nativeAsset: "ETH",
    chainUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    coinUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    bundlerUrl:
      "https://bundler.biconomy.io/api/v2/1/nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44",
    paymasterUrl:
      "https://paymaster.biconomy.io/api/v1/1/4nUU0EmQ6.2c5c625b-dc68-4ad4-8d05-0e7a399590fd",
    paymasterApiKey: "4nUU0EmQ6.2c5c625b-dc68-4ad4-8d05-0e7a399590fd",
    isMainnet: true,
    isL2: false,
    isERC20GasPaymentSupported: true,
    isEnabled: false,
    isGaslessEnabled: false,
    explorerUrl: "https://etherscan.io",
    accentColor: "#828384",
    isOnlyEoa: false,
  },
  {
    name: "Ethereum Sepolia",
    chainId: 11155111,
    rpc: "https://sepolia.infura.io/v3/de9db908d55647368373fdb42546104e",
    wssRpc: "wss://sepolia.infura.io/ws/v3/de9db908d55647368373fdb42546104e",
    nativeAsset: "ETH",
    chainUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    coinUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    bundlerUrl:
      "https://bundler.biconomy.io/api/v2/11155111/nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44",
    paymasterUrl:
      "https://paymaster.biconomy.io/api/v1/11155111/sQUWHyuKI.a97c623a-722c-4be2-9ff0-765925570fe8",
    paymasterApiKey: "sQUWHyuKI.a97c623a-722c-4be2-9ff0-765925570fe8",
    isMainnet: false,
    isL2: false,
    isERC20GasPaymentSupported: false,
    isEnabled: false,
    isGaslessEnabled: false,
    explorerUrl: "https://sepolia.etherscan.io",
    accentColor: "#828384",
    isOnlyEoa: false,
  },
  {
    name: "Polygon",
    chainId: 137,
    rpc: "https://polygon-rpc.com",
    wssRpc:
      "wss://polygon-mainnet.infura.io/ws/v3/de9db908d55647368373fdb42546104e",
    nativeAsset: "MATIC",
    chainUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    coinUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    bundlerUrl:
      "https://bundler.biconomy.io/api/v2/137/dewj2189.wh1289hU-7E49-45ic-af80-D6TABYHqW",
    paymasterUrl:
      "https://paymaster.biconomy.io/api/v1/137/nsGpvVdKf.af2d01df-489e-497c-90f4-34cbde1ba2d9",
    paymasterApiKey: "nsGpvVdKf.af2d01df-489e-497c-90f4-34cbde1ba2d9",
    isMainnet: true,
    isL2: false,
    isERC20GasPaymentSupported: true,
    isEnabled: true,
    isGaslessEnabled: true,
    explorerUrl: "https://polygonscan.com",
    accentColor: "#7342dc",
    isOnlyEoa: false,
  },
  {
    name: "Polygon Amoy",
    chainId: 80002,
    rpc: "https://polygon-amoy.infura.io/v3/cb2d379f86e54f5f923eae4b837290ef",
    wssRpc:
      "wss://polygon-amoy.infura.io/ws/v3/cb2d379f86e54f5f923eae4b837290ef",
    nativeAsset: "MATIC",
    chainUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    coinUri: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    bundlerUrl:
      "https://bundler.biconomy.io/api/v2/80002/nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44",
    paymasterUrl:
      "https://paymaster.biconomy.io/api/v1/80002/zEkQWuXsX.4a01fe37-c3db-4700-9ab3-fa9659fc2b31",
    paymasterApiKey: "zEkQWuXsX.4a01fe37-c3db-4700-9ab3-fa9659fc2b31",
    isMainnet: false,
    isL2: false,
    isERC20GasPaymentSupported: true,
    isEnabled: true,
    isGaslessEnabled: true,
    explorerUrl: "https://www.oklink.com/amoy",
    accentColor: "#7342dc",
    isOnlyEoa: false,
  },
];

export default supportedChains;
