import { useEffect, useState } from "react";
import { getTokenBalance } from "../utils/helper";
import { SelectedTokenForGasType, GasTokenCardType } from "../constants/Types";
import gas from "../assets/gas.svg";
import { NATIVE_ADDRESS } from "../constants/Enums";
import useWalletConfig from "../lib/store/hooks/useWalletConfig";
import IllustrationLoader from "./IllustrationLoader";

const GasTokenCard = ({
  tokenAddress,
  tokenGas,
  tokenGasValue,
  tokenLogo,
  tokenName,
  tokenSymbol,
  tokenBalance,
  updateGasToken,
  selectedTokenForGas,
}: GasTokenCardType) => {
  const tokenData: SelectedTokenForGasType = {
    icon: tokenLogo,
    tokenName,
    tokenSymbol,
    tokenAddress,
    tokenGasValue,
  };

  const [balance, setBalance] = useState<any>(null);
  const { provider, smartAccountAddress } = useWalletConfig();

  useEffect(() => {
    async function getBalance() {
      if (tokenAddress === NATIVE_ADDRESS) {
        return;
      }

      if (provider && tokenAddress) {
        const currentTokenBalance = await getTokenBalance(
          tokenAddress,
          provider,
          smartAccountAddress,
        );

        setBalance(currentTokenBalance);
      }
    }

    getBalance();
  }, [provider]);

  return (
    <>
      <div
        onClick={() => {
          if (balance !== "0.0" || tokenBalance > 0) {
            updateGasToken(tokenData);
          }
        }}
        className={` ${
          tokenAddress === selectedTokenForGas.tokenAddress
            ? "bg-card-bg bg-opacity-20 border-l-4 border-card-bg2"
            : ""
        } flex 
        ${
          balance === "0.0" || balance === 0
            ? "opacity-30"
            : "hover:bg-card-bg2 hover:bg-opacity-50"
        }  mt-2 py-2 px-2 pr-4  `}
      >
        <div className="w-[20%] flex justify-center items-center">
          <img className="h-8" src={tokenLogo} alt="tokenImage" />
        </div>
        <div className="w-[80%] flex  justify-between items-center ">
          <div>
            <p className="text-sm">{tokenSymbol}</p>
            <p className="text-xs">
              {tokenAddress === NATIVE_ADDRESS ? (
                <>{Number(tokenBalance).toFixed(5)}</>
              ) : (
                <>
                  {balance === null ? (
                    <div className="w-fit mx-auto">
                      <IllustrationLoader height="1.2em" />
                    </div>
                  ) : (
                    <>
                      {Number(balance) === 0 ? (
                        "0"
                      ) : (
                        <>{Number(balance).toFixed(5)}</>
                      )}
                    </>
                  )}
                </>
              )}
            </p>
          </div>
          <div className="flex flex-col items-end">
            <p className="text-sm">
              {tokenGasValue === 0 ? (
                <></>
              ) : (
                <>{`$${Number(tokenGasValue).toFixed(5)}`}</>
              )}
            </p>
            <>
              {tokenGasValue === 0 ? (
                <>
                  <p className="flex item-center text-sm gap-1 ">
                    <img className="h-4  my-auto" src={gas} alt="gasCanImage" />
                    {tokenGas > 0 && <>{Number(tokenGas).toFixed(6)}</>}
                  </p>
                </>
              ) : (
                <>
                  <p className="flex item-center text-xs gap-1 ">
                    <img className="h-4 " src={gas} alt="gasCanImage" />
                    {tokenGas > 0 && <>{Number(tokenGas).toFixed(8)}</>}
                  </p>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default GasTokenCard;
