export const ERROR_MESSAGES = {
  LENS_NOT_FOUND: "Please enter a valid lens id",
  ENS_NOT_FOUND: "Please enter a valid ens id",
  ENS_NOT_SUPPORTED: "Network does not support ENS",
  LENS_NOT_SUPPORTED: "Network does not support LENS",
  INVALID_LENS_ENS: "Please enter a valid ens or lens id",
  LENS_ENS_NOT_SUPPORTED: "ENS/Lens handle not supported",
  INVALID_USER_ADDRESS: "Please enter a valid address",
  ONE_ID_NOT_REGISTERED: "No user associated with this ONE ID",
  ONE_ID_NOT_FOUND: "ONE ID not found",
};

export const SUCCESS_MESSAGES = {};
