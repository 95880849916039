import { useState, useEffect, useRef, RefObject } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import dappConnection from "../../../assets/illustrations/dapp-connection.png";
import gasless from "../../../assets/illustrations/gasless.png";
import multiChain from "../../../assets/illustrations/multiChain.png";
import smartWallet from "../../../assets/illustrations/smart-wallets.png";
import stateOfArt from "../../../assets/illustrations/state-of-art.png";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import { checkExtensionApp } from "../../../utils/helper";

const WelcomePage = () => {
  const [shiftPressed, setShiftPressed] = useState(false);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [xPressed, setXPressed] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.showWelcome) setIsExploding(true);
    else navigate("/");
  }, []);

  const divRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const focusInterval = setInterval(() => {
      if (divRef.current && document.activeElement !== divRef.current) {
        divRef.current.focus();
      }
    }, 100);

    return () => clearInterval(focusInterval);
  }, []);

  const handleKeyDown = (event: any) => {
    if (event.key === "Shift") {
      setShiftPressed(true);
    }

    if (event.key === "Control") {
      setCtrlPressed(true);
    }

    if (event.key === "x") {
      setXPressed(true);
    }
  };

  const handleKeyUp = (event: any) => {
    if (event.key === "Shift") {
      setShiftPressed(false);
    }

    if (event.key === "Control") {
      setCtrlPressed(false);
    }

    if (event.key === "x") {
      setXPressed(false);
    }
  };

  const imgVariants1 = {
    hidden: { x: -100 },
    visible: {
      x: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
      translateY: [0, 20, -20, 0], // Vertical translation
      rotate: [0, 10, -10, 10, 0], // Rotation
    },
  };

  const imgVariants2 = {
    hidden: { x: -100, y: -100 },
    visible: {
      x: 0,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
      rotate: [0, 360], // Rotation
    },
  };

  const imgVariants3 = {
    hidden: { y: -100 },
    visible: {
      y: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
      rotate: [0, 20, -30, 360], // Rotation
    },
  };

  const imgVariants4 = {
    hidden: { x: 100, scale: 1.5 },
    visible: {
      x: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
      rotate: [-30, 0], // Rotation
    },
  };

  const imgVariants5 = {
    hidden: { x: 40, y: -100 },
    visible: {
      x: 0,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  return (
    <PageTransitionWrapper>
      <div
        ref={divRef}
        autoFocus
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        className="bg-primary-bg h-full w-full flex flex-col px-4 pt-6  focus:outline-none overflow-hidden"
      >
        {isExploding && (
          <ConfettiExplosion
            particleSize={6}
            height={597}
            width={350}
            className="fixed top-0 left-1/2"
            onComplete={() => setIsExploding(false)}
          />
        )}

        <div className="flex justify-center items-center gap-1 mt-5">
          <motion.div
            animate={{
              translateY: [0, 5, -5, 0],
              transition: {
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
              },
            }}
          >
            <motion.img
              src={multiChain}
              alt="multiChain"
              className="h-12 w-12 mt-4"
              variants={imgVariants1}
              initial="hidden"
              animate="visible"
            />
          </motion.div>
          <motion.div
            animate={{
              translateY: [0, -5, 5, 0],
              transition: {
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
              },
            }}
          >
            <motion.img
              src={stateOfArt}
              alt="stateOfArt"
              className="h-12 w-12 mb-4"
              variants={imgVariants2}
              initial="hidden"
              animate="visible"
            />
          </motion.div>
          <motion.div
            animate={{
              translateY: [0, 5, -5, 0],
              transition: {
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
              },
            }}
          >
            <motion.img
              src={gasless}
              alt="gasless"
              className="h-12 w-12 mt-4"
              variants={imgVariants3}
              initial="hidden"
              animate="visible"
            />
          </motion.div>

          <motion.div
            animate={{
              translateY: [0, -5, 5, 0],
              transition: {
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
              },
            }}
          >
            <motion.img
              src={smartWallet}
              alt="smartWallet"
              className="h-12 w-12 mb-4"
              variants={imgVariants4}
              initial="hidden"
              animate="visible"
            />
          </motion.div>

          <motion.div
            animate={{
              translateY: [0, 5, -5, 0],
              transition: {
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
              },
            }}
          >
            <motion.img
              src={dappConnection}
              alt="dappConnection"
              className="h-12 w-12 mt-4"
              variants={imgVariants5}
              initial="hidden"
              animate="visible"
            />
          </motion.div>
        </div>

        <p className="text-xl font-semibold text-center mt-10">
          Your Smart Wallet is ready!🎉
        </p>

        {checkExtensionApp() ? (
          <>
            <p className="text-sm w-[90%] mx-auto text-center my-8">
              To access your wallet just click WalletX from extension dropdown,
              or use the speedy keyboard shortcut
            </p>

            <div className="flex items-center justify-center gap-2 mt-2">
              <div className="border rounded-2xl">
                <p
                  className={`px-4 p-2 rounded-2xl border relative  duration-300 ${
                    shiftPressed
                      ? "bg-white bg-opacity-40 left-0 bottom-0"
                      : "bottom-1 left-1 bg-primary-bg"
                  }`}
                >
                  Shift
                </p>
              </div>
              +
              <div className="border rounded-2xl">
                <p
                  className={`px-4 p-2 rounded-2xl border relative  duration-300 ${
                    ctrlPressed
                      ? "bg-white bg-opacity-40 left-0 bottom-0"
                      : "bottom-1 left-1 bg-primary-bg"
                  }`}
                >
                  CTRL
                </p>
              </div>
              +
              <div className="border rounded-2xl">
                <p
                  className={`px-4 p-2 rounded-2xl border relative  duration-300 ${
                    xPressed
                      ? "bg-white bg-opacity-40 left-0 bottom-0"
                      : "bottom-1 left-1 bg-primary-bg"
                  }`}
                >
                  X
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="text-sm w-[90%] mx-auto text-center my-8">
              To quickly access your wallet, Goto to your browser settings and
              tap
            </p>

            <div className="flex items-center justify-center gap-2 mt-2">
              <div className="border rounded-2xl">
                <p
                  className={`px-4 p-2 rounded-2xl border relative  duration-300 ${
                    shiftPressed
                      ? "bg-white bg-opacity-40 left-0 bottom-0"
                      : "bottom-1 left-1 bg-primary-bg"
                  }`}
                >
                  Add to Home screen
                </p>
              </div>
            </div>
          </>
        )}
        <button
          onClick={() => navigate("/")}
          className="p-4 w-full mx-auto duration-200 rounded-[2rem] bg-white text-black hover:bg-opacity-80 mt-10"
        >
          Continue
        </button>
        {checkExtensionApp() && (
          <p className="text-xs w-[90%] mx-auto text-center mt-11">
            Shortcut is only usable in browser extension
          </p>
        )}
      </div>
    </PageTransitionWrapper>
  );
};

export default WelcomePage;
