import React from "react";
import { ArrowUpRight, ChevronRight, User } from "react-feather";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import twitterIcon from "../../assets/SocialIcons/twitter.svg";
import websiteIcon from "../../assets/SocialIcons/website.svg";
import discordIcon from "../../assets/SocialIcons/discord.svg";
import telegramIcon from "../../assets/SocialIcons/telegram.svg";

interface LoginScreenOptionsProps {
  showModal: boolean;
  toggleModal: () => void;
}

const LoginScreenOptions = ({
  showModal,
  toggleModal,
}: LoginScreenOptionsProps) => {
  const navigate = useNavigate();

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          animate={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          className="fixed inset-0 pb-10 z-50 flex items-end justify-center w-full transparent-bg"
          onClick={handleOutsideClick}
        >
          <motion.div
            initial={{ translateY: "110%" }}
            animate={{ translateY: 0 }}
            exit={{ translateY: "110%" }}
            transition={{ duration: 0.3 }}
            className=" w-[95%] bg-card-bg rounded-lg px-2 py-6"
          >
            <div className="flex flex-col justify-center items-center gap-6  p-1">
              <div
                onClick={() =>
                  navigate("/import-wallet", { state: { from: "login" } })
                }
                className="flex items-center w-full"
              >
                <div className="w-[10%] flex items-start justify-center">
                  <User className="opacity-50" size={20} />
                </div>
                <p className="ml-2 flex flex-col  text-sm tracking-wide font-medium">
                  <span>Recover your wallet</span>
                </p>
                <ChevronRight className="opacity-50 ml-auto" size={20} />
              </div>
              <a
                className="flex items-center w-full"
                href="https://getwalletx.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-[10%] flex items-start justify-center">
                  <img
                    className="h-6 opacity-50"
                    src={websiteIcon}
                    alt="Website Icon"
                  />
                </div>
                <p className="ml-2  text-sm tracking-wide font-medium">
                  GetWalletX.com
                </p>
                <ArrowUpRight className="opacity-50 ml-auto" size={20} />
              </a>
              <a
                className="flex items-center w-full"
                href="https://x.com/getWalletX"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-[10%] flex items-start justify-center">
                  <img
                    className="h-5 opacity-50"
                    src={twitterIcon}
                    alt="Twitter Icon"
                  />
                </div>
                <p className="ml-2  text-sm tracking-wide font-medium">
                  Twitter
                </p>
                <ArrowUpRight className="opacity-50 ml-auto" size={20} />
              </a>
              <a
                className="flex items-center w-full"
                href="https://t.me/getwalletx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-[10%] flex items-start justify-center">
                  <img
                    className="h-6 opacity-50"
                    src={telegramIcon}
                    alt="Twitter Icon"
                  />
                </div>
                <p className="ml-2  text-sm tracking-wide font-medium">
                  Telegram
                </p>
                <ArrowUpRight className="opacity-50 ml-auto" size={20} />
              </a>
              <a
                className="flex items-center w-full"
                href="https://discord.gg/syxevsSxct"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-[10%] flex items-start justify-center">
                  <img
                    className="h-7 opacity-50"
                    src={discordIcon}
                    alt="Discord Icon"
                  />
                </div>
                <p className="ml-2 text-sm tracking-wide font-medium">
                  Need help? Hop into Discord
                </p>
                <ArrowUpRight className="opacity-50 ml-auto" size={20} />
              </a>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoginScreenOptions;
