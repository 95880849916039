import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { useRecoilState } from "recoil";
import gasState from "../state/GasState";
import {
  GasTokenSelectionDrawerType,
  SelectedTokenForGasType,
} from "../constants/Types";
import Loader from "./common/Loader";
import { getCoinBalance } from "../utils/helper";
import GasTokenCard from "./GasTokenCard";
import { NATIVE_ADDRESS } from "../constants/Enums";
import useWalletConfig from "../lib/store/hooks/useWalletConfig";

const GasTokenSelectionDrawer = ({
  isOpen,
  updateGasToken,
  selectedTokenForGas,
  onClose,
  gasSupportedTokens,
  nativeAssetGas,
}: GasTokenSelectionDrawerType) => {
  const [gasData, setGasData] = useRecoilState(gasState);
  const drawer = useRef(null);
  const { smartAccountAddress, provider, chainData } = useWalletConfig();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    getCoinBalance(smartAccountAddress, provider, setBalance);
  }, [smartAccountAddress, provider]);

  useEffect(() => {
    const closeDrawerOnOutsideClick = (e: any) => {
      if (!(drawer.current as any).contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", closeDrawerOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    };
  });

  useEffect(() => {
    setGasData(gasSupportedTokens);
  });

  return (
    <>
      <div
        ref={drawer}
        className={`${
          isOpen ? "bottom-0" : " translate-y-full"
        }  absolute bottom-[-2] z-50 left-1/2 translate-x-[-50%]  w-[345px] bg-secondary-bg border-gray-300 text-white border rounded-t-3xl  mt-10  pt-2  transition duration-500  transform text-sm`}
      >
        <h1 className="text-center font-semibold text-md pt-3">
          Select Token For Gas
        </h1>

        <div className="mt-3 overflow-y-scroll h-[280px] w-full">
          {!gasData ? (
            <> </>
          ) : (
            <>
              {gasData.length === 0 ? (
                <div className="w-full h-full flex  justify-center items-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <GasTokenCard
                    tokenAddress={NATIVE_ADDRESS}
                    tokenBalance={balance}
                    tokenSymbol={chainData.nativeAsset}
                    tokenGasValue={0}
                    tokenGas={Number(nativeAssetGas)}
                    tokenLogo={chainData.coinUri}
                    tokenName={chainData.name}
                    tokenUID={v4()}
                    updateGasToken={(_tokenData: SelectedTokenForGasType) =>
                      updateGasToken(_tokenData)
                    }
                    selectedTokenForGas={selectedTokenForGas}
                  />
                  {gasData.map((token, index) => (
                    <div key={index}>
                      <GasTokenCard
                        tokenAddress={token.tokenAddress}
                        tokenGas={token.tokenGas}
                        tokenGasValue={token.tokenGasValue}
                        tokenLogo={token.tokenLogo}
                        tokenName={token.tokenName}
                        tokenSymbol={token.tokenSymbol}
                        tokenUID={token.tokenUID}
                        tokenBalance={0}
                        updateGasToken={(_tokenData: SelectedTokenForGasType) =>
                          updateGasToken(_tokenData)
                        }
                        selectedTokenForGas={selectedTokenForGas}
                        key={index}
                      />
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GasTokenSelectionDrawer;
