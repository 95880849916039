import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { X, MoreVertical, ChevronLeft, ChevronRight } from "react-feather";
import { PageTitleType } from "../constants/Types";
import { PAGE_TITLE_BUTTON_TYPE } from "../constants/Enums";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";

const PageTitle = ({
  title,
  leftButtonIcon,
  rightButtonIcon,
  leftDestinationRoute,
  rightDestinationRoute,
  leftButtonToolTip,
  rightButtonToolTip,
  leftButtonToolTipCss,
  rightButtonToolTipCss,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  backRouteState,
  onLeftButtonClick,
  onRightButtonClick,
}: PageTitleType) => {
  const navigate = useNavigate();

  const handleLeftButtonClick = () => {
    if (leftDestinationRoute) {
      navigate(leftDestinationRoute, {
        state: { back: backRouteState && backRouteState },
      });
    } else if (onLeftButtonClick) {
      onLeftButtonClick();
    }
  };

  const handleRightButtonClick = () => {
    if (rightDestinationRoute) {
      navigate(rightDestinationRoute);
    } else if (onRightButtonClick) {
      onRightButtonClick();
    }
  };
  const renderButtonContent = (
    btnType: PAGE_TITLE_BUTTON_TYPE,
    tooltipMsg: string,
    toolTipCss: string,
  ) => {
    const setButtonIcon = (): ReactElement => {
      if (btnType === PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT) {
        return <ChevronLeft size={25} />;
      }

      if (btnType === PAGE_TITLE_BUTTON_TYPE.ARROW_RIGHT) {
        return <ChevronRight size={25} />;
      }

      if (btnType === PAGE_TITLE_BUTTON_TYPE.X) {
        return <X size={25} />;
      }

      if (btnType === PAGE_TITLE_BUTTON_TYPE.HB) {
        return <MoreVertical size={25} />;
      }

      return <X size={25} />;
    };

    const hasTooltip = tooltipMsg !== "";

    return (
      <>
        {hasTooltip ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>{setButtonIcon()}</TooltipTrigger>
              <TooltipContent className={` ${toolTipCss}`}>
                <p className="whitespace-nowrap">{tooltipMsg}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <>{setButtonIcon()}</>
        )}
      </>
    );
  };

  return (
    <header className="mb-2 w-[92.5%] mx-auto h-14">
      <div className="relative h-full py-2 flex flex-row items-center text-white ">
        {leftButtonIcon !== PAGE_TITLE_BUTTON_TYPE.NONE && (
          <button
            className="absolute left-0  flex justify-center items-center"
            onClick={() => handleLeftButtonClick()}
            disabled={isLeftButtonDisabled}
          >
            {renderButtonContent(
              leftButtonIcon,
              leftButtonToolTip || "",
              leftButtonToolTipCss || "",
            )}
          </button>
        )}

        <h1 className="text-md font-semibold mx-auto tracking-wide">{title}</h1>

        {rightButtonIcon !== PAGE_TITLE_BUTTON_TYPE.NONE && (
          <button
            className="absolute right-0  flex justify-center items-center"
            onClick={() => handleRightButtonClick()}
            disabled={isRightButtonDisabled}
          >
            {renderButtonContent(
              rightButtonIcon,
              rightButtonToolTip || "",
              rightButtonToolTipCss || "",
            )}
          </button>
        )}
      </div>
    </header>
  );
};

export default PageTitle;
