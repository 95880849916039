import { useEffect, useState } from "react";
import EthProvider from "../../../dapp-connection/js/services/EthProvider";
import Store from "../../../dapp-connection/js/components/StoreComponent";
import logo from "../../../assets/tokens/eth.png";
import angleRight from "../../../assets/angle-right.svg";
import { validateBiometric } from "../../../hooks/functional-hooks";
import {
  getChainDetails,
  getItemFromStorage,
  setItemInStorage,
  getCoinBalance,
  getUserSettingsData,
  log,
} from "../../../utils/helper";
import { UserSettingsType } from "../../../constants/Types";
import { STORAGE_KEYS, DEVICE_AUTH_TYPE } from "../../../constants/Enums";
import Loader from "../../../components/common/Loader";
import VerifyPassword from "../../../components/Modals/VerifyPassword";
import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const SwitchNetwork = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const biometricAuth = validateBiometric();
  const [credId, setCredId] = useState<string>("");
  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE | null>(
    null,
  );
  const [activeSmartWallet, setActiveSmartWallet] =
    useState<UserSettingsType | null>(null);
  const {
    smartAccountAddress,
    provider,
    init,
    isInitialized,
    eoaAddress,
    userSettings,
  } = useWalletConfig();

  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();

  const chainIDFromStorage = getItemFromStorage(STORAGE_KEYS.NETWORK);
  const [chainDetails, setChainDetails] = useState(
    getChainDetails(chainIDFromStorage),
  );

  const storeData: any = Store.getTempState();

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          setActiveSmartWallet(usersSettings[userIndex]);
          const { authenticationType, biometrics } =
            usersSettings[userIndex].settings.security;

          setWalletAuthType(authenticationType);
          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [walletAuthType, eoaAddress]);

  useEffect(() => {
    const _chainDetails = getChainDetails(chainIDFromStorage);

    setChainDetails(_chainDetails);
  }, [chainIDFromStorage]);

  useEffect(() => {
    if (provider) getCoinBalance(smartAccountAddress, provider, setBalance);
  }, [provider, smartAccountAddress, chainIDFromStorage]);

  const rejectConnectionRequest = (reason: string | null = null) => {
    EthProvider.onCancel(reason);
  };

  useEffect(() => {
    const chain = getChainDetails(Number(storeData?.requestedChainId));

    if (!chain?.isEnabled) {
      rejectConnectionRequest("Chain not supported yet!");
    }
  });

  const handleNetworkSwitch = async () => {
    let isValid = false;

    if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
      isValid = await verifyPassword();
      return;
    } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
      isValid = true;
    }

    if (!isValid) {
      rejectConnectionRequest("Authentication Failed");
      return;
    }

    init({
      walletName: activeSmartWallet?.walletName || "",
      chainId: Number(storeData?.requestedChainId || 0),
    });
    await setItemInStorage(
      STORAGE_KEYS.NETWORK,
      Number(storeData?.requestedChainId),
    );

    setTimeout(() => {
      EthProvider.onAccept({
        address: userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
        chainId: Number(storeData?.requestedChainId),
        isConnected: true,
        balance,
        chainDetails: getChainDetails(Number(storeData?.requestedChainId)),
      });
    }, 500);
  };

  useEffect(() => {
    if (isInitialized && provider && smartAccountAddress) {
      setIsLoading(false);
    }
  }, [isInitialized, smartAccountAddress, provider]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-2 mx-auto w-[341px] h-[600px] text-white relative top-1/2 translate-y-[-50%]">
          <div className="w-[70%] p-2 px-6 mx-auto mt-2 flex justify-center items-center gap-2 border rounded-full">
            <span className="p-[2px] h-[2px] rounded-full bg-slate-300"></span>
            <p>{chainDetails?.name}</p>
          </div>
          <p className=" mt-8 text-center text-lg font-semibold">
            Allow this site to switch the network?
          </p>
          <p className="text-gray-500 mt-2 text-center text-sm">
            This will switch the selected network within WalletX to a previously
            added network:
          </p>

          <div className="mx-auto  mt-[5rem]  w-[70%] flex justify-between items-center this-one-gpt">
            <img
              src={chainDetails?.chainUri || logo}
              alt="logo"
              className="rounded-full h-16"
            />
            <div className="w-full flex items-center">
              <span className="w-full border border-gray-500"></span>
              <img
                src={angleRight}
                alt="logo"
                className="p-1 h-6 bg-blue-500 rounded-full"
              />
              <span className="w-full border border-gray-500"></span>
            </div>
            <img
              src={
                getChainDetails(Number(storeData?.requestedChainId))
                  ?.chainUri || logo
              }
              alt="logo"
              className="h-16 rounded-full"
            />
          </div>

          <div className="py-4 absolute left-0 bottom-0 w-full  border-t border-gray-500  flex justify-around gap-2">
            <button
              className="w-[46%] p-2 px-4  border hover:border-gray-500 shadow-lg rounded-lg"
              onClick={() => rejectConnectionRequest()}
            >
              Cancel
            </button>
            <button
              className="w-[46%] p-2 bg-gray-300 hover:bg-gray-500 shadow-lg text-black whitespace-nowrap rounded-lg"
              onClick={() => handleNetworkSwitch()}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
};

export default SwitchNetwork;
