import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { TransferStateType } from "../constants/Types";

const { persistAtom } = recoilPersist();

// eslint-disable-next-line import/prefer-default-export
export const transferState = atom<TransferStateType[]>({
  key: "transferState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
