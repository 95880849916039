import { useLocation } from "react-router-dom";
import { X } from "react-feather";
import { NFTAssetCardType } from "../../constants/Types";
import { getShortDisplayString } from "../../utils/helper";

const NFTAssetCard = ({
  nft,
  openAssetSelectionDrawer,
  openRemoveAssetModal,
}: NFTAssetCardType) => {
  const location = useLocation();
  const currentLocation = location.pathname;

  return (
    <>
      <div className="group flex relative flex-col gap-2 justify-center items-center w-full py-1">
        <div className="flex  w-[95%] place-self-end bg-card-bg2 border  border-black py-1  gap-2 rounded-lg px-2  h-12  justify-between  items-center z-20 ">
          <div
            onClick={() => {
              if (
                currentLocation !==
                "/dashboard/transaction/approve-transactions"
              ) {
                openAssetSelectionDrawer();
              }
            }}
            className="flex justify-center items-center gap-2 hover:cursor-pointer"
          >
            <img
              className="h-8  object-scale-down rounded-lg  "
              src={nft.imageUrl}
              alt="tokenIcon"
            />

            <div className="flex flex-col items-start z-10 leading-4 ">
              <p className="tracking-wide text-sm font-semibold ">
                {nft.collectionName} #{nft.id}
              </p>
              <p className="text-xs">
                <span className="mr-1">Token Address:</span>
                {getShortDisplayString(nft.address)}
              </p>
            </div>
          </div>
        </div>

        {currentLocation !== "/dashboard/transaction/approve-transactions" && (
          <button
            onClick={() => {
              openRemoveAssetModal();
            }}
            className="absolute group top-[-0.15em] right-[-0.1em] bg-neutral-500 border border-black hover:bg-[#b91c1c] font-semibold text-sm -z-10 group-hover:z-20  rounded-full "
          >
            <X size={11} color="black" />
          </button>
        )}

        <div className="absolute w-4 h-20 border-l-2 border-b rounded-bl-xl border-neutral-700 top-[-2.75em] left-[0.25em] z-10 "></div>
      </div>
    </>
  );
};

export default NFTAssetCard;
