import { useMemo } from "react";
import useWallet from "../hooks/useWallet";
import { getChainDetails, getItemFromStorage } from "../../../utils/helper";
import { STORAGE_KEYS } from "../../../constants/Enums";
import { SupportedChainType } from "../../../constants/Types";

const useWalletConfig = () => {
  const walletState = useWallet();

  const combinedWalletState = useMemo(() => {
    const provider = walletState.getProvider();
    const chainID =
      walletState.chainData?.chainId ||
      getItemFromStorage(STORAGE_KEYS.NETWORK);
    const chainDetails: SupportedChainType = getChainDetails(chainID);

    return {
      ...walletState,
      chainData: walletState.chainData || chainDetails,
      eoaAddress:
        walletState.eoaAddress || getItemFromStorage(STORAGE_KEYS.SIGNER),
      smartAccountAddress:
        walletState.smartAccountAddress ||
        getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT),
      provider,
      chainID,
    };
  }, [
    walletState.chainData,
    walletState.eoaAddress,
    walletState.eoaBalance,
    walletState.eoaPrivateKey,
    walletState.smartAccountAddress,
    walletState.smartAccountBalance,
    walletState.isConnected,
    walletState.isInitialized,
    walletState.userSettings,
    walletState.activeSmartAccountIndex,
    walletState.auth.isLoggedIn,
  ]);

  return combinedWalletState;
};

export default useWalletConfig;
