// components/Modals/VerifyPassword.tsx
import React, { useState, useEffect } from "react";
import { EyeOff, Eye, AlertTriangle } from "react-feather";
import { Button } from "../ui/button";

interface VerifyPasswordProps {
  isOpen: boolean;
  isVerifying: boolean;
  showPasswordError: boolean;
}

const VerifyPassword: React.FC<VerifyPasswordProps> = ({
  isOpen,
  isVerifying,
  showPasswordError,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setPassword("");
      setShowPassword(false);
    }
  }, [isOpen]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const handleConfirmClick = async () => {
    if (window.verifyPasswordCallback) {
      await window.verifyPasswordCallback.handleVerify(password);
    }
  };

  const handleClose = () => {
    if (window.verifyPasswordCallback) {
      window.verifyPasswordCallback.handleClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-primary-bg bg-opacity-80 z-50">
      <div className="relative flex flex-col gap-3 p-4 w-[90%] max-w-md justify-center items-center rounded-xl shadow-lg bg-secondary-bg">
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-md tracking-wide">Enter your Password</p>
          <p className="text-gray-500 text-sm">
            Continue with your current password
          </p>
        </div>
        <div className="relative mt-5 mb-2 w-full">
          {showPasswordError && password !== "" && (
            <div className="absolute right-2 -top-7 flex justify-end items-center text-sm gap-1 py-2">
              <AlertTriangle size={14} className="text-red-500" />
              <span className="text-red-500 text-xs">Wrong Password</span>
            </div>
          )}
          <div
            className={`flex border rounded-lg pl-3 pr-2 py-2 justify-between items-center ${
              password ? "opacity-95" : "opacity-60"
            }`}
          >
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              className="bg-transparent focus:outline-none w-full mr-2"
            />
            <span
              onClick={toggleShowPassword}
              className="transition-opacity duration-300"
            >
              {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
            </span>
          </div>
        </div>
        <div className="flex w-full justify-center items-center gap-2">
          <Button
            onClick={handleClose}
            className="group w-full transition-transform font-semibold tracking-wider bg-card-bg2 hover:bg-red-800"
            disabled={isVerifying}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            className="group w-full transition-transform font-semibold tracking-wider bg-green-500 hover:bg-green-500"
            disabled={isVerifying}
          >
            {isVerifying ? "Verifying..." : "Continue"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyPassword;
