import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown } from "react-feather";
import { UserSettingsType } from "../../constants/Types";
import { DEVICE_AUTH_TYPE } from "../../constants/Enums";

interface DropDownProps {
  listOfWallets: UserSettingsType[];
  setSelectedWallet: (wallet: UserSettingsType) => void;
}

const dropdownVariants = {
  open: { height: "auto" },
  closed: { height: 0 },
};

const DropDown: React.FC<DropDownProps> = ({
  listOfWallets,
  setSelectedWallet,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<UserSettingsType | null>(
    null,
  );

  const toggleDropdown = () => {
    if (listOfWallets.length === 0) return;

    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: UserSettingsType) => {
    setSelectedOption(option);
    setSelectedWallet(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full z-30">
      <AnimatePresence>
        <button
          onClick={toggleDropdown}
          className={`text-white bg-card-bg hover:bg-card-bg2 font-medium text-md rounded-lg  px-5 py-2 text-center flex justify-between items-center w-[102%] -ml-1 ${
            isOpen === true && "rounded-b-none"
          } `}
          type="button"
          key="buttonToOpenDropdown"
        >
          <span>
            {listOfWallets.length === 0
              ? "No Wallets Found"
              : selectedOption?.walletName || "Select Wallet"}
          </span>
          {listOfWallets.length > 0 && <ChevronDown />}
        </button>

        <motion.div
          key="motionDiv"
          variants={dropdownVariants}
          animate={isOpen ? "open" : "closed"}
          initial="closed"
          exit="closed"
          transition={{ duration: 0.3 }}
          className={`z-10 absolute bg-card-bg divide-y divide-gray-100 rounded-b-lg shadow overflow-y-scroll dropdown-scrollbar  w-[102%] h-fit -ml-1 ${
            selectedOption?.settings.security.authenticationType ===
            DEVICE_AUTH_TYPE.PASSKEY
              ? "max-h-[8em]"
              : "max-h-[13em]"
          }`}
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
            {listOfWallets.map((wallet, index: number) => (
              <li key={index}>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-card-bg2  text-white"
                  onClick={() => handleOptionClick(wallet)}
                >
                  {wallet.walletName}
                </a>
              </li>
            ))}
          </ul>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default DropDown;
