import React from "react";

const GradientBg = () => (
  <div className="absolute top-0 left w-full h-full bg-primary-bg ">
    <div className="relative h-full w-full">
      <div className="absolute top-[-1.5em] left-[-1.5em] bg-[#F9985F] h-[5em] w-[5em] rounded-full blur-xl opacity-60"></div>
      <div className="absolute top-[4em] right-[-1.5em] bg-[#8588E2] h-[9em] w-[12em] rounded-full blur-xl opacity-30"></div>
      <div className="absolute bottom-[-2em] left-[-2em] bg-[#F7D189] h-[7em] w-[7em] rounded-full blur-xl opacity-40"></div>
      <div className="absolute bottom-[10em] left-[2em] bg-[#F05640] h-[6em] w-[6em] rounded-full blur-xl opacity-20"></div>
      <div className="absolute bottom-[2em] right-[3em] bg-[#EBA5E0] h-[4em] w-[4em] rounded-full blur-xl opacity-50"></div>
    </div>
  </div>
);

export default GradientBg;
