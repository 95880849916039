import { motion, useCycle } from "framer-motion";
import { useMemo, useEffect } from "react";

type ToggleSwitchProps = {
  width: number;
  value: boolean;
  onChange: () => void;
  isDisable?: boolean;
};

const ToggleSwitch = ({
  width,
  value,
  onChange,
  isDisable,
}: ToggleSwitchProps) => {
  const [animate, cycle] = useCycle("off", "on");
  const height = useMemo(() => width * 0.55, [width]);

  useEffect(() => {
    if (value === true) {
      cycle(1);
    } else {
      cycle(0);
    }
  }, [value, cycle]);

  const handleClick = () => {
    if (!isDisable) {
      onChange();
    }
  };

  return (
    <motion.div
      className={`relative flex items-center cursor-pointer rounded-full ${
        isDisable ? "bg-gray-300" : "bg-white bg-opacity-30"
      }`}
      style={{ width: `${width}px`, height: `${height}px` }}
      onTapStart={handleClick}
      animate={animate}
    >
      <motion.div
        className={`absolute rounded-full ${
          isDisable ? "bg-green-300" : "bg-green-500 shadow-lg"
        }`}
        style={{ width: `${width}px`, height: `${height}px` }}
        variants={{ off: { scale: 0 }, on: { scale: 1 } }}
      ></motion.div>

      <motion.div
        className={`absolute rounded-full ${
          isDisable ? "bg-gray-100" : "bg-white border shadow-lg"
        }`}
        style={{
          width: `${width * 0.44}px`,
          height: `${height * 0.8}px`,
          left: `${width * 0.01}px`,
        }}
        variants={{
          off: { x: `${width * 0.1}px` },
          on: { x: `${width * 0.9 - width * 0.44}px` },
        }}
      ></motion.div>
    </motion.div>
  );
};

export default ToggleSwitch;
