/* eslint-disable security-node/detect-crlf */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import * as Config from "../../config/env.js";

export const log = (message, data = {}, type = "info") => {
  if (Config.NODE_ENV !== "development") return;

  if (type === "info") {
    console.info(message, data);
  } else if (type === "error") {
    console.error(message, data);
  } else if (type === "success") {
    console.log(message, data);
  } else if (type === "warning") {
    console.warn(message, data);
  }
};
