import { useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { RecoilRoot } from "recoil";
import { LensConfig, LensProvider, production } from "@lens-protocol/react-web";
import { bindings } from "@lens-protocol/wagmi";
import { Toaster } from "react-hot-toast";
import Moralis from "moralis";
import AppRoutes from "./routes";
import ConfigProvider from "./context/ConfigProvider";
import { NODE_ENV, SERVICES_ENV } from "./config/env";
import { setChains } from "./utils/helper";
import { ENVIRONMENT } from "./constants/Enums";

const lensConfig: LensConfig = {
  bindings: bindings(),
  environment: production,
};

function App() {
  const isMoralisInitialized = useRef(false);

  setChains([]);

  const handleRightClick = (e: any) => {
    if (NODE_ENV !== ENVIRONMENT.PRODUCTION) return;

    e.preventDefault();
  };

  useEffect(() => {
    if (!isMoralisInitialized.current) {
      Moralis.start({
        apiKey: SERVICES_ENV.MORALIS_NFT_API,
      });
      isMoralisInitialized.current = true;
    }

    window.addEventListener("contextmenu", handleRightClick);

    return () => {
      window.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  return (
    <BrowserRouter>
      <ConfigProvider>
        <RecoilRoot>
          <LensProvider config={lensConfig}>
            <AppRoutes />
            <Toaster
              toastOptions={{
                style: {
                  border: "1px solid white",
                  color: "white",
                  background: "#030712",
                  padding: "5px 8px",
                  fontSize: "1rem",
                },
              }}
            />
          </LensProvider>
        </RecoilRoot>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
