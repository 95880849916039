import PageTitle from "../../components/PageTitle";
import ActivityComponent from "../../components/DashboardComponents/Tabs/DashboardTabs/TabContent/Activity";
import { PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";

const Activity = () => (
  <div>
    <PageTitle
      title="Activity"
      leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
      rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
    />
    <ActivityComponent />
  </div>
);

export default Activity;
