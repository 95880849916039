import { useEffect, useState } from "react";
import { ethers } from "ethers";
import localforage from "localforage";
import toast from "react-hot-toast";

import { getTokenData, getItemFromStorage, log } from "../../utils/helper";
import { STORAGE_KEYS } from "../../constants/Enums";
import TokenCardTransaction from "../TokenCardTransaction";
import { Token } from "../../constants/Types";
import useWallet from "../../lib/store/hooks/useWallet";
import IllustrationLoader from "../IllustrationLoader";

const CustomToken = ({ onClose }: { onClose: () => void }) => {
  const [isValidAddress, setIsValidAddress] = useState<any>(null);
  const [tokenAddress, setTokenAddress] = useState<string>("");
  const [isValidTokenContract, setIsValidTokenContract] = useState<any>(null);

  const { smartAccountAddress, getProvider, chainData } = useWallet();
  const provider = getProvider();

  const smartAccountAddressInStorage = getItemFromStorage(
    STORAGE_KEYS.SMART_ACCOUNT,
  );
  const chainIDInStorage = getItemFromStorage(STORAGE_KEYS.NETWORK);
  const chainId = chainData?.chainId || chainIDInStorage;

  const [tokenData, setTokenData] = useState<Token>({
    address: "",
    symbol: "",
    name: "",
    logo: "",
    decimal: 0,
    balance: 0,
    amount: 0,
  });

  const isEthereumAddress = (address: string) => {
    try {
      return ethers.utils.isAddress(address);
    } catch (error) {
      return false;
    }
  };

  const handelContractAddressInput = async (e: any) => {
    const inputAddress = e.target.value;

    setTokenAddress(inputAddress);

    if (!isEthereumAddress(inputAddress)) {
      setIsValidAddress(false);
      return;
    }

    setIsValidAddress(true);
    setIsValidTokenContract("LOADING");

    const _tokenData = await getTokenData(
      inputAddress,
      provider,
      smartAccountAddress || smartAccountAddressInStorage,
    );

    if (!_tokenData) {
      setIsValidTokenContract(false);
    } else {
      setIsValidTokenContract(true);
      setTokenData({
        address: inputAddress,
        symbol: _tokenData.symbol,
        name: _tokenData.name,
        logo: `https://ui-avatars.com/api/?background=fff&color=000&rounded=true&bold=true&name=${_tokenData.symbol}`,
        decimal: _tokenData.decimals,
        balance: _tokenData.balance,
      });
    }
  };

  const resetForm = () => {
    setTokenData({
      address: "",
      symbol: "",
      name: "",
      logo: "",
      decimal: 0,
      balance: 0,
      amount: 0,
    });
    setTokenAddress("");
    setIsValidTokenContract(null);
    setIsValidAddress(null);
  };

  const addToken = async () => {
    try {
      // Get the current token data hash from storage
      const importedTokenData: Array<any> =
        (await localforage.getItem("importedTokenData")) || [];

      // Check if the token address already exists in the hash
      let tokenEntry = importedTokenData.find(
        (entry) =>
          entry.address.toLowerCase() === tokenData.address.toLowerCase(),
      );

      if (tokenEntry) {
        // If the token address exists, check if the chain ID exists
        if (tokenEntry[chainId]) {
          // Check if the token is already added for this chain
          if (
            tokenEntry[chainId].some(
              (token: Token) =>
                token.address.toLowerCase() === tokenData.address.toLowerCase(),
            )
          ) {
            toast.error("Token already added for this chain");
            return;
          }
          // If not, add the new token details to the existing chain

          tokenEntry[chainId].push(tokenData);
        } else {
          // If the chain doesn't exist, create a new array for this chain
          tokenEntry[chainId] = [tokenData];
        }
      } else {
        // If the token address doesn't exist, create a new entry
        tokenEntry = {
          address: tokenData.address,
          [chainId]: [tokenData],
        };
        importedTokenData.push(tokenEntry);
      }

      // Save the updated token data hash
      await localforage.setItem("importedTokenData", importedTokenData);

      toast.success("Token Added Successfully");
      onClose(); // Close the modal after adding the token
    } catch (error) {
      log("Error adding token:", error, "error");
      toast.error("Failed to add token");
    }

    resetForm();
  };

  useEffect(() => {
    setTokenData({
      address: "",
      symbol: "",
      name: "",
      logo: "",
      decimal: 0,
      balance: 0,
      amount: 0,
    });
  }, [isValidAddress]);

  const getButtonText = () => {
    if (isValidTokenContract === "LOADING") {
      return (
        <div className="flex justify-center">
          <IllustrationLoader height="1.4em" bgColor="bg-card-bg2" />
        </div>
      );
    }

    if (isValidAddress === false && tokenData.address) {
      return "Invalid Address";
    }

    if (isValidTokenContract === false || isValidAddress === false) {
      return "Invalid Token";
    }

    return "Add Token";
  };

  return (
    <>
      <div className="w-full">
        <div className="flex items-center w-[90%] mx-auto border border-gray-300 rounded-lg mt-2 mb-3 p-2">
          <input
            onChange={handelContractAddressInput}
            type="text"
            placeholder="Enter Token Address"
            className="w-full focus:outline-none  bg-transparent text-sm text-ellipsis"
            value={tokenAddress}
          />
        </div>
      </div>
      <div className=" w-full ">
        {tokenData.symbol && isValidTokenContract === true ? (
          <TokenCardTransaction
            tokenIcon={tokenData.logo}
            tokenName={tokenData.name}
            tokenSymbol={tokenData.symbol}
            tokenAddress={tokenData.address}
            userAddress={smartAccountAddressInStorage || smartAccountAddress}
            clickedTokenData={() => null}
          />
        ) : (
          <>
            <div className="font-medium mx-auto max-w-[80%] text-sm text-gray-500 text-center">
              Search using token address and then click on add token to add it
              to the token list
            </div>
          </>
        )}
      </div>

      <button
        disabled={!(isValidAddress === true && isValidTokenContract === true)}
        onClick={addToken}
        className={`w-full mt-5 bg-card-bg2 hover:bg-neutral-800 h-10 rounded-md px-4 py-2  ${
          isValidAddress === false ||
          isValidTokenContract === false ||
          tokenAddress === ""
            ? "opacity-50 cursor-not-allowed"
            : ""
        }`}
      >
        {getButtonText()}
      </button>
    </>
  );
};

export default CustomToken;
