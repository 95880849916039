import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { SupportedChainType } from "../constants/Types";

const { persistAtom } = recoilPersist();

// eslint-disable-next-line import/prefer-default-export
export const supportedChainsState = atom<SupportedChainType[]>({
  key: "supportedChainsState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
