import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import PageTitle from "../../../components/PageTitle";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";
import { UserSettingsType } from "../../../constants/Types";
import {
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../../utils/helper";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const DeveloperOptions = () => {
  const [showTestnet, setShowTestnet] = useState(false);
  const [turnOffAds, setTurnOffAds] = useState(false);
  const { eoaAddress } = useWalletConfig();

  useEffect(() => {
    async function getDeveloperOptions() {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const { showTestnet: showTestnetInDB, turnOffAds: turnOffAdsInDB } =
          usersSettings[userIndex].settings.developerMode;

        setShowTestnet(showTestnetInDB);
        setTurnOffAds(turnOffAdsInDB);
      }
    }
    getDeveloperOptions();
  }, [eoaAddress]);

  const updateUserSettings = async (
    updatedSettings: Partial<UserSettingsType["settings"]["developerMode"]>,
  ) => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        usersSettings[userIndex].settings.developerMode = {
          ...usersSettings[userIndex].settings.developerMode,
          ...updatedSettings,
        };
        await setUserSettingsData(usersSettings);
      }
    } catch (error) {
      log("Error updating user settings:", error);
    }
  };

  const toggleTestnet = async () => {
    const newValue = !showTestnet;

    setShowTestnet(newValue);
    if (!newValue) {
      setTurnOffAds(false);
      await updateUserSettings({
        showTestnet: newValue,
        turnOffAds: false,
      });
    } else {
      await updateUserSettings({ showTestnet: newValue });
    }
  };

  const toggleAds = async () => {
    const newValue = !turnOffAds;

    if (showTestnet) {
      setTurnOffAds(newValue);
      await updateUserSettings({ turnOffAds: newValue });
    } else {
      toast.error("Ads turned off on testnet");
    }
  };

  return (
    <PageTransitionWrapper>
      <div className="relative h-full ">
        <PageTitle
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          leftDestinationRoute="/dashboard/settings"
          title="Developer Options"
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          backRouteState={true}
        />

        <div className="px-2">
          <div
            className={`flex justify-between items-center gap-1 text-gray-100  hover:bg-card-bg  cursor-pointer rounded-lg pl-4  pr-2 py-3  w-full`}
          >
            <div className="max-w-[75%]">
              <h1 className="font-medium text-md">Testnet</h1>
              <p className="text-xs text-gray-500">
                Make testnet visible in the chain selection modal.
              </p>
            </div>

            <ToggleSwitch
              width={40}
              value={showTestnet}
              onChange={toggleTestnet}
            />
          </div>
          <div
            className={`flex justify-between items-center gap-1 text-gray-100  hover:bg-card-bg  cursor-pointer rounded-lg pl-4  pr-2 py-3  w-full`}
          >
            <div className="max-w-[75%]">
              <h1 className="font-medium text-md">Turn Off Ads</h1>
              <p className="text-xs text-gray-500">
                Turn Off Ads on testnets for developer experience.
              </p>
            </div>

            <ToggleSwitch
              width={40}
              value={turnOffAds}
              onChange={toggleAds}
              isDisable={!showTestnet}
            />
          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default DeveloperOptions;
