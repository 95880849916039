import { useState, useEffect } from "react";
import { X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { client } from "@passwordless-id/webauthn";
import { v4 } from "uuid";
import { Button } from "../ui/button";
import { DEVICE_AUTH_TYPE } from "../../constants/Enums";
import { UserSettingsType } from "../../constants/Types";
import {
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../utils/helper";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

interface AddPasswordAndPasskeysProps {
  isModalOpen: boolean;
  onClose: () => void;
  modalType: DEVICE_AUTH_TYPE;
  isPasskeysEnabled: boolean;
  isPasswordEnabled: boolean;
}

const AddPasswordAndPasskeys = ({
  isModalOpen,
  onClose,
  modalType,
  isPasskeysEnabled,
  isPasswordEnabled,
}: AddPasswordAndPasskeysProps) => {
  const navigate = useNavigate();
  const { eoaAddress, smartAccountAddress } = useWalletConfig();

  const [isPasswordNull, setIsPasswordNull] = useState<boolean>(true);
  const [isPasskeysNull, setIsPasskeysNull] = useState<boolean>(true);
  const [authType, setAuthType] = useState<DEVICE_AUTH_TYPE>(
    DEVICE_AUTH_TYPE.NONE,
  );

  const registerDevice = async () => {
    try {
      const challenge = v4();

      const registration = await client.register(
        smartAccountAddress,
        challenge,
        {
          authenticatorType: "both",
          userVerification: "required",
          timeout: 60000,
          attestation: false,
          debug: false,
        },
      );

      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        usersSettings[userIndex].settings.security.biometrics =
          registration?.credential.id;

        if (
          usersSettings[userIndex].settings.security.authenticationType ===
          DEVICE_AUTH_TYPE.NONE
        ) {
          usersSettings[userIndex].settings.security.authenticationType =
            DEVICE_AUTH_TYPE.PASSKEY;
        } else if (
          usersSettings[userIndex].settings.security.authenticationType ===
          DEVICE_AUTH_TYPE.PASSWORD
        ) {
          usersSettings[userIndex].settings.security.authenticationType =
            DEVICE_AUTH_TYPE.BOTH;
        }

        await setUserSettingsData(usersSettings);
      }
    } catch (error) {
      toast.error(
        "The operation either timed out or was not allowed. Please authenticate with password.",
      );
    }
  };

  const addPasskeys = async () => {
    await registerDevice();
    onClose();
  };

  const getButtonText = () => {
    let buttonText = "";

    if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
      buttonText = isPasswordNull ? "Add Password" : "Enable Password";
    } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
      buttonText = isPasskeysNull ? "Add Passkey" : "Enable Passkey";
    }

    return buttonText;
  };

  const handleEnablePasswordAndPasskeys = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const currentAuthType =
          usersSettings[userIndex].settings.security.authenticationType;

        if (modalType === DEVICE_AUTH_TYPE.PASSWORD && !isPasswordNull) {
          if (currentAuthType === DEVICE_AUTH_TYPE.NONE) {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.PASSWORD;
          } else if (currentAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.BOTH;
          }
        } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY && !isPasskeysNull) {
          if (currentAuthType === DEVICE_AUTH_TYPE.NONE) {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.PASSKEY;
          } else if (currentAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.BOTH;
          }
        }

        await setUserSettingsData(usersSettings);
        onClose();
      }
    } catch (error) {
      log("Error enabling password and passkeys:", error);
    }
  };

  useEffect(() => {
    const fetchSecuritySettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();
        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { password, biometrics, authenticationType } =
            usersSettings[userIndex].settings.security;

          setAuthType(authenticationType);
          setIsPasswordNull(!password);
          setIsPasskeysNull(!biometrics);
        }
      } catch (error) {
        log("Error fetching security settings:", error);
      }
    };

    fetchSecuritySettings();
  }, [
    eoaAddress,
    isPasswordNull,
    isPasskeysNull,
    authType,
    modalType,
    isPasskeysEnabled,
    isPasswordEnabled,
  ]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-primary-bg bg-opacity-50 z-50 flex justify-center items-center">
          <div className="relative flex flex-col gap-3 p-4 w-[90%] mx-auto justify-center items-center rounded-xl shadow-lg bg-secondary-bg">
            <div className="mb-2">
              <p className="text-md tracking-wide">
                Enable
                {modalType === DEVICE_AUTH_TYPE.PASSWORD
                  ? " Password "
                  : " Passkey "}
              </p>
              <X
                className="absolute top-4 right-2 hover:opacity-70"
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="relative w-full text-center text-gray-500 text-xs">
              {`Enable ${
                modalType === DEVICE_AUTH_TYPE.PASSWORD
                  ? " Password"
                  : " Passkey"
              } to make your wallet more secure and also you can use it as an added authentication for your transactions.`}
            </div>
            <Button
              onClick={() => {
                if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
                  if (isPasswordNull) {
                    navigate("/settings/security/add-password");
                  } else {
                    handleEnablePasswordAndPasskeys();
                  }
                } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
                  if (isPasskeysNull) {
                    addPasskeys();
                  } else {
                    handleEnablePasswordAndPasskeys();
                  }
                }
              }}
              className="group w-full transition-transform bg-card-bg2 hover:bg-card-bg2"
            >
              {getButtonText()}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPasswordAndPasskeys;
