import React from "react";
import { ethers } from "ethers";
import PageTitle from "../../../components/PageTitle";
import Web3AvatarComponent from "../../../components/Web3AvatarComponent";
import GasManagement from "../../../components/GasManagement";
import VerifyPassword from "../../../components/Modals/VerifyPassword.tsx";
import arrowRight from "../../../assets/arrow-right-white.svg";
import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";
import Loader from "../../../components/common/Loader";

const TransactionReview = ({
  isLoading,
  smartWalletAddress,
  transactionData,
  storeData,
  chainDetails,
  handleReject,
  transactionId,
  transactionInProcess,
  progress,
  transactionGas,
  setTransactionGas,
  partialTransaction,
  sendGaslessTransaction,
  sendBatchTransaction,
  nativeAssetGas,
  shouldShowAd,
  gasInUSD,
  isVerifyPassModalOpen,
  isVerifying,
  showPasswordError,
}) => {
  const getShortDisplayString = (address) =>
    `${address.slice(0, 6)}...${address.slice(-4)}`;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <PageTitle
            title="Review"
            leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
            leftButtonToolTip=""
            leftButtonToolTipCss=""
            leftDestinationRoute=""
            isLeftButtonDisabled={true}
            rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.X}
            rightButtonToolTip="Cancel"
            rightButtonToolTipCss="fixed top-0 right-5 bg-secondary-bg text-white border-black"
            isRightButtonDisabled={false}
            onRightButtonClick={() => handleReject(null)}
          />

          <div className="flex justify-around items-center p-1">
            <div className="flex gap-2 items-center">
              <Web3AvatarComponent
                address={smartWalletAddress}
                className="w-6 h-6"
              />
              <p>{getShortDisplayString(smartWalletAddress)}</p>
            </div>
            <div className="p-1 border-2 rounded-full">
              <img className="h-4 w-4" src={arrowRight} alt="arrowRight" />
            </div>
            <div className="flex gap-2 items-center">
              <Web3AvatarComponent
                address={transactionData.to}
                className="w-6 h-6"
              />
              <p>{getShortDisplayString(transactionData.to)}</p>
            </div>
          </div>

          <hr className="border border-slate-800 my-2" />

          <div className="flex items-center justify-between mt-2 px-2">
            <div className="w-fit py-2">
              <p className="text-sm">{storeData?.connect_origin}</p>
              <p className="px-3 py-1 mt-2 border text-slate-500 border-slate-500 rounded text-sm">
                CONTRACT INTERACTION
              </p>
            </div>
            <div className="flex flex-grow items-center justify-center">
              <img
                className="h-10 w-10"
                src={chainDetails?.coinUri}
                alt="profile"
              />
            </div>
          </div>

          <hr className="border border-dashed border-slate-800 my-2" />

          <div className="px-2 mt-4 overflow-y-scroll max-h-[227px]">
            <div className="flex items-center justify-between gap-4">
              <p>Data</p>
              <textarea
                className="px-2 py-1 rounded text-left border-[0.5px] bg-transparent"
                disabled
                placeholder="0x"
                value={transactionData?.data}
              />
            </div>

            <div className="flex text-right items-center justify-between mt-4">
              <p>Amount</p>
              <div className="flex gap-2 flex-col">
                <p>
                  {transactionData.value
                    ? Number(
                        ethers.utils.formatEther(transactionData.value),
                      ).toFixed(5)
                    : 0}
                </p>
                <p>
                  {transactionData.value
                    ? Number(
                        ethers.utils.formatEther(transactionData.value),
                      ).toFixed(5)
                    : 0}{" "}
                  {chainDetails?.nativeAsset}
                </p>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 w-full left-0 mb-2">
            <GasManagement
              transaction={{
                transactionId,
                transactionInProcess,
                progressBar: progress,
              }}
              selectedTokenForGas={transactionGas}
              setSelectedTokenForGas={setTransactionGas}
              isDappTransaction={true}
              transferDataList={[transactionData]}
              partialTransaction={partialTransaction}
              sendGaslessTransaction={sendGaslessTransaction}
              sendBatchTransaction={sendBatchTransaction}
              nativeAssetGas={nativeAssetGas}
              shouldShowAd={shouldShowAd}
              gasInUSD={gasInUSD}
            />
          </div>
        </div>
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
};

export default TransactionReview;
