import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeOff, Eye, Shield, AlertTriangle } from "react-feather";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";
import { UserSettingsType } from "../../constants/Types";
import {
  PAGE_TITLE_BUTTON_TYPE,
  DEVICE_AUTH_TYPE,
} from "../../constants/Enums";
import {
  getUserSettingsData,
  setUserSettingsData,
  log,
} from "../../utils/helper";
import PageTitle from "../../components/PageTitle";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const isPasswordStrong = (password: string): boolean => {
  // Basic strength check: at least 8 characters, one uppercase, one lowercase, one number
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  return regex.test(password);
};

const Password = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const { eoaAddress } = useWalletConfig();
  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const encryptPassword = async (
    walletAddress: string,
    userPassword: string,
  ) => {
    const encryptedAddress = CryptoJS.AES.encrypt(
      walletAddress,
      userPassword,
    ).toString();

    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        usersSettings[userIndex].settings.security.password = encryptedAddress;
        usersSettings[userIndex].settings.security.authenticationType =
          DEVICE_AUTH_TYPE.PASSWORD;
        await setUserSettingsData(usersSettings);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  const handleSubmit = async () => {
    await encryptPassword(eoaAddress, password);
    setPassword("");
    setConfirmPassword("");
    toast.success("Password set successfully");
    navigate("/settings/security");
  };

  useEffect(() => {
    setIsPasswordMatch(() => password === confirmPassword);
    setIsPasswordValid(
      () => isPasswordStrong(password) && password.length >= 8,
    );
  }, [password, confirmPassword]);

  return (
    <>
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        leftDestinationRoute="/settings/security"
        title="Add Password"
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
      />
      <div className="px-2">
        <p className=" px-2 text-wrap text-center text-gray-300 text-sm">
          Set a password to unlock your wallet each time you use your computer.
          And also use it as added security for your transactions. <br /> It
          can't be used to recover your wallet.
        </p>
        <div className="px-2 my-3">
          <div className="my-5">
            <div className="flex justify-between items-center">
              <p className="text-sm font-bold my-2 ml-1"> Password</p>
              <div className="flex items-center text-sm px-2">
                {password === "" ? (
                  <span className="text-gray-500">8 min characters</span>
                ) : (
                  <>
                    {isPasswordValid && (
                      <div className=" flex justify-center items-center gap-1">
                        <span className="text-green-500">Strong</span>
                        <Shield size={16} className="text-green-500 " />
                      </div>
                    )}
                    {!isPasswordValid && (
                      <div className=" flex justify-center items-center gap-1">
                        <span className="text-red-500">Weak</span>
                        <AlertTriangle size={16} className="text-red-500 " />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              className={`flex border-2 rounded-xl pl-3 pr-2 py-3 justify-between items-center ${
                password ? "opacity-100" : "opacity-40"
              }`}
            >
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={handlePasswordChange}
                className=" bg-transparent focus:outline-none w-full mr-2 "
              />

              <span
                onClick={toggleShowPassword}
                className="transition-opacity duration-300"
              >
                {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
              </span>
            </div>
          </div>
          <div className="my-5">
            <p className="text-sm font-bold my-2 ml-1">Confirm Password</p>
            <div
              className={`flex border-2 rounded-xl pl-3 pr-2 py-3 justify-between items-center ${
                confirmPassword ? "opacity-100" : "opacity-40"
              }`}
            >
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className=" bg-transparent focus:outline-none w-full mr-2 "
              />

              <span
                onClick={toggleShowConfirmPassword}
                className="transition-opacity duration-300"
              >
                {showConfirmPassword ? <Eye size={20} /> : <EyeOff size={20} />}
              </span>
            </div>
            {password !== confirmPassword && confirmPassword !== "" && (
              <span className="text-gray-500 text-sm">
                Passwords do not match
              </span>
            )}
          </div>
        </div>
        <button
          disabled={!isPasswordMatch && password.length >= 8}
          className={`p-4 w-[90%] absolute left-1/2 translate-x-[-50%]  bottom-6 duration-200 rounded-full bg-white text-gray-950 font-semibold tracking-wider  mt-3 ${
            !isPasswordMatch || password === "" || password.length < 8
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-opacity-80"
          }`}
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default Password;
