import { motion } from "framer-motion";
import { HiOutlineExternalLink } from "react-icons/hi";
import { DappConnectionsCardType } from "../../constants/Types";

const ConnectionsCard = ({
  name,
  hostname,
  icon,
  session,
  connectedWallet,
  disconnectDapp,
}: DappConnectionsCardType) => (
  <>
    <motion.div className=" w-full h-[70px] flex bg-card-bg rounded-xl group overflow-hidden cursor-default select-none">
      <div className=" w-[25%] h-full flex justify-center items-center">
        <img alt="" src={icon} className="w-11 h-11 bg-white rounded-full" />
      </div>
      <div className=" w-[45%] h-full flex flex-col justify-center items-start ">
        <div className="w-[90%] h-[55%] overflow-hidden whitespace-nowrap text-ellipsis py-3">
          {name.split(",")[0]}
        </div>
        <div
          className="w-full h-[45%] text-sm text-gray-500 flex items-center cursor-pointer hover:text-gray-200 transition-all duration-300"
          onClick={() => window.open(hostname, "_blank")}
        >
          <div className="w-fit h-fit overflow-hidden whitespace-nowrap text-ellipsis">
            {hostname.replace(/^https?:\/\//, "")}
          </div>
          <HiOutlineExternalLink className="ml-1 text-gray-300" />
        </div>
      </div>
      <div className="w-[30%] flex justify-center items-center">
        <button
          className="w-[80%] text-xs text-black bg-white p-2 rounded-full text-center hover:bg-gray-200"
          onClick={() => disconnectDapp(session, connectedWallet, hostname)}
        >
          Disconnect
        </button>
      </div>
    </motion.div>
  </>
);

export default ConnectionsCard;
