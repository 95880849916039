import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { MoreVertical } from "react-feather";
import { AnimatePresence, motion } from "framer-motion";

import {
  getShortDisplayString,
  getItemFromStorage,
  isAddressSame,
} from "../utils/helper";
import Web3AvatarComponent from "../components/Web3AvatarComponent";
import { STORAGE_KEYS } from "../constants/Enums";
import ManageSmartAddress from "./Modals/ManageSmartAddress";

interface SmartAccountAddressCardProps {
  name: string;
  address: string;
  explorerUrl: string;
  from?: string;
  isActive: boolean;
  setActiveModal: (address: string | null) => void;
  modalRef: React.RefObject<HTMLDivElement>;
  setButtonDisabled: (val: boolean) => any;
  isEoa?: boolean;
}

const SwaAddressCard = ({
  name,
  address,
  explorerUrl,
  from,
  isActive,
  setActiveModal,
  modalRef,
  setButtonDisabled,
  isEoa,
}: SmartAccountAddressCardProps) => {
  const [showManageSmartAddressModal, setShowManageSmartAddressModal] =
    useState<boolean>(false);
  const selectedAddress = getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
  const eoaAddress = getItemFromStorage(STORAGE_KEYS.SIGNER);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const openManageSmartAddressModal = (e: any) => {
    e.stopPropagation();
    setActiveModal(address);
    setShowManageSmartAddressModal(true);
  };

  const showStatus = () => {
    if (
      (isAddressSame(eoaAddress, address) && isEoa) ||
      isAddressSame(selectedAddress, address)
    ) {
      return (
        <motion.div
          className="absolute w-4 h-4 bottom-0 right-0 bg-green-500 rounded-full border-2"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.3 }}
        />
      );
    }

    if (isLoading && selectedAddress !== address) {
      return (
        <motion.div
          className="absolute w-4 h-4 bottom-0 right-0 bg-yellow-500 rounded-full border-2 animate-pulse duration-500"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.3 }}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (isLoading && selectedAddress === address) {
      setIsLoading(false);
      setButtonDisabled(false);
      setTimeout(() => {
        navigate("/");
      }, 200);
    }
  }, [selectedAddress]);

  return (
    <motion.div
      onClick={(e) => {
        if (from === "/setting/edit-account") return;

        setIsLoading(true);
        setButtonDisabled(true);
        if (showManageSmartAddressModal) {
          e.stopPropagation();
        }

        if (
          (isAddressSame(eoaAddress, address) && isEoa) ||
          isAddressSame(selectedAddress, address)
        ) {
          navigate("/dashboard");
        }
      }}
      className="w-auto z-10 pl-1"
    >
      <div
        className="flex relative gap-4 items-center w-full"
        style={{
          cursor: from === "/setting/edit-account" ? "default" : "",
        }}
      >
        <div className="w-10 h-10 relative ">
          <Web3AvatarComponent address={address} className="w-full h-full" />
          <AnimatePresence>{showStatus()}</AnimatePresence>
        </div>
        <div className="flex flex-col flex-grow">
          <p className="text-center w-fit max-w-[200px] truncate">{name}</p>
          <p className="text-sm text-gray-500">
            {getShortDisplayString(address)}
          </p>
        </div>
        <div>
          <MoreVertical
            size={16}
            onClick={(e) => openManageSmartAddressModal(e)}
            className={`h-8 w-8 p-1 hover:bg-card-bg2 rounded-full transition-colors duration-500 cursor-pointer ${
              isActive ? "bg-card-bg2" : ""
            }`}
          />
        </div>
      </div>
      {isActive && (
        <ManageSmartAddress
          isOpen={showManageSmartAddressModal}
          onClose={() => {
            setShowManageSmartAddressModal(false);
            setActiveModal(null);
          }}
          address={address}
          explorerUrl={explorerUrl}
          name={name}
          modalRef={modalRef}
          isEoa={isEoa}
        />
      )}
    </motion.div>
  );
};

export default SwaAddressCard;
