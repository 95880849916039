import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";
import PageTitle from "../../../components/PageTitle";
import angleDown from "../../../assets/angle-down.svg";
import faqData from "../../../constants/faqData";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";

const UserSupport = () => {
  const [showText, setShowText] = useState(Array(faqData.length).fill(false));

  const toggleShowText = (index: number) => {
    setShowText((prevState) =>
      prevState.map((value, i) => (i === index ? !value : false)),
    );
  };

  return (
    <PageTransitionWrapper>
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        leftDestinationRoute="/dashboard/settings"
        title="Help"
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        backRouteState={true}
      />

      <div className="px-4 pb-2 text-sm h-[530px] overflow-y-auto">
        <p className=" text-center">
          If you need any support, join our
          <a
            href="https://t.me/getwalletx"
            target="_blank"
            className="font-semibold text-[#26a4e3]"
          >
            {" "}
            Telegram
          </a>
          . And also, you can raise a ticket on
          <a
            href="https://discord.gg/hHt2H3rwv8"
            target="_blank"
            className="font-semibold text-[#5462eb]"
          >
            {" "}
            Discord
          </a>
          .
        </p>

        <div className="mt-4">
          <p className="w-fit ml-2 font-semibold">FAQS</p>

          <div>
            {faqData.map((faq, index) => (
              <div
                key={index}
                className="bg-card-bg hover:bg-card-bg2 p-2 px-4 mt-3 rounded-xl"
              >
                <div
                  onClick={() => toggleShowText(index)}
                  className="flex justify-between items-center cursor-pointer"
                >
                  <span className="font-semibold text-sm">{faq.title}</span>
                  <img
                    src={angleDown}
                    alt="angle down"
                    className={`h-6 transition-transform duration-300 ${
                      showText[index] ? "rotate-180" : ""
                    }`}
                  />
                </div>
                <AnimatePresence>
                  {showText[index] && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <p className="pt-2 text-xs text-gray-300">
                        {faq.description}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default UserSupport;
