import PageTitle from "../../components/PageTitle";
// import ComingSoon from "../../components/common/ComingSoon";
import { PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";
// TODO: this needs to be added later
import Nfts from "../../components/DashboardComponents/Tabs/DashboardTabs/TabContent/Nfts";

const Collectables = () => (
  <div className="h-full">
    <PageTitle
      title="Collectibles"
      leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
      rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
    />
    {/* <div className="h-full pb-[50%]">
      <ComingSoon />
    </div> */}

    <Nfts />
  </div>
);

export default Collectables;
