import { useState } from "react";
import { X } from "react-feather";
import toast from "react-hot-toast";

import { Button } from "../ui/button";
import {
  getShortDisplayString,
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../utils/helper";

import Web3AvatarComponent from "../Web3AvatarComponent";
import { UserSettingsType } from "../../constants/Types";

import useWallet from "../../lib/store/hooks/useWallet";

type RenameSmartAddressProps = {
  isOpen: boolean;
  onClose: () => void;
  address: string;
  name: string;
  isEoa?: boolean;
};

const RenameSmartAddress = ({
  isOpen,
  onClose,
  address,
  name,
  isEoa,
}: RenameSmartAddressProps) => {
  const [enteredName, setEnteredName] = useState<string>("");
  const { eoaAddress, updateUserSettings } = useWallet();

  const updateNameInUserSettings = async (_name: string, _address: string) => {
    const usersSettings: UserSettingsType[] = await getUserSettingsData();
    const userIndex = usersSettings.findIndex(
      (user: UserSettingsType) => user.address === eoaAddress,
    );

    if (userIndex !== -1) {
      if (isEoa) {
        usersSettings[userIndex].walletName = _name;
        await setUserSettingsData(usersSettings);
        updateUserSettings(usersSettings[userIndex]);
      } else {
        usersSettings[userIndex].accounts.forEach((smartAccount) => {
          if (smartAccount.address === _address) {
            smartAccount.name = _name;
          }
        });
        await setUserSettingsData(usersSettings);
        updateUserSettings(usersSettings[userIndex]);
      }
    } else {
      log("User not found");
    }
  };

  const handleSaveBtn = async (_name: string, _address: string) => {
    if (_name === "") {
      toast.error("Please enter a valid Name");
    } else {
      await updateNameInUserSettings(_name, _address);
      onClose();
    }
  };

  const handleInputChange = (e: any) => {
    const _enteredName = e.target.value;

    setEnteredName(_enteredName);
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="fixed top-0 left-0 w-full h-full  bg-primary-bg bg-opacity-50 z-50 flex justify-center items-center"
        >
          <div className=" flex flex-col gap-3 p-4 w-[85%]  justify-center items-center  rounded-xl shadow-lg bg-secondary-bg ">
            <div className="relative w-full text-center m-auto">
              <span className=" text-md font-bold text-neutral-200 ">
                Rename Address
              </span>
              <button
                className="absolute right-0 hover:opacity-70 "
                onClick={() => {
                  onClose();
                }}
              >
                <X style={{ color: "#FFFFFF", fill: "#FFFFFF" }} />
              </button>
            </div>

            <div className="flex flex-col gap-2 justify-center items-center">
              <Web3AvatarComponent address={address} className=" w-20 h-20" />

              <label className="w-fit flex  flex-col">
                <input
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  className="w-fit rounded-md text-md text-center  px-2  pt-2  focus:outline-none bg-transparent text-neutral-300"
                  type="text"
                  placeholder={name}
                  maxLength={15}
                />
              </label>

              <p className="w-full text-neutral-500 font-semibold text-center text-sm">
                {getShortDisplayString(address)}
              </p>
            </div>

            <Button
              onClick={() => {
                handleSaveBtn(enteredName, address);
              }}
              disabled={enteredName === ""}
              className={`group w-full transition-transform bg-card-bg2  hover:bg-card-bg2 `}
            >
              <span className="group-hover:scale-105">Save</span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RenameSmartAddress;
