import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";
import PageTitle from "../../../components/PageTitle";
import { Button } from "../../../components/ui/button";
import AddAddressModal from "../../../components/Modals/AddAddressModal";
import {
  AddressInAddressBookType,
  UserSettingsType,
} from "../../../constants/Types";
import { getUserSettingsData, log } from "../../../utils/helper";
import AddressBookEntries from "../../../components/AddressBookEntries";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const AddressBook = () => {
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] =
    useState<boolean>(false);
  const [addressBookEntries, setAddressBookEntries] = useState<
    AddressInAddressBookType[]
  >([]);

  const { eoaAddress } = useWalletConfig();

  const openAddAddressModal = () => {
    setIsAddAddressModalOpen(true);
  };

  const closeAddAddressModal = () => {
    setIsAddAddressModalOpen(false);
  };

  const fetchAddressBookEntries = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const userAddressBook = usersSettings[userIndex].settings.addressBook;

        setAddressBookEntries(userAddressBook);
      } else {
        setAddressBookEntries([]);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  useEffect(() => {
    fetchAddressBookEntries();
  }, [eoaAddress, addressBookEntries]);

  return (
    <PageTransitionWrapper>
      <div className="relative h-full ">
        <PageTitle
          title="Address Book"
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          leftDestinationRoute="/dashboard/settings"
          backRouteState={true}
        />

        <AddressBookEntries
          onClickOfAddressCard={(_address: string, _name: string) =>
            log(
              "this is the clicked address and its name",
              { _address, _name },
              "warning",
            )
          }
        />

        <div className="absolute group bottom-4 w-full flex justify-center items-center   ">
          <Button
            onClick={() => openAddAddressModal()}
            className="gap-3"
            variant={"tsxButton"}
            size={"tsx"}
          >
            <span className="flex justify-center gap-2 items-center group-hover:scale-105">
              <Plus />
              Add Address
            </span>
          </Button>
        </div>
        <AddAddressModal
          isOpen={isAddAddressModalOpen}
          onClose={() => closeAddAddressModal()}
        />
      </div>
    </PageTransitionWrapper>
  );
};

export default AddressBook;
