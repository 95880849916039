import { useEffect, useState } from "react";
import { Copy } from "react-feather";
import { QRCode } from "react-qrcode-logo";
import { useLocation } from "react-router";
import {
  getItemFromStorage,
  copyToClipboard,
  getChainDetails,
  getShortDisplayString,
  getAccentColor,
} from "../../utils/helper";
import PageTitle from "../../components/PageTitle";
import { PAGE_TITLE_BUTTON_TYPE, STORAGE_KEYS } from "../../constants/Enums";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const Receive = () => {
  const walletAddress = getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
  const [chainLogo, setChainLogo] = useState("");
  const { chainID } = useWalletConfig();
  const [accentColor, setAccentColor] = useState("#000000");
  const location = useLocation();

  const passedAddress = location.state ? location.state.address : null;
  const backLink = location.state ? location.state.from : "/dashboard";

  const fetchTheme = async () => {
    const theme = await getAccentColor(chainID);

    setAccentColor(theme);
  };

  useEffect(() => {
    fetchTheme();
  }, [chainID]);

  useEffect(() => {
    const currentChainDetails = getChainDetails(chainID);

    if (currentChainDetails) {
      setChainLogo(currentChainDetails.chainUri);
    }
  }, [chainID]);

  return (
    <PageTransitionWrapper>
      <div className="max-w-[350px] mx-auto overflow-hidden no-scrollbar bg-primary-bg h-full text-white select-none">
        <PageTitle
          title="Receive"
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          leftDestinationRoute={backLink}
        />

        <div className="flex flex-col gap-4 justify-center items-center mt-6 ">
          <div className="flex justify-center items-center bg-gray-50 p-2 rounded-3xl  shadow-white">
            <QRCode
              value={`ethereum:${passedAddress || walletAddress}`}
              logoImage={chainLogo}
              logoPaddingStyle="circle"
              logoPadding={5}
              qrStyle="dots"
              eyeRadius={10}
              ecLevel="M"
              eyeColor={accentColor}
              size={250}
              quietZone={15}
            />
          </div>

          <div className=" flex flex-col justify-center items-center gap-2 w-full font-semibold text-md tracking-wide">
            {getShortDisplayString(passedAddress || walletAddress)}

            <span
              onClick={() =>
                copyToClipboard(
                  passedAddress || walletAddress,
                  "Address copied successfully",
                )
              }
              className=" flex justify-center gap-2 items-center text-sm font-semibold tracking-wide bg-secondary-bg border-2 border-black rounded-xl px-2 py-1  hover:bg-card-bg cursor-pointer"
            >
              <Copy className="hover:scale-105" size={20} />
              Copy address
            </span>
          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default Receive;
