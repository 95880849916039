import { Outlet, useLocation } from "react-router-dom";
import "./index.css";
import BottomNavBar from "../../components/BottomNavBar";

export const PrivateLayout = () => {
  const location = useLocation();
  const route = location.pathname;

  return (
    <div className="landing_wrapper w-screen h-screen relative text-white overflow-hidden">
      <div
        className={`w-[360px] h-[600px] centered-element absolute left-1/2 top-1/2  transform -translate-x-1/2 -translate-y-1/2  m-auto bg-primary-bg overflow-hidden ${
          route === "/" || route === "/dashboard" ? "px-0" : "px-2"
        }`}
      >
        <Outlet />
        {route === "/" ||
        route === "/dashboard" ||
        route === "/dashboard/collectibles" ||
        route === "/dashboard/activity" ||
        route === "/dashboard/settings" ? (
          <BottomNavBar />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PrivateLayout;
