import { useEffect, useState } from "react";
import { ethers } from "ethers";
import Store from "../../../dapp-connection/js/components/StoreComponent";
import EthProvider from "../../../dapp-connection/js/services/EthProvider";
import VerifyPassword from "../../../components/Modals/VerifyPassword";
import SignatureScreen from "../components/SignatureScreen";

import {
  log,
  isAddressSame,
  getCoinBalance,
  getUserSettingsData,
  getEoaSigner,
} from "../../../utils/helper";
import { validateBiometric } from "../../../hooks/functional-hooks";
import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import { UserSettingsType } from "../../../constants/Types";
import {
  DAPP_INTERACTION_METHODS,
  ERROR_EVENTS,
  ERROR_MESSAGE,
  DEVICE_AUTH_TYPE,
} from "../../../constants/Enums";
import Loader from "../../../components/common/Loader";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

type SignatureRequestDataType = {
  address: string;
  message: string;
  method: string;
  connect_title: string | null;
  connect_origin: string | null;
};

export default function SignatureRequest() {
  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");
  const [dappData, setDappData] = useState<SignatureRequestDataType>({
    address: "",
    message: "",
    method: "",
    connect_title: null,
    connect_origin: null,
  });
  const [actualMessage, setActualMessage] = useState("");
  const biometricAuth = validateBiometric();
  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();
  const [credId, setCredId] = useState<string>("");
  const [activeSmartWallet, setActiveSmartWallet] =
    useState<UserSettingsType | null>(null);
  const {
    getSmartAccountProvider,
    isInitialized,
    init,
    smartAccountAddress,
    provider,
    eoaAddress,
    chainData,
    chainID,
    userSettings,
    eoaPrivateKey,
  } = useWalletConfig();
  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE | null>(
    null,
  );
  const storeData: any = Store.getState();

  const getActiveAddress = () =>
    userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress;

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          setActiveSmartWallet(usersSettings[userIndex]);
          const { biometrics } = usersSettings[userIndex].settings.security;

          const { signMsg } =
            usersSettings[userIndex].settings.transactionSettings;

          setWalletAuthType(signMsg);
          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [walletAuthType, eoaAddress]);

  useEffect(() => {
    if (provider) getCoinBalance(smartAccountAddress, provider, setBalance);
  }, [provider, smartAccountAddress, chainID]);

  useEffect(() => {
    const data: any = Store.getTempState();

    log("SignatureRequest useEffect", { data });
    if (data) {
      setDappData({
        address: data.address,
        message: data.message,
        method: data.method,
        connect_title: data.connect_title,
        connect_origin: data.connect_origin,
      });
      if (data.method === DAPP_INTERACTION_METHODS.PERSONAL_SIGN) {
        const msg = Buffer.from(data.message.substring(2), "hex").toString();

        setActualMessage(msg || data.message);
      } else if (
        data.method === DAPP_INTERACTION_METHODS.ETH_SIGN_TYPED_DATA_V4
      ) {
        // TODO: add support
        EthProvider.onCancel("Method not supported");
      } else {
        setActualMessage(data.message);
      }
    }
  }, [
    dappData.address,
    dappData.message,
    dappData.method,
    dappData.connect_title,
    dappData.connect_origin,
  ]);

  const rejectSignRequest = (reason: string | null = null) => {
    EthProvider.onCancel(reason);
  };

  const handleError = () => {
    rejectSignRequest(ERROR_MESSAGE.DAPP_NETWORK_ERROR);
  };

  useEffect(() => {
    window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    return () => {
      window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    };
  });

  useEffect(() => {
    async function initializeSmartWallet() {
      if (!smartAccountAddress) {
        init({
          walletName: activeSmartWallet?.walletName || "",
          chainId: chainID,
        });
      }
    }

    setSmartWalletAddress(smartAccountAddress);

    initializeSmartWallet();

    // if (provider && smartAccountAddress) setIsLoading(false);
  }, [smartAccountAddress, smartWalletAddress]);

  const userSignMessage = async () => {
    const smartAccountProvider = await getSmartAccountProvider();

    if (!isAddressSame(dappData.address, getActiveAddress())) {
      rejectSignRequest("Not the correct signer address");
      return;
    }

    let isValid = false;

    if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
      isValid = await verifyPassword();
      return;
    } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
      isValid = true;
    }

    if (!isValid) {
      rejectSignRequest("Authentication Failed");
      return;
    }

    if (userSettings?.isEoaSelected) {
      const signer = getEoaSigner(eoaPrivateKey, provider);

      const messageHashBytes = ethers.utils.arrayify(dappData.message);

      // Sign the binary data
      const flatSig = await signer.signMessage(messageHashBytes);

      EthProvider.onAccept(flatSig);
    } else {
      const signMessageResponse = await smartAccountProvider?.signMessage(
        dappData.message,
      );

      EthProvider.onAccept(signMessageResponse);
    }
  };

  useEffect(() => {
    if (isInitialized && provider && smartAccountAddress) {
      setIsLoading(false);
    }
  }, [isInitialized, smartAccountAddress, provider]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <SignatureScreen
          smartWalletAddress={getActiveAddress()}
          chainData={chainData}
          storeData={storeData}
          balance={balance}
          actualMessage={actualMessage}
          rejectSignRequest={rejectSignRequest}
          userSignMessage={userSignMessage}
        />
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
}
