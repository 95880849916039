/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Menu, AlertTriangle, Eye, EyeOff } from "react-feather";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CryptoJS from "crypto-js";

import WalletX from "../../../assets/icons/walletX-Full.png";

import DropDown from "../../../components/ui/dropdown";

import { UserSettingsType } from "../../../constants/Types";
import fingerPrint from "../../../assets/fingerPrintblack.svg";
import {
  CUSTOM_EVENTS,
  DEVICE_AUTH_TYPE,
  MIXPANEL_KEY,
  STORAGE_KEYS,
} from "../../../constants/Enums";
import PeekingIllustrations from "../../../components/PeekingIllustrations";
import LoginScreenOptions from "../../../components/Modals/LoginScreenOptions";
import {
  customEventHandler,
  getUserSettingsData,
  log,
  setItemInStorage,
} from "../../../utils/helper";
import { validateBiometric } from "../../../hooks/functional-hooks";
import MixPanel from "../../../utils/MixPanel";
import useWallet from "../../../lib/store/hooks/useWallet";

const LoginPage = () => {
  const navigate = useNavigate();
  const [listOfDevices, setListOfDevices] = useState<UserSettingsType[]>([]);
  const [userPassword, setUserPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedWallet, setSelectedWallet] = useState<UserSettingsType | null>(
    null,
  );
  const [showImages, setShowImages] = useState(true);
  const biometricAuth = validateBiometric();
  const { login } = useWallet();

  const handleUserPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;

    setUserPassword(password);
    setShowPasswordError(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);

    if (showPassword) {
      setShowImages(true);
    } else {
      setShowImages(false);
    }
  };

  let passwordFromLocal: string | null = null;
  const retrieveEncryptedPassword = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === selectedWallet?.address,
      );

      if (userIndex !== -1) {
        passwordFromLocal = usersSettings[userIndex].settings.security.password;
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
    return passwordFromLocal;
  };

  retrieveEncryptedPassword();

  const decryptPassword = (
    encryptedAddress: string,
    _userPassword: string,
    userEOA: string,
  ) => {
    const bytes = CryptoJS.AES.decrypt(encryptedAddress, _userPassword);

    if (bytes.toString(CryptoJS.enc.Utf8) === userEOA) {
      return true;
    }

    return false;
  };

  const validatePassword = (password: string): boolean => {
    if (passwordFromLocal && selectedWallet?.address) {
      return decryptPassword(
        passwordFromLocal,
        password,
        selectedWallet?.address,
      );
    }

    return false;
  };

  const handlePasswordLogin = (): boolean => {
    if (userPassword === "") {
      toast.error("Please enter a password", { id: "password-error" });
      return false;
    }

    if (!validatePassword(userPassword)) {
      setShowPasswordError(true);
      return false;
    }

    return true;
  };

  const handleLoginClick = async (_authenticationType: DEVICE_AUTH_TYPE) => {
    if (selectedWallet) {
      try {
        let isValid = false;

        if (_authenticationType === DEVICE_AUTH_TYPE.PASSKEY) {
          if (selectedWallet?.settings.security.biometrics) {
            isValid = await biometricAuth(
              selectedWallet?.settings.security?.biometrics,
            );
          }
        } else if (_authenticationType === DEVICE_AUTH_TYPE.PASSWORD) {
          isValid = handlePasswordLogin();
        } else if (_authenticationType === DEVICE_AUTH_TYPE.NONE) {
          isValid = true;
        }

        if (selectedWallet?.address) {
          setItemInStorage(
            STORAGE_KEYS.SMART_ACCOUNT,
            selectedWallet?.activeSmartAccountAddress,
          );
          setItemInStorage(STORAGE_KEYS.SIGNER, selectedWallet?.address);
        } else {
          toast.error("Some error Occurred !");
        }

        if (isValid) {
          login();
          setItemInStorage(STORAGE_KEYS.IS_LOGGED_IN, true);

          MixPanel.identify(selectedWallet?.address);

          MixPanel.register({
            name: selectedWallet?.walletName,
            walletAddress: selectedWallet?.address,
          });

          MixPanel.track(MIXPANEL_KEY.Login_Successful, {
            name: selectedWallet?.walletName,
            walletAddress: selectedWallet?.address,
          });

          customEventHandler(
            {
              newAddress: selectedWallet.isEoaSelected
                ? selectedWallet?.address
                : selectedWallet.activeSmartAccountAddress,
            },
            CUSTOM_EVENTS.ACCOUNT_CHANGE,
          );

          toast.success("Login Successful !", {
            icon: "🚀",
            duration: 3000,
          });
        } else {
          toast.error("Authentication Failed", { id: "auth-failed" });
        }
      } catch (e) {
        log("Error in handleLoginClick", e);
      }
    } else {
      toast.error("Please select an option !", {
        duration: 3000,
      });
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const fetchUserSettings = async () => {
      const data: UserSettingsType[] = await getUserSettingsData();

      setListOfDevices(data);
    };

    fetchUserSettings();
  }, []);

  return (
    <div className="h-full w-full bg-primary-bg select-none">
      <div className="bg-primary-bg relative h-full w-full px-4 flex flex-col">
        <PeekingIllustrations toggleImages={showImages} />
        <div className="flex justify-end p-2 mt-4">
          <Menu
            onClick={toggleModal}
            size={30}
            className="justify-self-end opacity-80 hover:opacity-100 relative z-10"
          />
          <LoginScreenOptions showModal={showModal} toggleModal={toggleModal} />
        </div>

        <div className="mt-20 px-2">
          <img src={WalletX} alt="Logo" />
        </div>

        <div className="w-[90%] absolute left-1/2 translate-x-[-50%] bottom-6 flex flex-col gap-4 text-md font-semibold pt-0">
          <div className="w-full">
            {/* TODO also pass the device data stored in the local storage for the current users  */}
            <DropDown
              listOfWallets={listOfDevices}
              setSelectedWallet={setSelectedWallet}
            />
          </div>
          {selectedWallet?.settings.security.authenticationType !==
            DEVICE_AUTH_TYPE.PASSKEY &&
            selectedWallet?.settings.security.authenticationType !==
              DEVICE_AUTH_TYPE.NONE && (
              <div>
                {showPasswordError && (
                  <div className=" flex justify-end items-center text-sm gap-1 py-2">
                    <AlertTriangle size={16} className="text-red-500 " />
                    <span className="text-red-500">Wrong Password</span>
                  </div>
                )}

                <div
                  className={`relative rounded-xl backdrop-blur-lg ${
                    userPassword ? "opacity-100" : "opacity-60"
                  }`}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={userPassword}
                    onChange={handleUserPasswordChange}
                    className="border-2 rounded-xl bg-transparent focus:outline-none w-full py-2.5 pl-2 pr-10 text-sm pl-4"
                    onFocus={() => {
                      setShowImages(false);
                    }}
                    onBlur={() =>
                      showPassword === true
                        ? setShowImages(false)
                        : setShowImages(true)
                    }
                  />

                  <span
                    onClick={togglePasswordVisibility}
                    className="transition-opacity duration-300 absolute top-1/2 -translate-y-1/2 right-4"
                  >
                    {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                  </span>
                </div>
              </div>
            )}

          {selectedWallet?.settings.security.authenticationType ===
          DEVICE_AUTH_TYPE.NONE ? (
            <>
              <button
                onClick={() => {
                  handleLoginClick(DEVICE_AUTH_TYPE.NONE);
                }}
                className="relative z-20 p-3 max-h-14 w-full duration-200 rounded-full bg-white text-black hover:bg-opacity-80 flex justify-center items-center gap-1 "
              >
                <span>Unlock</span>
              </button>
            </>
          ) : selectedWallet?.settings.security.authenticationType ===
            DEVICE_AUTH_TYPE.PASSKEY ? (
            <button
              onClick={() => handleLoginClick(DEVICE_AUTH_TYPE.PASSKEY)}
              className="p-3 max-h-14 w-full duration-200 rounded-full bg-white text-black hover:bg-opacity-80 flex justify-center items-center gap-1 "
            >
              <span>Unlock with</span>
              <img
                className="h-10"
                src={fingerPrint}
                alt="Finger print icon for passkeys"
              />
            </button>
          ) : selectedWallet?.settings.security.authenticationType ===
            DEVICE_AUTH_TYPE.PASSWORD ? (
            <>
              <button
                onClick={() => handleLoginClick(DEVICE_AUTH_TYPE.PASSWORD)}
                className="p-3 max-h-14 w-full duration-200 rounded-full bg-white text-black hover:bg-opacity-80 flex justify-center items-center gap-1 "
              >
                <span>Unlock</span>
              </button>
            </>
          ) : (
            <div className="w-full max-h-14 flex justify-between items-center ">
              <button
                onClick={() => handleLoginClick(DEVICE_AUTH_TYPE.PASSWORD)}
                className="relative p-3 w-[80%] duration-200 rounded-l-full bg-white text-black hover:bg-opacity-80"
              >
                Unlock
              </button>
              <button
                className="rounded-r-full h-full p-2 bg-white hover:bg-opacity-80"
                onClick={() => handleLoginClick(DEVICE_AUTH_TYPE.PASSKEY)}
              >
                <img
                  className="h-[1.95em] ml-[-0.15em]"
                  src={fingerPrint}
                  alt="fingerPrint"
                />
              </button>
            </div>
          )}
          <div className="w-full">
            <button
              onClick={() => navigate("/register")}
              className="text-[17px] w-full px-3 py-2 rounded-[2rem] duration-200 hover:bg-zinc-800 whitespace-nowrap"
            >
              Create a new Wallet
            </button>
            <button
              onClick={() =>
                navigate("/import-wallet", {
                  state: {
                    goToLogin: true,
                  },
                })
              }
              className="text-[17px] w-full px-3 py-2 rounded-[2rem] duration-200 hover:bg-zinc-800 whitespace-nowrap"
            >
              Import Wallet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
