import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import Store from "../../../dapp-connection/js/components/StoreComponent";
import VerifyPassword from "../../../components/Modals/VerifyPassword";
import SignatureScreen from "../../Dapp/components/SignatureScreen";

import {
  log,
  getCoinBalance,
  getUserSettingsData,
  getEoaSigner,
} from "../../../utils/helper";
import { validateBiometric } from "../../../hooks/functional-hooks";
import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import { UserSettingsType } from "../../../constants/Types";
import {
  ERROR_EVENTS,
  ERROR_MESSAGE,
  DEVICE_AUTH_TYPE,
} from "../../../constants/Enums";
import Loader from "../../../components/common/Loader";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";
import { useConfig } from "../../../context/ConfigProvider";

export default function SignatureRequest() {
  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");
  const [actualMessage, setActualMessage] = useState("");
  const biometricAuth = validateBiometric();
  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();
  const [credId, setCredId] = useState<string>("");
  const [activeSmartWallet, setActiveSmartWallet] =
    useState<UserSettingsType | null>(null);
  const {
    getSmartAccountProvider,
    isInitialized,
    init,
    smartAccountAddress,
    provider,
    eoaAddress,
    chainData,
    chainID,
    userSettings,
    eoaPrivateKey,
  } = useWalletConfig();
  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE | null>(
    null,
  );
  const storeData: any = Store.getState();

  const { state } = useLocation();
  const { id, message, topic, dappName } = state;
  const { wcInstance } = useConfig();
  const navigate = useNavigate();

  useEffect(() => {
    setActualMessage(message);
  }, []);

  const getActiveAddress = () =>
    userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress;

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          setActiveSmartWallet(usersSettings[userIndex]);
          const { biometrics } = usersSettings[userIndex].settings.security;

          const { signMsg } =
            usersSettings[userIndex].settings.transactionSettings;

          setWalletAuthType(signMsg);
          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [walletAuthType, eoaAddress]);

  useEffect(() => {
    if (provider) getCoinBalance(getActiveAddress(), provider, setBalance);
  }, [
    provider,
    smartAccountAddress,
    chainID,
    userSettings?.isEoaSelected,
    eoaAddress,
  ]);

  const rejectSignRequest = async (reason: string | null = null) => {
    const response = {
      id,
      jsonrpc: "2.0",
      error: { code: 5000, message: reason },
    };

    await wcInstance.respondSessionRequest({ topic, response });
  };

  const handleError = () => {
    rejectSignRequest(ERROR_MESSAGE.DAPP_NETWORK_ERROR);
  };

  useEffect(() => {
    window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    return () => {
      window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    };
  });

  useEffect(() => {
    async function initializeSmartWallet() {
      if (!smartAccountAddress) {
        init({
          walletName: activeSmartWallet?.walletName || "",
          chainId: chainID,
        });
      }
    }

    setSmartWalletAddress(smartAccountAddress);

    initializeSmartWallet();

    // if (provider && smartAccountAddress) setIsLoading(false);
  }, [smartAccountAddress, smartWalletAddress]);

  const userSignMessage = async () => {
    const smartAccountProvider = await getSmartAccountProvider();

    let isValid = false;

    if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
      isValid = await verifyPassword();
      return;
    } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
      isValid = true;
    }

    if (!isValid) {
      rejectSignRequest("Authentication Failed");
      return;
    }

    let messageHashBytes: any = actualMessage;

    try {
      messageHashBytes = ethers.utils.arrayify(actualMessage);
    } catch (e: any) {
      log("Cannot arrayify string for EOA ", e, "error");
    }

    let signMessageResponse;

    if (userSettings?.isEoaSelected) {
      const signer = getEoaSigner(eoaPrivateKey, provider);

      // Sign the binary data
      signMessageResponse = await signer.signMessage(messageHashBytes);
    } else {
      signMessageResponse = await smartAccountProvider?.signMessage(
        messageHashBytes,
      );
    }

    const response = { id, result: signMessageResponse, jsonrpc: "2.0" };

    await wcInstance.respondSessionRequest({ topic, response });

    navigate("/dashboard");
  };

  useEffect(() => {
    if (
      isInitialized &&
      provider &&
      ((userSettings?.isEoaSelected && eoaAddress) || smartAccountAddress)
    ) {
      setIsLoading(false);
    }
  }, [
    isInitialized,
    smartAccountAddress,
    provider,
    userSettings?.isEoaSelected,
    eoaAddress,
  ]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <SignatureScreen
          smartWalletAddress={getActiveAddress()}
          chainData={chainData}
          storeData={storeData}
          balance={balance}
          actualMessage={actualMessage}
          rejectSignRequest={rejectSignRequest}
          userSignMessage={userSignMessage}
          dappName={dappName}
        />
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
}
