import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import dappConnection from "../assets/illustrations/dapp-connection.png";
import gasless from "../assets/illustrations/gasless.png";
import multiChain from "../assets/illustrations/multiChain.png";
import smartWallet from "../assets/illustrations/smart-wallets.png";
import stateOfArt from "../assets/illustrations/state-of-art.png";

type IllustrationLoaderProps = {
  bgColor?: string;
  height?: string;
  time?: number;
};

const illustrations = [
  dappConnection,
  gasless,
  multiChain,
  smartWallet,
  stateOfArt,
];

const sizes = ["h-8 w-8", "h-10 w-10", "h-14 w-14", "h-10 w-10", "h-8 w-8"];
const passedSize = ["h-[60%]", "h-[72%]", "h-[100%]", "h-[72%]", "h-[60%]"];

const IllustrationLoader = ({
  bgColor,
  height,
  time,
}: IllustrationLoaderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const transitionDuration = time || 0.45;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % illustrations.length);
    }, transitionDuration * 1000);

    return () => clearInterval(interval);
  }, []);

  const containerStyle = {
    height: height || "auto",
  };

  return (
    <div className="flex items-center w-fit" style={containerStyle}>
      <AnimatePresence>
        {illustrations.map((illustration, index) => {
          const relativeIndex =
            (illustrations.length - 1 + currentIndex - index) %
            illustrations.length;
          const sizeClass = height ? passedSize[index] : sizes[index];

          return (
            <motion.img
              key={relativeIndex}
              src={illustrations[relativeIndex]}
              alt={`illustration-${relativeIndex}`}
              className={`${sizeClass} ${
                bgColor || "bg-primary-bg"
              } w-fit object-scale-down px-[2px]`}
              layout
            />
          );
        })}
      </AnimatePresence>
    </div>
  );
};

export default IllustrationLoader;
