import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { ShieldOff, X } from "react-feather";
import { useNavigate } from "react-router-dom";

interface SecureYourWalletProps {
  showModal: boolean;
  toggleModal: (secure?: boolean) => void;
}

const SecureYourWallet = ({
  showModal,
  toggleModal,
}: SecureYourWalletProps) => {
  const navigate = useNavigate();

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            animate={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            className="fixed inset-0 pb-4 z-50 flex items-end justify-center w-full transparent-bg select-none"
            onClick={handleOutsideClick}
          >
            <motion.div
              initial={{ translateY: "110%" }}
              animate={{ translateY: 0 }}
              exit={{ translateY: "110%" }}
              transition={{ duration: 0.3 }}
              className=" relative  w-[95%] bg-card-bg rounded-lg px-2 py-4"
            >
              <ShieldOff size={50} color="red" className="mx-auto my-8" />
              <div className="flex flex-col justify-center items-center gap-5 mb-10">
                <p>Your Wallet isn't Secured</p>
                <p className="text-center text-gray-500 text-sm ">
                  Your wallet is not secured yet. Secure your wallet by adding
                  password or passkeys.
                </p>
              </div>
              <X
                className="absolute top-3 right-4 cursor-pointer"
                onClick={() => toggleModal()}
              />
              <div className="flex flex-col gap-4 text-md font-semibold pt-0 max-w-[90%] mx-auto">
                <button
                  onClick={() => navigate("/settings/security")}
                  className="p-3 w-full duration-200 rounded-[2rem] bg-red-500  text-white hover:bg-opacity-80"
                >
                  Secure Now
                </button>
                <button
                  onClick={() => toggleModal(true)}
                  className="p-3 rounded-[2rem] duration-200 w-full hover:bg-card-bg2 hover:text-white whitespace-nowrap"
                >
                  Remind me in an hour
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SecureYourWallet;
