import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import initializeWC from "./initiateWC";
import WCEventHandler from "../../walletconnect";

export default function useInit() {
  const [wcInstance, setWcInstance] = useState(null);

  const navigate = useNavigate();
  const { smartAccountAddress } = useWalletConfig();

  const navigation = (path, data) => {
    navigate(path, { state: { ...data } });
  };

  const getWC = initializeWC(setWcInstance);

  useEffect(() => {
    getWC();
  }, []);

  useEffect(() => {
    if (wcInstance) {
      wcInstance.on("session_proposal", ({ id, params }) =>
        WCEventHandler(
          {
            id,
            params,
            type: "session_proposal",
            wcInstance,
            smartAccountAddress,
          },
          navigation,
        ),
      );
      wcInstance.on("session_request", ({ id, params, topic }) =>
        WCEventHandler(
          {
            id,
            params,
            topic,
            type: "session_request",
            wcInstance,
            smartAccountAddress,
          },
          navigation,
        ),
      );
    }
  }, [wcInstance]);

  return {
    wcInstance,
  };
}
