import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";
import logo from "../../assets/icons/walletX-Full.png";
import dappConnection from "../../assets/illustrations/dapp-connection.png";
import gasless from "../../assets/illustrations/gasless.png";
import multiChain from "../../assets/illustrations/multiChain.png";
import smartWallet from "../../assets/illustrations/smart-wallets.png";
import stateOfArt from "../../assets/illustrations/state-of-art.png";
import dashboardIcon from "../../assets/dashboard.svg";

const index = () => {
  const navigate = useNavigate();

  return (
    <div className=" h-full">
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.X}
        leftDestinationRoute="/dashboard"
        title="What's New ✨"
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
      />
      <div className="px-2 h-[450px] overflow-y-auto">
        <div className="flex flex-col items-center justify-center">
          <img
            src={logo}
            alt="logo"
            className="h-16 p-1 object-scale-down w-[80%] "
          />

          <div className="flex items-center gap-1">
            <img src={multiChain} alt="multiChain" className="h-8 w-8" />
            <img src={stateOfArt} alt="stateOfArt" className="h-10 w-10" />
            <img src={gasless} alt="gasless" className="h-12 w-12" />
            <img src={smartWallet} alt="smartWallet" className="h-10 w-10" />
            <img
              src={dappConnection}
              alt="dappConnection"
              className="h-8 w-8"
            />
          </div>
        </div>

        <p className="mt-8 border-b w-fit text-md font-semibold">
          Welcome to WalletX
        </p>

        <div className="flex flex-col gap-4 my-4">
          <div className="flex gap-2">
            <img
              src={dashboardIcon}
              alt="dashboardIcon"
              className="h-10 w-10 opacity-70"
            />
            <div className="flex-grow">
              <p className="mb-1">User Dashboard</p>
              <p className="text-sm text-gray-500">
                We have created a new user dashboard for the user to reward them
                with exps
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            <img
              src={dashboardIcon}
              alt="dashboardIcon"
              className="h-10 w-10 opacity-70"
            />
            <div className="flex-grow">
              <p className="mb-1">User Dashboard</p>
              <p className="text-sm text-gray-500">
                We have created a new user dashboard for the user to reward them
                with exps
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            <img
              src={dashboardIcon}
              alt="dashboardIcon"
              className="h-10 w-10 opacity-70"
            />
            <div className="flex-grow">
              <p className="mb-1">User Dashboard</p>
              <p className="text-sm text-gray-500">
                We have created a new user dashboard for the user to reward them
                with exps
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            <img
              src={dashboardIcon}
              alt="dashboardIcon"
              className="h-10 w-10 opacity-70"
            />
            <div className="flex-grow">
              <p className="mb-1">User Dashboard</p>
              <p className="text-sm text-gray-500">
                We have created a new user dashboard for the user to reward them
                with exps
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            <img
              src={dashboardIcon}
              alt="dashboardIcon"
              className="h-10 w-10 opacity-70"
            />
            <div className="flex-grow">
              <p className="mb-1">User Dashboard</p>
              <p className="text-sm text-gray-500">
                We have created a new user dashboard for the user to reward them
                with exps
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 flex w-full gap-2 p-4 bg-card-bg">
        <a
          href="https://getwalletx.medium.com/"
          className="flex-grow rounded-xl py-3 bg-neutral-800 hover:bg-neutral-700 duration-700 text-center"
        >
          Learn More
        </a>
        <button
          onClick={() => navigate("/dashboard")}
          className="flex-grow rounded-xl py-3 bg-white text-black hover:bg-opacity-80 duration-300"
        >
          Understood
        </button>
      </div>
    </div>
  );
};

export default index;
