import { useState, useEffect } from "react";
import Moralis from "moralis";
import localforage from "localforage";
import Collection from "../../../../Collection";
import galleryImage from "../../../../../assets/gallery.svg";
import ImportNfts from "../../../../Modals/ImportNfts";
import plus from "../../../../../assets/NftDiscoveryPage/add-file.svg";
import defaultImg from "../../../../../assets/NftDiscoveryPage/nft_default.jpg";
import Loader from "../../../../common/Loader";
import { log } from "../../../../../utils/helper";
import { CollectionType, NFT } from "../../../../../constants/Types";
import useWalletConfig from "../../../../../lib/store/hooks/useWalletConfig";

const Nfts = () => {
  const [nftSource, setNftSource] = useState<CollectionType[]>([]);
  const [isImportNftsModalOpen, setImportNftsModalOpen] =
    useState<boolean>(false);
  const { smartAccountAddress, chainID, eoaAddress, userSettings } =
    useWalletConfig();

  const [isLoading, setIsLoading] = useState(true);

  const getNftFromIndexedDB = async () => {
    const storedNftData: any = (await localforage.getItem("NftData")) || {};

    if (userSettings?.isEoaSelected) {
      if (storedNftData[eoaAddress]) {
        setNftSource(storedNftData[eoaAddress][chainID]);
      }
    } else if (storedNftData[smartAccountAddress]) {
      setNftSource(storedNftData[smartAccountAddress][chainID]);
    }
  };

  const storeNftDataInIndexedDB = async (collectionSrcArray: any) => {
    try {
      const userAccount = userSettings?.isEoaSelected
        ? eoaAddress
        : smartAccountAddress;

      const storedNftData: any = (await localforage.getItem("NftData")) || {};

      if (!storedNftData[userAccount]) {
        storedNftData[userAccount] = {};
      }

      storedNftData[userAccount][chainID] = collectionSrcArray;

      await localforage.setItem("NftData", storedNftData);
    } catch (error) {
      log("Error storing NFT data in IndexedDB", error, "error");
    }
  };

  const updateNftDataInIndexedDB = async (
    userAccount: string,
    _chainId: string,
    updatedNftData: any,
  ) => {
    try {
      const storedNftData: any = (await localforage.getItem("NftData")) || {};

      if (!storedNftData[userAccount]) {
        storedNftData[userAccount] = {};
      }

      storedNftData[userAccount][_chainId] = updatedNftData;

      await localforage.setItem("NftData", storedNftData);
    } catch (error) {
      log("Error updating NFT data in IndexedDB", error, "error");
    }
  };

  const updateSingleImportedNftData = (data: NFT) => {
    const existingNftIndex = nftSource.findIndex(
      (item: any) => item.tokenAddress === data.address,
    );

    const nftData: NFT = {
      id: data.id.toString(),
      imageUrl: data.imageUrl.replace("ipfs://", "https://ipfs.io/ipfs/"),
      name: data.name,
      description: data.description,
      collectionName: data.collectionName,
      address: data.address,
    };

    if (existingNftIndex !== -1) {
      const updatedNftSource = [...nftSource];

      updatedNftSource[existingNftIndex] = {
        ...updatedNftSource[existingNftIndex],
        nftsData: [...updatedNftSource[existingNftIndex].nftsData, nftData],
      };

      updateNftDataInIndexedDB(
        userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
        chainID,
        updatedNftSource,
      );
    } else {
      const newNftData = {
        tokenAddress: data.address,
        collectionName: data.collectionName,
        nftsData: [nftData],
      };

      const updatedNftSource: any = [...nftSource, newNftData];

      setNftSource(updatedNftSource);

      updateNftDataInIndexedDB(
        userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
        chainID,
        updatedNftSource,
      );
    }
  };

  const sortNftUsingTokenAddress = (
    collectionSrcArray: CollectionType[],
    index: number,
    tokenAddress: string,
    collectionName: string,
    nftData: any,
  ) => {
    if (index !== -1) {
      collectionSrcArray[index].nftsData.push(nftData);
    } else {
      const newNftData: CollectionType = {
        name: collectionName,
        tokenAddress,
        nftsData: [nftData],
      };

      collectionSrcArray.push(newNftData);
    }
  };

  const importAllNfts = async () => {
    try {
      const response: any = await Moralis.EvmApi.nft.getWalletNFTs({
        chain: chainID,
        format: "decimal",
        mediaItems: false,
        address: userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
      });

      const collectionSrcArray: CollectionType[] = [];

      for (let i = 0; i < response?.raw?.result?.length; i += 1) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const tokenUri = await fetch(response.raw.result[i].token_uri);
          // eslint-disable-next-line no-await-in-loop
          const dataFromTokenUri = await tokenUri.json();
          const existingNftIndex = collectionSrcArray.findIndex(
            (item: CollectionType) =>
              item.tokenAddress === response.raw.result[i].token_address,
          );

          const singleNftData: NFT = {
            id: response.raw.result[i].token_id,
            imageUrl: dataFromTokenUri?.image
              ? dataFromTokenUri.image.replace(
                  "ipfs://",
                  "https://ipfs.io/ipfs/",
                )
              : defaultImg,
            name: dataFromTokenUri.name,
            description: dataFromTokenUri.description,
            address: response.raw.result[i].token_address,
            collectionName: response.raw.result[i].name,
          };

          sortNftUsingTokenAddress(
            collectionSrcArray,
            existingNftIndex,
            response.raw.result[i].token_address,
            response.raw.result[i].name,
            singleNftData,
          );
        } catch (error) {
          const existingNftIndex: any = collectionSrcArray.findIndex(
            (item: CollectionType) =>
              item.tokenAddress === response.raw.result[i].token_address,
          );

          const singleNftData: NFT = {
            id: response.raw.result[i].token_id,
            imageUrl: defaultImg,
            name: "",
            description: "",
            collectionName: response.raw.result[i].name,
            address: response.raw.result[i].tokenAddress,
          };

          sortNftUsingTokenAddress(
            collectionSrcArray,
            existingNftIndex,
            response.raw.result[i].token_address,
            response.raw.result[i].name,
            singleNftData,
          );
        }
      }

      setNftSource(collectionSrcArray);
      setIsLoading(false);
      await storeNftDataInIndexedDB(collectionSrcArray);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNftFromIndexedDB();
    importAllNfts();
  }, []);

  return (
    <>
      <div className="p-2 relative h-full overflow-y-scroll">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {nftSource.length > 0 ? (
              <>
                <div className="mb-4 gap-2">
                  {nftSource.map((collection: CollectionType, index) => (
                    <Collection
                      name={collection.name}
                      tokenAddress={collection.tokenAddress}
                      nftsData={collection.nftsData}
                      canOpenNFTDetailsPage={true}
                      key={index}
                    />
                  ))}
                </div>
                <button
                  onClick={() => setImportNftsModalOpen(true)}
                  className=" border border-grayish-blue p-2 px-4 rounded-lg flex gap-1 justify-center items-center mx-auto"
                >
                  <img src={plus} className="h-5" alt="add" />
                  <p className="text-sm">Import NFT</p>
                </button>
              </>
            ) : (
              <div className="flex justify-center h-full w-full">
                <div className="h-fit w-full pt-[25%]">
                  <div className="px-5 py-6 bg-opacity-50 rounded-2xl">
                    <div className="flex flex-col gap-2 justify-center items-center">
                      <div className="flex items-center gap-4 mb-4">
                        <img
                          className="h-11 rounded-xl"
                          src={galleryImage}
                          alt="no nfts found"
                        />
                        <p className="text-lg text-gray-200">No NFTs</p>
                      </div>
                      <p className="text-sm text-center text-gray-500 font-medium">
                        Get started with your first NFT by visiting your
                        favorite marketplace
                      </p>
                    </div>
                    <p className="text-xs text-center my-4">OR</p>
                    <div>
                      <button
                        onClick={() => setImportNftsModalOpen(true)}
                        className="mx-auto p-2 px-4 rounded-lg flex gap-1 justify-center items-center hover:bg-card-bg"
                      >
                        <img src={plus} className="h-5" alt="add" />
                        <p className="text-sm">Import NFT</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <ImportNfts
          isOpen={isImportNftsModalOpen}
          onClose={() => setImportNftsModalOpen(false)}
          updateSingleImportedNftData={updateSingleImportedNftData}
        />
      </div>
    </>
  );
};

export default Nfts;
