import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { motion, AnimatePresence } from "framer-motion";
import { Lock } from "react-feather";
import {
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../utils/helper";
import { TRANSACTION_TYPE, DEVICE_AUTH_TYPE } from "../../constants/Enums";
import { UserSettingsType } from "../../constants/Types";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

type TransactionSettingOptionProps = {
  isOpen: boolean;
  onClose: () => void;
  transactionType: TRANSACTION_TYPE;
};

const TransactionSettingOption = ({
  isOpen,
  onClose,
  transactionType,
}: TransactionSettingOptionProps) => {
  const { eoaAddress } = useWalletConfig();
  const navigate = useNavigate();

  const [currentAuthType, setCurrentAuthType] = useState<DEVICE_AUTH_TYPE>(
    DEVICE_AUTH_TYPE.NONE,
  );

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const handleOptionClick = async (option: DEVICE_AUTH_TYPE) => {
    try {
      const usersSettings = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        if (transactionType === TRANSACTION_TYPE.SIGN) {
          usersSettings[userIndex].settings.transactionSettings.signMsg =
            option;
        } else if (transactionType === TRANSACTION_TYPE.CONNECT) {
          usersSettings[userIndex].settings.transactionSettings.connect =
            option;
        } else if (transactionType === TRANSACTION_TYPE.APPROVE_TRANSACTION) {
          usersSettings[
            userIndex
          ].settings.transactionSettings.approveTransaction = option;
        }

        await setUserSettingsData(usersSettings);
      }
    } catch (error) {
      log("Error updating transaction settings:", error);
    }

    onClose();
  };

  const getTransactionTypeText = (type: TRANSACTION_TYPE): string => {
    switch (type) {
      case TRANSACTION_TYPE.SIGN:
        return "Sign";

      case TRANSACTION_TYPE.CONNECT:
        return "Connect";

      default:
        return "Approve Transaction";
    }
  };

  useEffect(() => {
    const fetchSecuritySettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();
        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { authenticationType } =
            usersSettings[userIndex].settings.security;

          setCurrentAuthType(authenticationType);
        }
      } catch (error) {
        log("This is error ", error);
      }
    };

    fetchSecuritySettings();
  }, [currentAuthType]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed left-1/2 translate-x-[-50%] top-0 h-full w-[94%] bg-primary-bg bg-opacity-70 flex items-end"
        >
          <motion.div
            initial={{ translateY: "110%" }}
            animate={{ translateY: "0%" }}
            exit={{ translateY: "110%" }}
            transition={{ duration: 0.5 }}
            onClick={stopPropagation}
            className="flex flex-col gap-4 w-full p-4 pb-6 pt-4 bg-card-bg rounded-t-xl"
          >
            <p className="px-2 text-center font-semibold">
              {getTransactionTypeText(transactionType)} Settings
            </p>
            <p className="text-sm text-gray-500 w-4/5 mx-auto text-center leading-tightest tracking-tighter">
              Select the authentication method you want to use for{" "}
              {getTransactionTypeText(transactionType)}.
            </p>
            {currentAuthType === DEVICE_AUTH_TYPE.NONE && (
              <p className="text-xs text-red-500 font-semibold text-center px-4">
                Enable authentication method in security
                <span
                  className="text-blue-500 font-semibold cursor-pointer"
                  onClick={() => navigate("/settings/security")}
                >
                  {" "}
                  settings
                </span>
              </p>
            )}
            <button
              disabled={
                currentAuthType === DEVICE_AUTH_TYPE.PASSWORD ||
                currentAuthType === DEVICE_AUTH_TYPE.NONE
              }
              onClick={() => handleOptionClick(DEVICE_AUTH_TYPE.PASSKEY)}
              className={`p-2 rounded-xl  flex gap-2 items-center justify-center ${
                currentAuthType === DEVICE_AUTH_TYPE.PASSWORD ||
                currentAuthType === DEVICE_AUTH_TYPE.NONE
                  ? "text-black font-semibold bg-gray-500 cursor-not-allowed"
                  : "bg-card-bg2 hover:bg-green-500 hover:text-black duration-300 hover:font-semibold"
              }`}
            >
              Passkeys
              {(currentAuthType === DEVICE_AUTH_TYPE.NONE ||
                currentAuthType === DEVICE_AUTH_TYPE.PASSWORD) && (
                <Lock size={20} />
              )}
            </button>

            <button
              disabled={
                currentAuthType === DEVICE_AUTH_TYPE.PASSKEY ||
                currentAuthType === DEVICE_AUTH_TYPE.NONE
              }
              onClick={() => handleOptionClick(DEVICE_AUTH_TYPE.PASSWORD)}
              className={`p-2 rounded-xl  flex gap-2 items-center justify-center ${
                currentAuthType === DEVICE_AUTH_TYPE.PASSKEY ||
                currentAuthType === DEVICE_AUTH_TYPE.NONE
                  ? "text-black font-semibold bg-gray-500 cursor-not-allowed"
                  : "bg-card-bg2 hover:bg-amber-500 hover:text-black duration-300 hover:font-semibold"
              }`}
            >
              Password
              {(currentAuthType === DEVICE_AUTH_TYPE.NONE ||
                currentAuthType === DEVICE_AUTH_TYPE.PASSKEY) && (
                <Lock size={20} />
              )}
            </button>

            <button
              onClick={() => handleOptionClick(DEVICE_AUTH_TYPE.NONE)}
              className="p-2 rounded-xl bg-card-bg2 hover:bg-red-500 hover:text-black duration-300 hover:font-semibold"
            >
              None
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TransactionSettingOption;
