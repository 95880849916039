/**
 * Description of how to add types.
 * 1. Initiate a block with a comment.
 * 2. Add complete path of the file where this type is used.
 * 3. Name of the variable should be in the format: `${varName}Types`
 *  for ex.: If the component is Dashboard, then type variable name would be DashboardType
 * 4. Since types are constants, the variable name should always start with capital letters.
 * 5. Start and end type of a file with // -----
 */
import { getChainDetails } from "../utils/helper";
import { DEVICE_AUTH_TYPE, NATIVE_ADDRESS, WALLET_TYPE } from "./Enums";
import {
  TransferStateType,
  NFT,
  DeviceType,
  Token,
  TransactionDrawerDetailsType,
  AdvertiserDetails,
  UserSettingsType,
  TransactionSettingsType,
  AccountType,
  CompletedTxnAPIType,
} from "./Types";

// ----- Transfer State ----- //

export const TokenDefault: Token = {
  address: "",
  decimal: 0,
  logo: "",
  name: "",
  symbol: "",
  balance: 0,
  amount: 0,
};

export const NftDefault: NFT = {
  collectionName: "",
  address: "",
  id: "",
  name: "",
  imageUrl: "",
  description: "",
};

export const TransferStateDefault: TransferStateType = {
  name: "",
  uid: "",
  address: "",
  assets: [],
  usersDomainName: "",
};

// ----- END

// ----- SelectedTokenForGas ----- //
// ./src/pages/Transaction/ApproveTransaction.tsx
export const SelectedTokenForGasDefault = (chainId: number) => ({
  icon: getChainDetails(chainId).coinUri,
  tokenName: getChainDetails(chainId).name,
  tokenSymbol: getChainDetails(chainId).nativeAsset,
  tokenAddress: NATIVE_ADDRESS,
  tokenGasValue: 0,
});

// ----- END

// ----- DEVICE ----- //

export const DeviceDefault: DeviceType = {
  address: "",
  hashCode: "",
  id: "",
  name: "",
  signer: "",
  authType: DEVICE_AUTH_TYPE.NONE,
};

// ----- END

// ----- TransactionCompletionDrawer ----- //

export const TransactionDrawerDetailsDefault: TransactionDrawerDetailsType = {
  transactionHash: "",
  isError: false,
  data: {}, // would be error object if isError = true, else transaction data if required
};

// ----- END

// ----- GasManagement ----- //
// ./src/components/GasManagement/*

export const AdvertiserDetailsDefault: AdvertiserDetails = {
  advertiserAdUrl: "",
  advertiserWebsite: "",
  advertiserName: "",
  advertiserChains: [],
};

// ----- END

// ----- UserSettingsType ----- //
// src/pages/OnboardingAndAuthentication/Landing/WelcomePage.tsx
// src/pages/Settings/AddressBook/index.tsx
// src/components/Modals/AddAddressModal.tsx
export const TransactionSettingsDefault: TransactionSettingsType = {
  connect: DEVICE_AUTH_TYPE.NONE,
  signMsg: DEVICE_AUTH_TYPE.NONE,
  approveTransaction: DEVICE_AUTH_TYPE.NONE,
};

export const AccountDefault: AccountType = {
  address: "",
  name: "",
};

export const UserSettingsDefault: UserSettingsType = {
  id: "",
  hashCode: "",
  address: "", // EOA
  activeSmartAccountAddress: "",
  walletName: "",
  walletType: WALLET_TYPE.GENERATED,
  mnemonic: null,
  // walletVersion: "string",
  accounts: [AccountDefault],
  settings: {
    preferences: {
      theme: "", // it will be enum type
      currency: "",
    },
    security: {
      autoLockTimer: 0,
      autoLockTimerLabel: "",
      authenticationType: DEVICE_AUTH_TYPE.NONE,
      password: null,
      biometrics: null,
    },
    recoveryPhrase: {
      seedPhraseBackedUp: false,
    },
    addressBook: [AccountDefault],
    transactionSettings: TransactionSettingsDefault,
    developerMode: {
      showTestnet: false,
      turnOffAds: false,
    },
  },
};

// ----- END

// ----- CompletedTxnAPI  ----- //
// ./src/utils/api.tsx
export const CompletedTxnAPIDefault: CompletedTxnAPIType = {
  address: "",
  chainId: "",
  transactionHash: "",
  status: "",
  gasFeeType: "",
  transactionId: "",
  receipt: {},
  transactions: [{}],
};

// ----- END
