import dappConnection from "../assets/illustrations/dapp-connection.png";
import gasless from "../assets/illustrations/gasless.png";
import smartWallet from "../assets/illustrations/smart-wallets.png";
import stateOfArt from "../assets/illustrations/state-of-art.png";
import multiChain from "../assets/illustrations/multiChain.png";

const illustrationsData = [
  {
    index: 0,
    name: "Multi-Chain Wallet",
    icon: multiChain,
    desc: "Effortlessly manage your assets across multiple EVM chains with just a click! WalletX supports multiple wallets, each with its own seed phrase and independent wallet.",
  },
  {
    index: 1,
    name: "gasless",
    icon: gasless,
    desc: "Experience on-chain transactions without gas fees! With WalletX, simply watch a short ad and say goodbye to gas fees forever. No more transferring gas from other addresses or worries about getting doxed with burner wallets.",
  },
  {
    index: 2,
    name: "Fingerprint Authentication",
    icon: stateOfArt,
    desc: "WalletX uses passkeys for authentication and transaction signing. Approve transactions effortlessly with your fingerprint from your laptop or phone—no password needed.",
  },
  {
    index: 3,
    name: "Batch Transactions",
    icon: smartWallet,
    desc: "With WalletX, batch multiple transactions into one—send multiple tokens to multiple users effortlessly. Built on ERC-4337 Account Abstraction, WalletX’s “Smart Wallet Addresses” provide enhanced security and flexibility from your WalletX extension.",
  },
  {
    index: 4,
    name: "Dapp Connection",
    icon: dappConnection,
    desc: "Connect WalletX seamlessly to your favorite DApps and explore the Web3 ecosystem with ease!",
  },
];

export default illustrationsData;
