import { log } from "../../utils/helper";
import PageTitle from "../../components/PageTitle";
import { PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";

const AccountAndKeys = () => {
  const temp = "";

  log("This is temp", temp);
  return (
    <>
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        leftDestinationRoute="/dashboard/settings"
        title="Account Details"
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
      />
    </>
  );
};

export default AccountAndKeys;
