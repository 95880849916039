import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ChevronRight } from "react-feather";
import { motion } from "framer-motion";
import PageTitle from "../../../components/PageTitle";
import {
  DEVICE_AUTH_TYPE,
  PAGE_TITLE_BUTTON_TYPE,
} from "../../../constants/Enums";
import { UserSettingsType } from "../../../constants/Types";
import {
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../../utils/helper";
import ToggleSwitch from "../../../components/ToggleSwitch";
import AddPasswordAndPasskeys from "../../../components/Modals/AddPasswordAndPasskeys";
import RemoveModal from "../../../components/Modal";
import VerifyPassword from "../../../components/Modals/VerifyPassword";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

import trashIcon from "../../../assets/trash.svg";
import settingIcon from "../../../assets/setting.svg";
import trashIconFilled from "../../../assets/trashFilled.svg";
import settingIconFilled from "../../../assets/settingFilled.svg";
import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const Security = () => {
  const [isPasswordEnabled, setIsPasswordEnabled] = useState<boolean>(false);
  const [isPasskeysEnabled, setIsPasskeysEnabled] = useState<boolean>(false);
  const [autoLockTimer, setAutoLockTimer] = useState<string>("");
  const [isPasswordAndPasskeysModalOpen, setIsPasswordAndPasskeysModalOpen] =
    useState<boolean>(false);
  const [authenticationTypeInDB, setAuthenticationTypeInDB] = useState(
    DEVICE_AUTH_TYPE.NONE,
  );
  const [modalType, setModalType] = useState<DEVICE_AUTH_TYPE>(
    DEVICE_AUTH_TYPE.NONE,
  );

  const location = useLocation();

  const [
    isDisablePasswordAndPasskeysModalOpen,
    setIsDisablePasswordAndPasskeysModalOpen,
  ] = useState<boolean>(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deleteHovered, setDeleteHovered] = useState<boolean>(false);
  const [deletePasskeyHovered, setDeletePasskeyHovered] =
    useState<boolean>(false);
  const [settingHovered, setSettingHovered] = useState<boolean>(false);

  const { eoaAddress } = useWalletConfig();
  const {
    isVerifyPassModalOpen,
    isVerifying,
    verifyPassword,
    showPasswordError,
  } = usePasswordVerification();
  const navigate = useNavigate();

  const togglePassword = async () => {
    if (isPasswordEnabled) {
      setModalType(DEVICE_AUTH_TYPE.PASSWORD);
      setIsDisablePasswordAndPasskeysModalOpen(true);
    } else {
      setModalType(DEVICE_AUTH_TYPE.PASSWORD);
      setIsPasswordAndPasskeysModalOpen(true);
    }
  };

  const togglePasskeys = async () => {
    if (isPasskeysEnabled) {
      setModalType(DEVICE_AUTH_TYPE.PASSKEY);
      setIsDisablePasswordAndPasskeysModalOpen(true);
    } else {
      setModalType(DEVICE_AUTH_TYPE.PASSKEY);
      setIsPasswordAndPasskeysModalOpen(true);
    }
  };

  const closePasswordAndPasskeysModal = () => {
    setIsPasswordAndPasskeysModalOpen(false);
    // when the authentication type is NONE then the switch should be off
    if (authenticationTypeInDB === DEVICE_AUTH_TYPE.NONE) {
      if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
        setIsPasswordEnabled(false);
      } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
        setIsPasskeysEnabled(false);
      }

      return;
    }

    if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
      setIsPasswordEnabled(!isPasswordEnabled);
    } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
      setIsPasskeysEnabled(!isPasskeysEnabled);
    }
  };

  const closeDisablePasswordAndPasskeysModal = () => {
    setIsDisablePasswordAndPasskeysModalOpen(false);

    if (authenticationTypeInDB === DEVICE_AUTH_TYPE.NONE) {
      if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
        setIsPasswordEnabled(false);
      } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
        setIsPasskeysEnabled(false);
      }

      return;
    }

    if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
      setIsPasswordEnabled(!isPasswordEnabled);
    } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
      setIsPasskeysEnabled(!isPasskeysEnabled);
    }
  };

  const handleDisablePasswordAndPasskeys = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const currentAuthType =
          usersSettings[userIndex].settings.security.authenticationType;

        if (currentAuthType === DEVICE_AUTH_TYPE.BOTH) {
          usersSettings[userIndex].settings.security.authenticationType =
            modalType === DEVICE_AUTH_TYPE.PASSWORD
              ? DEVICE_AUTH_TYPE.PASSKEY
              : DEVICE_AUTH_TYPE.PASSWORD;
        } else {
          usersSettings[userIndex].settings.security.authenticationType =
            DEVICE_AUTH_TYPE.NONE;
        }

        // Update transactionSettings
        if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
          usersSettings[userIndex].settings.transactionSettings.signMsg =
            usersSettings[userIndex].settings.transactionSettings.signMsg ===
            DEVICE_AUTH_TYPE.PASSWORD
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings.signMsg;
          usersSettings[
            userIndex
          ].settings.transactionSettings.approveTransaction =
            usersSettings[userIndex].settings.transactionSettings
              .approveTransaction === DEVICE_AUTH_TYPE.PASSWORD
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings
                  .approveTransaction;
        } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
          usersSettings[userIndex].settings.transactionSettings.signMsg =
            usersSettings[userIndex].settings.transactionSettings.signMsg ===
            DEVICE_AUTH_TYPE.PASSKEY
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings.signMsg;
          usersSettings[
            userIndex
          ].settings.transactionSettings.approveTransaction =
            usersSettings[userIndex].settings.transactionSettings
              .approveTransaction === DEVICE_AUTH_TYPE.PASSKEY
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings
                  .approveTransaction;
        }

        await setUserSettingsData(usersSettings);

        const updatedAuthType: DEVICE_AUTH_TYPE =
          usersSettings[userIndex].settings.security.authenticationType;

        if (updatedAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
          setIsPasswordEnabled(true);
        } else if (updatedAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
          setIsPasskeysEnabled(true);
        }
      }

      setIsDisablePasswordAndPasskeysModalOpen(false);
    } catch (error) {
      log("Error disabling password and passkeys:", error);
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deletePasswordAndPasskey = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        if (modalType === DEVICE_AUTH_TYPE.PASSWORD) {
          usersSettings[userIndex].settings.security.password = null;

          if (
            usersSettings[userIndex].settings.security.authenticationType ===
            DEVICE_AUTH_TYPE.BOTH
          ) {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.PASSKEY;
          } else {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.NONE;
          }

          // Update transactionSettings
          usersSettings[userIndex].settings.transactionSettings.signMsg =
            usersSettings[userIndex].settings.transactionSettings.signMsg ===
            DEVICE_AUTH_TYPE.PASSWORD
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings.signMsg;
          usersSettings[
            userIndex
          ].settings.transactionSettings.approveTransaction =
            usersSettings[userIndex].settings.transactionSettings
              .approveTransaction === DEVICE_AUTH_TYPE.PASSWORD
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings
                  .approveTransaction;

          setIsPasswordEnabled(!isPasswordEnabled);
        } else if (modalType === DEVICE_AUTH_TYPE.PASSKEY) {
          usersSettings[userIndex].settings.security.biometrics = null;

          if (
            usersSettings[userIndex].settings.security.authenticationType ===
            DEVICE_AUTH_TYPE.BOTH
          ) {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.PASSWORD;
          } else {
            usersSettings[userIndex].settings.security.authenticationType =
              DEVICE_AUTH_TYPE.NONE;
          }

          // Update transactionSettings
          usersSettings[userIndex].settings.transactionSettings.signMsg =
            usersSettings[userIndex].settings.transactionSettings.signMsg ===
            DEVICE_AUTH_TYPE.PASSKEY
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings.signMsg;
          usersSettings[
            userIndex
          ].settings.transactionSettings.approveTransaction =
            usersSettings[userIndex].settings.transactionSettings
              .approveTransaction === DEVICE_AUTH_TYPE.PASSKEY
              ? DEVICE_AUTH_TYPE.NONE
              : usersSettings[userIndex].settings.transactionSettings
                  .approveTransaction;

          setIsPasskeysEnabled(!isPasskeysEnabled);
        }

        await setUserSettingsData(usersSettings);
      }

      setIsDeleteModalOpen(false);
    } catch (error) {
      log("Error deleting password and passkeys:", error);
    }
  };

  const handleUpdatePassword = async () => {
    const isValid = await verifyPassword();

    if (isValid) {
      navigate("/settings/security/update-password");
    }
  };

  useEffect(() => {
    const fetchSecuritySettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();
        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { autoLockTimerLabel, authenticationType } =
            usersSettings[userIndex].settings.security;

          setAuthenticationTypeInDB(authenticationType);

          setAutoLockTimer(autoLockTimerLabel);
          setIsPasswordEnabled(
            authenticationType === DEVICE_AUTH_TYPE.PASSWORD ||
              authenticationType === DEVICE_AUTH_TYPE.BOTH,
          );
          setIsPasskeysEnabled(
            authenticationType === DEVICE_AUTH_TYPE.PASSKEY ||
              authenticationType === DEVICE_AUTH_TYPE.BOTH,
          );
        } else {
          setAutoLockTimer("15 minutes");
        }
      } catch (error) {
        log("Error fetching security settings:", error);
      }
    };

    fetchSecuritySettings();
  }, [
    eoaAddress,
    isPasskeysEnabled,
    isPasswordEnabled,
    isDisablePasswordAndPasskeysModalOpen,
    isDeleteModalOpen,
    isPasswordAndPasskeysModalOpen,
    modalType,
    authenticationTypeInDB,
  ]);

  return (
    <PageTransitionWrapper back={location.state?.back}>
      <div>
        <PageTitle
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          leftDestinationRoute="/dashboard/settings"
          backRouteState={true}
          title="Security"
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        />
        <div className="px-2">
          <Link
            to="/settings/security/auto-lock"
            className={`flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
          >
            <h1 className="font-medium text-md">Auto-lock Timer</h1>
            <div className="flex justify-center items-center">
              <p className="text-gray-300 text-xs">{autoLockTimer}</p>
              <ChevronRight />
            </div>
          </Link>

          <Link
            to="/setting/security/transaction-setting"
            className={`flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
          >
            <div>
              <h1 className="font-medium text-md">DApp Connection Settings</h1>
              <p className="text-xs text-gray-500 w-4/5">
                Select the authentication method you want to use for dapp
                interaction
              </p>
            </div>
            <div className="flex justify-center items-center">
              <ChevronRight />
            </div>
          </Link>

          <div
            className={`flex justify-between items-center gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
          >
            <div className="max-w-[75%]">
              <h1 className="font-medium text-md">Password</h1>
              <p className="text-xs text-gray-500">
                Secure your wallet and also you can use it as an added
                authentication for your transactions.
              </p>
            </div>
            <div className=" flex flex-col gap-2 justify-between items-center">
              <ToggleSwitch
                width={40}
                value={isPasswordEnabled}
                onChange={togglePassword}
              />
              {isPasswordEnabled && (
                <div className="flex gap-2">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <motion.img
                          onHoverStart={() => setSettingHovered(true)}
                          onHoverEnd={() => setSettingHovered(false)}
                          onClick={handleUpdatePassword}
                          src={settingHovered ? settingIconFilled : settingIcon}
                          className="h-4"
                        />
                      </TooltipTrigger>
                      <TooltipContent className="fixed top-5 -right-10 bg-card-bg text-white border-black ">
                        <p className="whitespace-nowrap">Update Password</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <motion.img
                    onHoverStart={() => setDeleteHovered(true)}
                    onHoverEnd={() => setDeleteHovered(false)}
                    onClick={() => {
                      setIsDeleteModalOpen(true);
                      setModalType(DEVICE_AUTH_TYPE.PASSWORD);
                    }}
                    src={deleteHovered ? trashIconFilled : trashIcon}
                    className="h-4 w-4"
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className={`flex justify-between items-center gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
          >
            <div className="max-w-[75%]">
              <h1 className="font-medium text-md">Passkeys</h1>
              <p className="text-xs text-gray-500">
                Secure your wallet and also you can use it as an added
                authentication for your transactions.
              </p>
            </div>
            <div className=" flex flex-col gap-2 justify-between items-center">
              <ToggleSwitch
                width={40}
                value={isPasskeysEnabled}
                onChange={togglePasskeys}
              />
              {
                // If passkeys is enabled, show the edit and delete icons
                isPasskeysEnabled && (
                  <div className=" flex gap-2 place-self-end">
                    <motion.img
                      onHoverStart={() => setDeletePasskeyHovered(true)}
                      onHoverEnd={() => setDeletePasskeyHovered(false)}
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                        setModalType(DEVICE_AUTH_TYPE.PASSKEY);
                      }}
                      src={deletePasskeyHovered ? trashIconFilled : trashIcon}
                      className="h-4 w-4"
                    />
                  </div>
                )
              }
            </div>
          </div>

          <div
            className={`flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
          >
            <div>
              <h1 className="font-medium text-md">WalletX Guard</h1>
              <p className="text-xs text-gray-500 w-4/5">Coming Soon</p>
            </div>
            <div className="flex justify-center items-center">
              <ChevronRight />
            </div>
          </div>
        </div>
      </div>
      <AddPasswordAndPasskeys
        isModalOpen={isPasswordAndPasskeysModalOpen}
        modalType={modalType}
        onClose={closePasswordAndPasskeysModal}
        isPasswordEnabled={isPasswordEnabled}
        isPasskeysEnabled={isPasskeysEnabled}
      />
      <RemoveModal
        actionBtnName="Disable"
        isOpen={isDisablePasswordAndPasskeysModalOpen}
        onCancel={closeDisablePasswordAndPasskeysModal}
        onRemove={handleDisablePasswordAndPasskeys}
        message={`Do you want to disable ${
          modalType === DEVICE_AUTH_TYPE.PASSWORD ? "Password" : "Passkeys"
        }?`}
      />
      <RemoveModal
        actionBtnName="Delete"
        isOpen={isDeleteModalOpen}
        onCancel={() => closeDeleteModal()}
        onRemove={() => deletePasswordAndPasskey()}
        message={`Do you want to Delete ${
          modalType === DEVICE_AUTH_TYPE.PASSWORD ? "Password" : "Passkeys"
        }?`}
      />
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </PageTransitionWrapper>
  );
};

export default Security;
