import { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { PAGE_TITLE_BUTTON_TYPE, LOCK_TIME } from "../../../constants/Enums";
import check from "../../../assets/check.svg";

import { UserSettingsType } from "../../../constants/Types";
import {
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../../utils/helper";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const AutoLock = () => {
  const [selectedTime, setSelectedTime] = useState<null | number>(null);

  const { eoaAddress } = useWalletConfig();

  useEffect(() => {
    const fetchAutoLockSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();
        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { autoLockTimer } = usersSettings[userIndex].settings.security;

          setSelectedTime(autoLockTimer);
        }
      } catch (error) {
        log("Error fetching auto-lock settings:", error);
      }
    };

    fetchAutoLockSettings();
  }, [eoaAddress]);

  const updateAutoLockTime = async (_time: number, _label: string) => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        usersSettings[userIndex].settings.security.autoLockTimer = _time;
        usersSettings[userIndex].settings.security.autoLockTimerLabel = _label;
        await setUserSettingsData(usersSettings);
      }
    } catch (error) {
      log("Error updating auto-lock time:", error);
    }
  };

  return (
    <PageTransitionWrapper>
      <div className="h-full w-full">
        <PageTitle
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          leftDestinationRoute="/settings/security"
          title="Auto Lock"
          backRouteState={true}
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        />

        <div className="h-[530px] rounded-xl mx-2 bg-card-bg flex flex-col divide-y divide-gray-700">
          {Object.values(LOCK_TIME).map((item) => (
            <div
              key={item.minutes}
              onClick={() => {
                setSelectedTime(item.minutes);
                if (selectedTime !== null) {
                  updateAutoLockTime(selectedTime, item.time);
                }
              }}
              className="flex-grow flex items-center justify-between pl-6 pr-4 text-sm"
            >
              {item.time}
              {selectedTime === item.minutes && (
                <img src={check} className="h-5" />
              )}
            </div>
          ))}
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default AutoLock;
