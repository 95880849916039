/* eslint-disable @typescript-eslint/no-unused-vars */
import localforage from "localforage";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import toast from "react-hot-toast";
import { transferState } from "../../../../state/TransferState";
import Collection from "../../../Collection";
import { log } from "../../../../utils/helper";
import { ASSET_TYPE } from "../../../../constants/Enums";
import {
  AssetSelectionType,
  CollectionType,
  NFT,
  TransferStateType,
} from "../../../../constants/Types";
import useWalletConfig from "../../../../lib/store/hooks/useWalletConfig";

const NftsForAssetSelection = ({
  transactionUID,
  assetUID,
  onClose,
}: AssetSelectionType) => {
  // If assetUID is present then update the array of asset in the transactionUId with the same assetUID
  const [nftData, setNftData] = useState<any>();
  const { smartAccountAddress, chainID } = useWalletConfig();
  const [transferData, setTransferData] = useRecoilState(transferState);

  const getNftDataFromIndexedDB = async () => {
    try {
      const userAccount = smartAccountAddress;

      const storedNftData: any = (await localforage.getItem("NftData")) || {};

      if (storedNftData[userAccount] && storedNftData[userAccount][chainID]) {
        setNftData(storedNftData[userAccount][chainID]);
      } else {
        log("No NFT data found for the specified user account and chain ID");
      }
    } catch (error) {
      log("Error retrieving NFT data from IndexedDB", error, "error");
    }
  };

  const addNFT = (_collection: CollectionType, _tokenID: string) => {
    const getNameAndImageUrl = () => {
      const selectedNFT = _collection.nftsData.find(
        (nft: NFT) => nft.id === _tokenID,
      );

      if (selectedNFT) {
        const nftUrl = selectedNFT.imageUrl;
        const { name } = selectedNFT;

        return { nftUrl, name };
      }

      log("Token ID not found in the collection", _collection, "error");
      return null;
    };

    const address = _collection.tokenAddress;
    const collectionName = _collection.name;
    const id = _tokenID;
    const imageUrl = getNameAndImageUrl()?.nftUrl || "";
    const name = getNameAndImageUrl()?.name || "";

    const newTransferData = transferData.map(
      (transferDetail: TransferStateType) => {
        let data: TransferStateType = { ...transferDetail };

        if (
          transferDetail.uid === transactionUID &&
          assetUID !== "" &&
          data.assets
        ) {
          const tokenAlreadyAdded = data.assets.some(
            (asset) => asset.nftDetails && asset.nftDetails.address === address,
          );

          if (tokenAlreadyAdded) {
            toast.error(`${collectionName} is already added for this asset.`);
            return data; // Return the unchanged data
          }

          const updatedAssets = data.assets.map((asset) => {
            if (asset.assetUID === assetUID) {
              return {
                ...asset,
                assetType: ASSET_TYPE.NFT,
                tokenDetails: undefined,
                nftDetails: {
                  address,
                  collectionName,
                  id,
                  name,
                  imageUrl,
                  description: "",
                },
              };
            }

            return asset;
          });

          data = {
            ...data,
            assets: updatedAssets,
          };
        } else if (transferDetail.uid === transactionUID && data.assets) {
          const tokenAlreadyAdded = data.assets.some(
            (asset) => asset.nftDetails && asset.nftDetails.address === address,
          );

          if (tokenAlreadyAdded) {
            toast.error(`${collectionName} is already added for this address.`);
          } else {
            data = {
              ...data,
              assets: [
                ...data.assets,
                {
                  assetUID: crypto.randomUUID(),
                  assetType: ASSET_TYPE.NFT,
                  nftDetails: {
                    address,
                    collectionName,
                    id,
                    imageUrl,
                    name,
                    description: "",
                  },
                },
              ],
            };
          }
        }

        return data;
      },
    );

    setTransferData(newTransferData);
    log("Transfer data added successfully NFT ", newTransferData, "success");
    onClose();
  };

  useEffect(() => {
    getNftDataFromIndexedDB();
  }, []);

  return (
    <div className="p-4 pt-0 h-[275px] overflow-y-scroll">
      {nftData && nftData.length ? (
        <>
          {nftData.map((collection: CollectionType, index: number) => (
            <div key={index}>
              <div>
                <Collection
                  name={collection.name}
                  tokenAddress={collection.tokenAddress}
                  nftsData={collection.nftsData}
                  canOpenNFTDetailsPage={false}
                  onNFTClick={(_tokenID: string) =>
                    addNFT(collection, _tokenID)
                  }
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center h-full w-full ">
          <p className=" text-sm mb-4  ">No NFT data available.</p>
        </div>
      )}
    </div>
  );
};

export default NftsForAssetSelection;
