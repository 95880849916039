import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import toast from "react-hot-toast";
import { motion } from "framer-motion";

import TokenCardTransaction from "../../../TokenCardTransaction";
import { getItemFromStorage, log } from "../../../../utils/helper";
import { transferState } from "../../../../state/TransferState";
import {
  Token,
  TransferStateType,
  AssetSelectionType,
} from "../../../../constants/Types";
import { ASSET_TYPE, STORAGE_KEYS } from "../../../../constants/Enums";
import useWallet from "../../../../lib/store/hooks/useWallet";
import useAssets from "../../../../lib/store/hooks/useAssets";

const TokenForAssetSelection = ({
  transactionUID,
  assetUID,
  onClose,
  searchValue,
}: AssetSelectionType) => {
  const { tokens, loading } = useAssets();
  const [transferData, setTransferData] = useRecoilState(transferState);
  const { smartAccountAddress } = useWallet();
  const SCW = getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
  const [allTokens, setAllTokens] = useState<Token[]>([]);

  const addToken = (token: Token, _balance: any) => {
    const newTransferData = transferData.map(
      (transferDetail: TransferStateType) => {
        let data: TransferStateType = { ...transferDetail };

        if (
          transferDetail.uid === transactionUID &&
          assetUID !== "" &&
          data.assets
        ) {
          const tokenAlreadyAdded = data.assets.some(
            (asset) =>
              asset.tokenDetails &&
              asset.tokenDetails.address === token.address,
          );

          if (tokenAlreadyAdded) {
            toast.error(
              `Token ${token.symbol} is already added for this asset.`,
            );
            return data; // Return the unchanged data
          }

          const updatedAssets = data.assets.map((asset) => {
            if (asset.assetUID === assetUID) {
              return {
                ...asset,
                assetType: ASSET_TYPE.TOKEN,
                nftDetails: undefined,
                tokenDetails: {
                  address: token.address,
                  decimal: token.decimal,
                  logo: token.logo,
                  name: token.name,
                  symbol: token.symbol,
                  balance: _balance,
                  amount: 0,
                },
              };
            }

            return asset;
          });

          data = {
            ...data,
            assets: updatedAssets,
          };
        } else if (transferDetail.uid === transactionUID && data.assets) {
          const tokenAlreadyAdded = data.assets.some(
            (asset) =>
              asset.tokenDetails &&
              asset.tokenDetails.address === token.address,
          );

          if (tokenAlreadyAdded) {
            toast.error(
              `Token ${token.symbol} is already added for this address.`,
            );
          } else {
            data = {
              ...data,
              assets: [
                ...data.assets,
                {
                  assetUID: crypto.randomUUID(),
                  assetType: ASSET_TYPE.TOKEN,
                  tokenDetails: {
                    address: token.address,
                    decimal: token.decimal,
                    logo: token.logo,
                    name: token.name,
                    symbol: token.symbol,
                    balance: _balance,
                    amount: 0,
                  },
                },
              ],
            };
          }
        }

        return data;
      },
    );

    setTransferData(newTransferData);
    log("Transfer data added successfully TOKEN ", newTransferData, "success");
    onClose();
  };

  const renderFilteredTokens = () => {
    const lowerCaseSearchValue = (searchValue || "").toLowerCase();
    const matchingTokens: any = [];
    const nonMatchingTokens: any = [];

    if (!lowerCaseSearchValue) {
      // Create a shallow copy of allTokens and sort by temporary balance in descending order
      const sortedTokensByBalance = [...allTokens].sort(
        (a: Token, b: Token) => {
          const balanceA = a.tokenBalance ?? 0;
          const balanceB = b.tokenBalance ?? 0;

          return balanceB - balanceA;
        },
      );

      return sortedTokensByBalance.map((token: Token) => (
        <motion.div layout key={token.address}>
          <TokenCardTransaction
            tokenIcon={token.logo}
            tokenName={token.name}
            tokenSymbol={token.symbol}
            tokenAddress={token.address}
            tokenDecimal={token.decimal}
            userAddress={SCW || smartAccountAddress}
            clickedTokenData={(_balance) => addToken(token, _balance)}
            key={token.address}
            tokenBalance={token.balance || 0}
          />
        </motion.div>
      ));
    }

    allTokens.forEach((token: Token) => {
      const tokenName = token.name.toLowerCase();
      const tokenSymbol = token.symbol.toLowerCase();
      let score = 0;

      if (lowerCaseSearchValue) {
        // Adjust scoring to give more preference to tokenSymbol matches
        if (tokenSymbol.includes(lowerCaseSearchValue)) {
          score += 2; // Higher score for symbol match
        }

        if (tokenName.includes(lowerCaseSearchValue)) {
          score += 1; // Lower score for name match
        }
      }

      if (score > 0) {
        matchingTokens.push({ ...token, score });
      } else {
        nonMatchingTokens.push(token);
      }
    });

    // Sort matching tokens first by score, then by closeness to search value for symbols, then names
    const sortedMatchingTokens = matchingTokens.sort((a: any, b: any) => {
      if (b.score === a.score) {
        // If scores are equal, sort by closeness to the search value
        const symbolA = a.symbol.toLowerCase();
        const symbolB = b.symbol.toLowerCase();
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        const symbolADistance = Math.abs(symbolA.indexOf(lowerCaseSearchValue));
        const symbolBDistance = Math.abs(symbolB.indexOf(lowerCaseSearchValue));
        const nameADistance = Math.abs(nameA.indexOf(lowerCaseSearchValue));
        const nameBDistance = Math.abs(nameB.indexOf(lowerCaseSearchValue));

        // Prioritize symbol closeness over name closeness
        if (symbolADistance !== symbolBDistance) {
          return symbolADistance - symbolBDistance;
        }

        if (nameADistance !== nameBDistance) {
          return nameADistance - nameBDistance;
        }
      }

      return b.score - a.score; // Primary sort by score
    });

    const finalTokens = sortedMatchingTokens.concat(nonMatchingTokens);

    return finalTokens.map((token: Token) => (
      <motion.div layout key={token.address}>
        <TokenCardTransaction
          tokenIcon={token.logo}
          tokenName={token.name}
          tokenSymbol={token.symbol}
          tokenAddress={token.address}
          tokenDecimal={token.decimal}
          userAddress={SCW || smartAccountAddress}
          clickedTokenData={(_balance) => addToken(token, _balance)}
          key={token.address}
          tokenBalance={token.balance || 0}
        />
      </motion.div>
    ));
  };

  useEffect(() => {
    // Update allTokens when tokens from the useAssets hook change
    setAllTokens(tokens);
  }, [tokens]);

  useEffect(() => {
    renderFilteredTokens();
  }, [allTokens, searchValue]);

  return (
    <>
      <div className="overflow-y-scroll max-h-[315px]">
        {loading ? (
          <div>Loading tokens...</div> // Add a loading indicator
        ) : (
          renderFilteredTokens()
        )}
      </div>
    </>
  );
};

export default TokenForAssetSelection;
