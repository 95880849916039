import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FolderMinus, Plus } from "react-feather";

import { AddressInAddressBookType, UserSettingsType } from "../constants/Types";
import { getUserSettingsData, log } from "../utils/helper";
import AddressCard from "./AddressCard";
import { Button } from "./ui/button";
import AddAddressModal from "./Modals/AddAddressModal";
import useWallet from "../lib/store/hooks/useWallet";
import IllustrationLoader from "./IllustrationLoader";

type AddressBookEntriesType = {
  onClickOfAddressCard: (_address: string, _name: string) => void;
};

const AddressBookEntries = ({
  onClickOfAddressCard,
}: AddressBookEntriesType) => {
  const [addressBookEntries, setAddressBookEntries] = useState<
    AddressInAddressBookType[]
  >([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const currentRoute = location.pathname;
  const { eoaAddress } = useWallet();

  const openAddAddressModal = () => {
    setIsAddAddressModalOpen(true);
  };

  const closeAddAddressModal = () => {
    setIsAddAddressModalOpen(false);
  };

  const fetchAddressBookEntries = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const userAddressBook = usersSettings[userIndex].settings.addressBook;

        setAddressBookEntries(userAddressBook);
      } else {
        setAddressBookEntries([]);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchAddressBookEntries();
  }, [eoaAddress, addressBookEntries]);

  return (
    <div className="w-full">
      {loader ? (
        <div className="w-full h-full flex justify-center items-center mt-44">
          <IllustrationLoader height="1.8em" />
        </div>
      ) : (
        <>
          {addressBookEntries && addressBookEntries.length === 0 ? (
            <>
              <div className="flex flex-col gap-2 justify-center items-center    my-auto w-full h-fit px-5 py-6 bg-primary-bg   rounded-2xl mt-28">
                <FolderMinus size={50} className=" text-neutral-200" />
                <p className="text-lg text-gray-200 "> No Addresses Found</p>
                <p className="text-sm text-gray-500 font-medium text-center ">
                  Your address book is currently empty. <br />
                  Click below to add an address
                </p>
                {currentRoute !== "/settings/address-book" && (
                  <>
                    <div className="absolute group bottom-4 w-full flex justify-center items-center">
                      <Button
                        onClick={() => openAddAddressModal()}
                        className="gap-3"
                        variant={"tsxButton"}
                        size={"tsx"}
                      >
                        <span className="flex justify-center gap-2 items-center group-hover:scale-105">
                          <Plus />
                          Add Address
                        </span>
                      </Button>
                    </div>
                    <AddAddressModal
                      isOpen={isAddAddressModalOpen}
                      onClose={() => closeAddAddressModal()}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {addressBookEntries.map(
                (entries: AddressInAddressBookType, idx: number) => (
                  <div key={idx}>
                    <AddressCard
                      name={entries.name}
                      address={entries.address}
                      getClickedAddress={(_address) => log(_address)}
                      onClick={() => {
                        onClickOfAddressCard(entries.address, entries.name);
                      }}
                      isSelected={false}
                      usersDomainName={""}
                    />
                  </div>
                ),
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AddressBookEntries;
