import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";

import receiveIcon from "../assets/arrow-down.png";
import sendIcon from "../assets/arrow-up.png";
import swapIcon from "../assets/swap.png";
import bridgeIcon from "../assets/bridge.png";
import { getAccentColor } from "../utils/helper";
import useWalletConfig from "../lib/store/hooks/useWalletConfig";

type TransactParam = {
  isOpen: boolean;
};
const TransactDrawer = ({ isOpen }: TransactParam) => {
  const navigate = useNavigate();
  const { chainID } = useWalletConfig();
  const [accentColor, setAccentColor] = useState("#000000");

  const fetchTheme = async () => {
    const theme = await getAccentColor(chainID);

    setAccentColor(theme);
  };

  useEffect(() => {
    fetchTheme();
  }, [chainID]);

  const menu = [
    {
      icon: `${sendIcon}`,
      name: "Send",
      navigateTo: "/dashboard/transaction/add-address",
    },
    {
      icon: `${receiveIcon}`,
      name: "Receive",
      navigateTo: "/dashboard/receive",
    },

    {
      icon: `${swapIcon}`,
      name: "Swap",
      navigateTo: "",
    },
    {
      icon: `${bridgeIcon}`,
      name: "Bridge",
      navigateTo: "",
    },
  ];

  return (
    <>
      <div
        className={`${
          isOpen ? "bottom-0 " : " translate-y-full bg-transparent"
        }   fixed flex justify-center items-end bottom-0 left-1/2 translate-x-[-50%]  w-full h-[110%] bg-primary-bg   text-white  rounded-t-3xl  mt-10 px-4 py-5 transition duration-1000  transform bg-opacity-90 pb-24 z-20`}
      >
        <div className="flex flex-col gap-3 w-full justify-center text-md ">
          <h1 className="text-lg font-semibold tracking-wide mb-2 mx-auto">
            Transact
          </h1>
          <div className=" flex  flex-wrap justify-center gap-2 ">
            <TooltipProvider>
              {menu.reverse().map((item, index) => (
                <Tooltip key={index}>
                  <TooltipTrigger
                    style={{
                      background: item.navigateTo !== "" ? accentColor : "",
                    }}
                    className={`group flex flex-row  w-[48%] px-3 py-3 bg-[#323232] text-white border-2 border-gray-950  rounded-xl tracking-wide ${
                      item.navigateTo === ""
                        ? " cursor-default "
                        : " cursor-pointer "
                    }`}
                  >
                    <div
                      key={index}
                      onClick={() =>
                        item.navigateTo && navigate(item.navigateTo)
                      }
                      className={`w-full flex justify-start items-center gap-3  `}
                    >
                      <img
                        className={` h-9 p-2 rounded-full bg-white group-hover:scale-110`}
                        src={item.icon}
                        alt="bridgeIcon"
                      />
                      {item.name}
                    </div>
                  </TooltipTrigger>
                  {item.navigateTo === "" && (
                    <TooltipContent className="bg-secondary-bg text-white border-black">
                      <p>Coming Soon</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              ))}
            </TooltipProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactDrawer;
