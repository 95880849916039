import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit/react";
import localforage from "localforage";
import { ethers } from "ethers";
import { Token } from "../../../constants/Types";
import { getTokens } from "../../../utils/api";
import {
  checkIsNativeToken,
  getCoinBalance,
  getTokenBalance,
} from "../../../utils/helper";

interface AssetsState {
  tokens: Token[];
  loading: boolean;
  error: string | null;
}

const initialState: AssetsState = {
  tokens: [],
  loading: false,
  error: null,
};

export const fetchAssets = createAsyncThunk(
  "assets/fetchAssets",
  async (
    {
      chainId,
      userAddress,
      provider,
    }: {
      chainId: string;
      userAddress: string;
      provider: ethers.providers.Provider;
    },
    { rejectWithValue },
  ) => {
    try {
      const tokens: { [key: string]: Token[] } = await getTokens();

      const importedTokensData: { [key: string]: Token[] } | null =
        await localforage.getItem("importedTokenData");

      let chainTokens: Token[] = [...(tokens[chainId] || [])];

      if (importedTokensData) {
        Object.values(importedTokensData).forEach((tokenData: Token[]) => {
          if (tokenData[chainId as any]) {
            chainTokens = chainTokens.concat(tokenData[chainId as any]);
          }
        });
      }

      const tokensWithBalance = await Promise.all(
        chainTokens.map(async (token: Token) => {
          let balance;

          if (checkIsNativeToken(token.address)) {
            balance = await getCoinBalance(userAddress, provider);
          } else {
            balance = await getTokenBalance(
              token.address,
              provider,
              userAddress,
            );
          }

          return { ...token, balance };
        }),
      );

      return tokensWithBalance.sort(
        (a, b) => Number(b.balance) - Number(a.balance),
      );
    } catch (error) {
      return rejectWithValue("Failed to fetch assets");
    }
  },
);

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAssets.fulfilled,
        (state, action: PayloadAction<Token[]>) => {
          state.loading = false;
          state.tokens = action.payload;
        },
      )
      .addCase(fetchAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default assetsSlice.reducer;
