import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import PageTitle from "../../../components/PageTitle";
import {
  DEVICE_AUTH_TYPE,
  PAGE_TITLE_BUTTON_TYPE,
  TRANSACTION_TYPE,
} from "../../../constants/Enums";
import TransactionSettingOption from "../../../components/Modals/TransactionSettingOption";
import { UserSettingsType } from "../../../constants/Types";
import { getUserSettingsData, log } from "../../../utils/helper";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const TransactionSetting = () => {
  const [showModal, setShowModal] = useState(false);
  const [authMethodForSign, setAuthMethodForSign] = useState<DEVICE_AUTH_TYPE>(
    DEVICE_AUTH_TYPE.NONE,
  );
  const [authMethodForConnect, setAuthMethodForConnect] =
    useState<DEVICE_AUTH_TYPE>(DEVICE_AUTH_TYPE.NONE);
  const [authMethodForApproveTransaction, setAuthMethodForApproveTransaction] =
    useState<DEVICE_AUTH_TYPE>(DEVICE_AUTH_TYPE.NONE);
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TRANSACTION_TYPE>(TRANSACTION_TYPE.NONE);
  const { eoaAddress } = useWalletConfig();

  useEffect(() => {
    const fetchSecuritySettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();
        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { signMsg, connect, approveTransaction } =
            usersSettings[userIndex].settings.transactionSettings;

          setAuthMethodForSign(signMsg);
          setAuthMethodForConnect(connect);
          setAuthMethodForApproveTransaction(approveTransaction);
        }
      } catch (error) {
        log("This is error ", error);
      }
    };

    fetchSecuritySettings();
  }, [
    authMethodForApproveTransaction,
    authMethodForSign,
    authMethodForConnect,
    eoaAddress,
    showModal,
  ]);

  return (
    <PageTransitionWrapper>
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        leftDestinationRoute="/settings/security"
        title="Transaction Settings"
        backRouteState={true}
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
      />

      <div className="px-2">
        <div
          onClick={() => {
            setShowModal(true);
            setSelectedTransactionType(TRANSACTION_TYPE.CONNECT);
          }}
          className={`flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
        >
          <h1 className="font-medium text-md">Connect</h1>
          <div className="flex justify-center items-center">
            <p className="text-gray-300 text-xs">{authMethodForConnect}</p>
            <ChevronRight />
          </div>
        </div>
        <div
          onClick={() => {
            setShowModal(true);
            setSelectedTransactionType(TRANSACTION_TYPE.SIGN);
          }}
          className={`flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
        >
          <h1 className="font-medium text-md">Sign Message</h1>
          <div className="flex justify-center items-center">
            <p className="text-gray-300 text-xs">{authMethodForSign}</p>
            <ChevronRight />
          </div>
        </div>

        <div
          onClick={() => {
            setShowModal(true);
            setSelectedTransactionType(TRANSACTION_TYPE.APPROVE_TRANSACTION);
          }}
          className={`flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg pl-4 pr-2 py-3 w-full`}
        >
          <h1 className="font-medium text-md">Approve Transaction</h1>
          <div className="flex justify-center items-center">
            <p className="text-gray-300 text-xs">
              {authMethodForApproveTransaction}
            </p>
            <ChevronRight />
          </div>
        </div>
      </div>

      <TransactionSettingOption
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        transactionType={selectedTransactionType}
      />
    </PageTransitionWrapper>
  );
};

export default TransactionSetting;
