import { useEffect, useRef, useState } from "react";
import { Copy, Edit2, Trash } from "react-feather";
import localforage from "localforage";
import toast from "react-hot-toast";
import {
  copyToClipboard,
  getShortDisplayString,
  getUserSettingsData,
  log,
} from "../../utils/helper";
import RenameAddressModal from "./RenameAddressModal";
import Modal from "../Modal";
import {
  AddressInAddressBookType,
  ManageAddressModalType,
  UserSettingsType,
} from "../../constants/Types";
import { STORAGE_KEYS } from "../../constants/Enums";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const ManageAddressModal = ({
  isOpen,
  onClose,
  address,
  name,
}: ManageAddressModalType) => {
  const [isRenameAddressModalOpen, setIsRenameAddressModalOpen] =
    useState<boolean>(false);
  const [isDeleteAddressModalOpen, setIsDeleteAddressModalOpen] =
    useState<boolean>(false);
  const modal = useRef(null);
  const { eoaAddress } = useWalletConfig();

  const openRenameAddressModal = () => {
    setIsRenameAddressModalOpen(true);
    onClose();
  };

  const closeRenameAddressModal = () => {
    setIsRenameAddressModalOpen(false);
  };

  const openDeleteAddressModal = () => {
    setIsDeleteAddressModalOpen(true);
    onClose();
  };

  const closeDeleteAddressModal = () => {
    setIsDeleteAddressModalOpen(false);
  };

  const deleteAddressFromAddressBook = async (_address: string) => {
    try {
      const currentAddressBook: UserSettingsType[] =
        await getUserSettingsData();

      const userIndex = currentAddressBook.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      const addressBookOfEOA =
        currentAddressBook[userIndex].settings.addressBook;

      const updatedAddressBookOfEOA = addressBookOfEOA.filter(
        (entry: AddressInAddressBookType) => entry.address !== _address,
      );

      if (updatedAddressBookOfEOA.length === addressBookOfEOA.length) {
        toast.error("Address not found in the address book.");
        return;
      }

      currentAddressBook[userIndex].settings.addressBook =
        updatedAddressBookOfEOA;
      await localforage.setItem(STORAGE_KEYS.USER_SETTINGS, currentAddressBook);

      toast.success("Address deleted from the address book");
      closeDeleteAddressModal();
    } catch (error) {
      log("Error deleting address from AddressBook:", error, "error");
    }
  };

  useEffect(() => {
    const closeModalOnOutsideClick = (e: any) => {
      if (!(modal.current as any).contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", closeModalOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeModalOnOutsideClick);
    };
  });

  return (
    <>
      <div
        ref={modal}
        className={` ${
          isOpen ? "" : "hidden"
        } absolute top-10 right-4 flex flex-col text-sm  p-2  justify-center items-start gap-1  rounded-xl shadow-xl bg-card-bg border-2 border-black z-50 `}
      >
        <button
          onClick={() => openRenameAddressModal()}
          className="group flex w-full justify-start items-center  gap-2 hover:bg-card-bg2 px-3 py-2 rounded-lg"
        >
          <Edit2 size={16} className="group-hover:scale-110" />
          Rename Address
        </button>
        <button
          className="group flex w-full justify-start items-center  gap-2 hover:bg-card-bg2 px-3 py-2 rounded-lg"
          onClick={() => copyToClipboard(address)}
        >
          <Copy size={16} className="group-hover:scale-110" />
          <span className="flex flex-col justify-start items-start">
            Copy Address
            <span className="text-xs tracking-wide">
              ({getShortDisplayString(address)})
            </span>
          </span>
        </button>

        <hr className="border border-neutral-500  my-1 w-[95%] mx-auto" />
        <button
          onClick={() => openDeleteAddressModal()}
          className="group flex w-full justify-start items-center  gap-2 text-red-500 hover:bg-card-bg2 px-3 py-2 rounded-lg"
        >
          <Trash size={16} className="group-hover:scale-110" />
          Delete Address
        </button>
      </div>

      <RenameAddressModal
        isOpen={isRenameAddressModalOpen}
        onClose={() => closeRenameAddressModal()}
        address={address}
        name={name}
      />
      <Modal
        isOpen={isDeleteAddressModalOpen}
        onCancel={() => closeDeleteAddressModal()}
        onRemove={() => deleteAddressFromAddressBook(address)}
        message="Do you want to delete this address."
        actionBtnName="Delete"
      />
    </>
  );
};

export default ManageAddressModal;
