import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PublicRoutes from "./publicRoutes";
import PrivateRoutes from "./privateRoutes";
import {
  getItemFromStorage,
  getUserSettingsData,
  log,
  removeItemFromStorage,
  setItemInStorage,
} from "../utils/helper";
import EthProvider from "../dapp-connection/js/services/EthProvider";
import { STORAGE_KEYS } from "../constants/Enums";
import { UserSettingsType } from "../constants/Types";
import useWallet from "../lib/store/hooks/useWallet";

function AppRoutes() {
  const navigate = useNavigate();
  const { eoaAddress, auth, logout } = useWallet();
  const [autoLockTime, setAutoLockTime] = useState<number | null>(null);

  const storedTime = getItemFromStorage(STORAGE_KEYS.LAST_ACTIVE);
  const difference = Date.now() - storedTime;

  const redirectApp = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    try {
      EthProvider.init(redirectApp);
    } catch (e) {
      log("Error while initializing EthProvider ", e, "error");
    }
  });

  useEffect(() => {
    const fetchAutoLockTime = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();
        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { autoLockTimer } = usersSettings[userIndex].settings.security;

          setAutoLockTime(autoLockTimer);
        }
      } catch (error) {
        log("Error fetching auto-lock time:", error);
      }
    };

    fetchAutoLockTime();
  }, [eoaAddress]);

  useEffect(() => {
    if (autoLockTime && storedTime && difference > autoLockTime * 60 * 1000) {
      logout();
      setItemInStorage(STORAGE_KEYS.IS_LOGGED_IN, false);
      removeItemFromStorage(STORAGE_KEYS.LAST_ACTIVE);
      navigate("/login");
    } else {
      setItemInStorage(STORAGE_KEYS.LAST_ACTIVE, Date.now());
    }
  }, [autoLockTime, storedTime]);

  useEffect(() => {
    if (window?.history?.state?.usr?.showWelcome) {
      navigate(`/welcome`, {
        state: {
          showWelcome: true,
        },
      });
    }
  }, [
    getItemFromStorage(STORAGE_KEYS.IS_LOGGED_IN),
    window?.history?.state?.usr?.showWelcome,
    auth.isLoggedIn,
  ]);

  return auth.isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />;
}

export default AppRoutes;
