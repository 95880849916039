import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Ledger from "../../../assets/Ledger.svg";
import Login from "../../../assets/login.svg";
import SeedPhrase from "../../../assets/seedPhrase.svg";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import GradientBg from "../../../components/GradientBg";
import { migrateOldDevices, setAppVersion } from "../../../utils/helper";

const UseExistingWallet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showComingSoon, setShowComingSoon] = useState(false);

  useEffect(() => {
    migrateOldDevices();
  }, []);

  useEffect(() => {
    setAppVersion();
  }, []);

  return (
    <PageTransitionWrapper back={location.state?.back}>
      <GradientBg />
      <div className=" relative h-full w-full px-4  flex flex-col">
        <div className="flex justify-between  p-2 mt-4">
          <h1 className="text-lg font-semibold tracking-wide ">
            Use an existing wallet
          </h1>

          <X
            onClick={() => navigate("/register")}
            size={30}
            className="opacity-80 hover:opacity-100"
          />
        </div>
        <p className=" text-gray-500  px-2 text-wrap mt-5">
          Select how you want to your existing wallet.
        </p>

        <div className="flex flex-col gap-5 mt-10">
          <div
            onClick={() => navigate("/login")}
            className="bg-white backdrop:blur-md bg-opacity-10 flex items-center gap-3 border border-gray-200 border-opacity-40 hover:border-opacity-100   rounded-xl px-3 py-2"
          >
            <div>
              <p> Login </p>
              <p className="text-sm text-gray-500">
                Login to your already existing wallet.
              </p>
            </div>
            <img src={Login} className="h-12 opacity-90" alt="Login" />
          </div>
          <div
            onClick={() => navigate("/import-wallet")}
            className="bg-white backdrop:blur-md bg-opacity-10 flex items-center gap-3 border border-gray-200  border-opacity-40 hover:border-opacity-100  rounded-xl px-3 p-2"
          >
            <div>
              <p> Enter recovery phrase</p>
              <p className="text-sm text-gray-500">
                Securely import your wallet using your recovery phrase.
              </p>
            </div>
            <img
              src={SeedPhrase}
              className="h-11 opacity-90"
              alt="Recovery phrase"
            />
          </div>
          <motion.div
            onHoverStart={() => setShowComingSoon(true)}
            onHoverEnd={() => setShowComingSoon(false)}
            className="bg-white backdrop:blur-md bg-opacity-10  flex items-center gap-3  border border-gray-200  border-opacity-40 hover:border-opacity-100   rounded-xl px-3 p-2 relative"
          >
            <div className={showComingSoon ? "opacity-0 duration-300" : ""}>
              <p> Connect Ledger wallet</p>
              <p className="text-sm text-gray-500">
                Approve transaction using a ledger device.
              </p>
            </div>
            <img
              src={Ledger}
              className={`h-10 opacity-90 ${
                showComingSoon && "!opacity-0 duration-300"
              }`}
              alt="Ledger login"
            />
            <p
              className={`${
                !showComingSoon && "!opacity-0 duration-500 "
              } absolute top-0 left-0 flex justify-center items-center h-full w-full rounded-xl font-semibold`}
            >
              {" "}
              Coming soon{" "}
            </p>
          </motion.div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default UseExistingWallet;
