import dappConnection from "../assets/illustrations/dapp-connection.png";
import gasless from "../assets/illustrations/gasless.png";
import smartWallet from "../assets/illustrations/smart-wallets.png";
import stateOfArt from "../assets/illustrations/state-of-art.png";
import multiChain from "../assets/illustrations/multiChain.png";

const illustrations = [
  {
    src: multiChain,
    alt: "X",
    title: "Multichain Wallet",
    description:
      "Manage assets across EVM chains with WalletX: multi-wallet support, independent seed phrases, one-click convenience.",
  },
  {
    src: gasless,
    alt: "X",
    title: "Gasless",
    description:
      "Watch a brief ad to eliminate gas fees on WalletX: seamless transactions without transfers or privacy concerns.",
  },
  {
    src: stateOfArt,
    alt: "X",
    title: "Fingerprint Authentication",
    description:
      "WalletX uses passkeys: authenticate and sign transactions with just your fingerprint on any device.",
  },
  {
    src: smartWallet,
    alt: "X",
    title: "Batch Transactions",
    description:
      "WalletX enables batched multi-token, multi-recipient transactions via ERC-4337 Smart Wallet Addresses for enhanced security and efficiency.",
  },
  {
    src: dappConnection,
    alt: "Dapp Connection",
    title: "DApp Connection",
    description:
      "WalletX integrates smoothly with DApps for effortless Web3 exploration.",
  },
];

export default illustrations;
