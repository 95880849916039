import { getShortDisplayString } from "../../../utils/helper";
import Web3AvatarComponent from "../../../components/Web3AvatarComponent";

type SignatureScreenProps = {
  smartWalletAddress: string;
  chainData: any;
  storeData: any;
  balance: number;
  actualMessage: string;
  rejectSignRequest: (reason: string | null) => void;
  userSignMessage: () => void;
  dappName?: string;
};

export default function SignatureScreen({
  smartWalletAddress,
  chainData,
  storeData,
  balance,
  actualMessage,
  rejectSignRequest,
  userSignMessage,
  dappName,
}: SignatureScreenProps) {
  return (
    <div className=" mx-auto w-[341px] h-[600px] relative top-1/2 translate-y-[-50%] text-white  ">
      <div className=" py-2 flex items-center gap-4 w-full border-b border-slate-500">
        <div className="relative w-fit">
          <Web3AvatarComponent
            address={smartWalletAddress}
            className=" w-10 h-10"
          />
          <img
            src={chainData.coinUri}
            alt="logo"
            className="h-6 absolute top-[-25%] right-[-25%] rounded-full"
          />
        </div>

        <div className="w-full">
          <div className="flex w-full justify-between mb-2">
            <p>{chainData.name}</p>
            <p>Balance</p>
          </div>
          <div className="flex w-full justify-between">
            <p className="font-semibold">
              {getShortDisplayString(smartWalletAddress)}
            </p>
            <p className="font-semibold">
              {Number(balance).toFixed(5)} {chainData.nativeAsset}
            </p>
          </div>
        </div>
      </div>
      <p className="p-2 px-4 mt-6 mx-auto rounded-full border w-min max-w-full truncate">
        {dappName || storeData?.connect_origin}
      </p>
      <p className="mt-4 text-center text-3xl font-bold">Signature request</p>
      <p className="mt-2 mx-auto w-[90%] text-center text-slate-500 text-[14px] ">
        Only sign this message if you fully understand the content and trust the
        requesting site.
      </p>
      <p className="mt-4 mb-2 text-slate-500 text-[17px] text-center ">
        You are signing:
      </p>
      <div className="p-4 border-y border-slate-500 text-slate-500 max-h-[207px] overflow-y-scroll">
        <p className="mb-2 text-[17px]">Message:</p>
        <p className="text-wrap text-sm break-words">{actualMessage}</p>
      </div>
      <div className="py-4 absolute left-0 bottom-0 w-full border-t border-gray-500  flex justify-around gap-2">
        <button
          className="w-[46%] p-2 px-4  border hover:border-gray-500 shadow-lg rounded-lg"
          onClick={() => rejectSignRequest(null)}
        >
          Reject
        </button>
        <button
          className="w-[46%] p-2 px-4 bg-gray-300 hover:bg-gray-500 shadow-lg text-black rounded-lg"
          onClick={userSignMessage}
        >
          Sign
        </button>
      </div>
    </div>
  );
}
