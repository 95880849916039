import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";

const PageTransitionWrapper = ({ children, back }: any) => {
  const controls = useAnimation();
  const location = useLocation();

  useEffect(() => {
    const animateY = back ? [-5, 0] : [5, 0];

    controls.start({
      translateY: animateY,
      transition: { duration: 0.3 },
    });
  }, [controls, location.key, back]);

  return (
    <motion.div
      animate={controls}
      initial={{ translateY: back ? -5 : 5 }}
      className="h-full w-full"
    >
      {children}
    </motion.div>
  );
};

export default PageTransitionWrapper;
