import React from "react";
import localforage from "localforage";
import { Buffer } from "buffer";
import "process/browser";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { store } from "../src/lib/store/store";

global.Buffer = Buffer;

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "WalletX",
  storeName: "tokensData",
});

const root = document.createElement("div");

root.className = "root";
document.body.appendChild(root);
const rootDiv = ReactDOM.createRoot(root);

rootDiv.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
