import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { getAccentColor, getItemFromStorage } from "../../utils/helper";
import arrow from "../../assets/diagonal-right-up-arrow.svg";
import TransactionHistoryModal from "../Modals/TransactionHistoryModal";
import { TransactionData } from "../../constants/Types";
import confirmed from "../../assets/confiremed.png";
import rejected from "../../assets/failed.png";
import useWallet from "../../lib/store/hooks/useWallet";
import { STORAGE_KEYS } from "../../constants/Enums";

const TransactionHistory = ({
  transaction,
}: {
  transaction: TransactionData;
}) => {
  const { chainData } = useWallet();
  const chianIdInStorage = getItemFromStorage(STORAGE_KEYS.NETWORK);
  const chainId = chainData?.chainId || chianIdInStorage;
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);

  const [accentColor, setAccentColor] = useState("#000000");

  const fetchTheme = async () => {
    const theme = await getAccentColor(chainId);

    setAccentColor(theme);
  };

  useEffect(() => {
    fetchTheme();
  }, [chainId]);

  const toggleTransactionDetail = () => {
    setShowTransactionDetail(!showTransactionDetail);
  };

  return (
    <div
      className="flex items-center gap-2 mt-2 hover:bg-card-bg p-2 rounded-lg cursor-pointer"
      onClick={toggleTransactionDetail}
    >
      <div className="relative">
        {transaction.label === "Received" ? (
          <img
            src={arrow}
            className="h-9 p-1 rounded-full rotate-180"
            style={{ background: accentColor }}
            alt="arrow"
          />
        ) : (
          <img
            src={arrow}
            className="h-9 p-1 rounded-full "
            style={{ background: accentColor }}
            alt="arrow"
          />
        )}
        <img
          src={transaction.status === "1" ? confirmed : rejected}
          className="absolute bottom-[-3px] right-[-3px] h-4 w-4"
          alt="confirmed"
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between ">
          <p className="font-semibold capitalize flex align-center">
            {transaction.label}{" "}
            {transaction.isGasless && (
              <span className=" text-green-800 text-xs bg-green-200 border-green-800 rounded-full  px-2 py-0.5 ml-2 mt-1 mb-auto">
                Gasless
              </span>
            )}
          </p>
          <p>
            {transaction.label === "Received"
              ? Number(
                  ethers.utils.formatEther(transaction.amount.toString()),
                ).toFixed(5)
              : Number(transaction.amount).toFixed(5)}
          </p>
        </div>
      </div>

      {showTransactionDetail && (
        <div className="absolute top-0 left-0 h-full w-full p-2 bg-primary-bg bg-opacity-90 z-50">
          <TransactionHistoryModal
            txnDetails={transaction}
            onClose={toggleTransactionDetail}
          />
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
