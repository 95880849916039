import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { GasStateType } from "../constants/Types";

const { persistAtom } = recoilPersist();

const gasState = atom<GasStateType[] | null>({
  key: "GasState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export default gasState;
