import { motion, AnimatePresence } from "framer-motion";
import dappConnection from "../assets/illustrations/dapp-connection.png";
import gasless from "../assets/illustrations/gasless.png";
import smartWallet from "../assets/illustrations/smart-wallets.png";
import stateOfArt from "../assets/illustrations/state-of-art.png";
import multiChain from "../assets/illustrations/multiChain.png";

const PeekingIllustrations = ({ toggleImages }: { toggleImages: boolean }) => (
  <div className="absolute top-0 left-0  flex justify-center items-center text-white h-full w-full">
    <div className="h-full w-full relative overflow-hidden">
      <AnimatePresence>
        {toggleImages && (
          <>
            <motion.div
              className="absolute bottom-[-1.4em] left-[-1.6em]"
              initial={{ translateY: 100, translateX: -100, scale: 0.8 }}
              animate={{
                translateY: 0,
                translateX: 0,
                scale: 1,
                transition: { duration: 0.4 },
              }}
              exit={{ translateY: 100, translateX: -100, scale: 0.8 }}
            >
              <img
                src={dappConnection}
                alt="dapp-connection"
                className=" rotate-[20deg] h-[5rem] w-[5rem] select-none"
              />
            </motion.div>

            <motion.div
              className="absolute top-[37%] left-[10%] select-none"
              initial={{ translateY: -20, translateX: -100, scale: 0.8 }}
              animate={{
                translateY: 0,
                translateX: 0,
                scale: 1,
                transition: { duration: 0.4 },
              }}
              exit={{ translateY: -20, translateX: -100, scale: 0.8 }}
            >
              <img
                src={gasless}
                alt="dapp-connection"
                className=" h-12 w-12 rotate-[12deg] select-none"
              />
            </motion.div>

            <motion.div
              className="absolute top-[45%] right-[-2.6em] "
              initial={{ translateX: 60, scale: 0.9 }}
              animate={{
                translateX: 0,
                scale: 1,
                transition: { duration: 0.4 },
              }}
              exit={{ translateX: 60, scale: 0.9 }}
            >
              <img
                src={smartWallet}
                alt="dapp-connection"
                className="h-20 w-20 rotate-[-40deg] select-none"
              />
            </motion.div>

            <motion.div
              className="absolute top-[14%] right-[16%] select-none"
              initial={{ translateY: -40, translateX: 150, scale: 0.6 }}
              animate={{
                translateY: 0,
                translateX: 0,
                scale: 1,
                transition: { duration: 0.4 },
              }}
              exit={{ translateY: -40, translateX: 150, scale: 0.6 }}
            >
              <img
                src={stateOfArt}
                alt="dapp-connection"
                className=" h-[3em] w-[3em] select-none"
              />
            </motion.div>

            <motion.div
              className="absolute top-[1em] left-[-1.4em]"
              initial={{ translateY: -120, translateX: -20, scale: 0.7 }}
              animate={{
                translateY: 0,
                translateX: 0,
                scale: 1,
                transition: { duration: 0.4 },
              }}
              exit={{ translateY: -120, translateX: -20, scale: 0.7 }}
            >
              <img
                src={multiChain}
                alt="dapmultichainp"
                className=" rotate-[30deg] h-20 w-20 select-none"
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  </div>
);

export default PeekingIllustrations;
