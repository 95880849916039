import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import {
  AlertTriangle,
  Aperture,
  EyeOff,
  FileText,
  Unlock,
} from "react-feather";
import usePasswordVerification from "../../hooks/functional-hooks/usePasswordVerification";
import {
  generateMnemonicAndEoa,
  getActiveUserDevice,
  getUserSettingsData,
  log,
} from "../../utils/helper";
import PageTitle from "../../components/PageTitle";
import {
  DEVICE_AUTH_TYPE,
  PAGE_TITLE_BUTTON_TYPE,
} from "../../constants/Enums";
import { Button } from "../../components/ui/button";
import { validateBiometric } from "../../hooks/functional-hooks";
import { UserSettingsType } from "../../constants/Types";
import {
  // DeviceDefault,
  UserSettingsDefault,
} from "../../constants/TypesDefaults";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import VerifyPassword from "../../components/Modals/VerifyPassword";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

declare global {
  interface Window {
    verifyPasswordCallback?: {
      handleVerify: (password: string) => Promise<void>;
      handleClose: () => void;
    };
  }
}

const WarningPage = () => {
  const [activeDevice, setActiveDevice] =
    useState<UserSettingsType>(UserSettingsDefault);
  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE>(
    DEVICE_AUTH_TYPE.PASSWORD,
  );
  const { smartAccountAddress, eoaAddress } = useWalletConfig();
  // const devices = getDevicesFromStorage();
  const navigate = useNavigate();
  const biometricAuth = validateBiometric();

  const location = useLocation();
  const fromRoute = location.state?.from;

  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();

  const generateMnemonicAndNavigate = () => {
    const { mnemonic } = generateMnemonicAndEoa(activeDevice);

    navigate("/settings/recoveryphrase-page", { state: mnemonic });
  };

  const verifyAndNavigate = async () => {
    try {
      let isValid = false;

      if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
        isValid = await biometricAuth(activeDevice.id);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
        isValid = await biometricAuth(activeDevice.id);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
        isValid = await verifyPassword();
      } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
        isValid = true;
      }

      if (!isValid) {
        toast.error("Authentication Failed", { id: "auth-failed" });
      } else {
        generateMnemonicAndNavigate();
      }
    } catch (e) {
      toast.error("Can't export mnemonic, try again later.");
    }
  };

  async function setUserDevices() {
    setActiveDevice(await getActiveUserDevice(eoaAddress));
  }

  useEffect(() => {
    setUserDevices();
  }, [smartAccountAddress, eoaAddress]);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { authenticationType } =
            usersSettings[userIndex].settings.security;

          setWalletAuthType(authenticationType);
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, []);

  return (
    <PageTransitionWrapper>
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        leftDestinationRoute={!fromRoute ? "/setting/edit-account" : "/"}
        title=""
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        backRouteState={!fromRoute}
      />

      <div className="flex flex-col h-full items-center gap-5 py-4">
        <div className="flex flex-col gap-3 justify-center items-center">
          <div className="w-fit bg-red-500 rounded-full p-4">
            <AlertTriangle size={25} />
          </div>
          <p>Before you proceed</p>
        </div>

        <div className="w-full flex flex-col gap-4 mt-5 px-4">
          <div className="flex gap-5 justify-center items-center">
            <AlertTriangle size={25} className="text-red-500" />
            <p className="text-sm w-[90%]">
              Never Share your Secret Recovery Phrase or enter it into any apps.
            </p>
          </div>
          <div className="flex gap-5 justify-center items-center">
            <EyeOff size={22} className="text-purple-500" />
            <p className="text-sm w-[90%]">
              Make sure nobody can view your screen when viewing your Secret
              Recovery Phrase.
            </p>
          </div>
          <div className="flex gap-5 justify-center items-center">
            <Unlock size={25} className="text-yellow-300" />
            <p className="text-sm w-[90%]">
              Anyone with your Secret Recovery Phrase can access your entire
              wallet.
            </p>
          </div>
          <div className="flex gap-5 justify-center items-center">
            <Aperture size={25} className="text-blue-500" />
            <p className="text-sm w-[90%]">
              WalletX team will never ask you for your Secret Recovery Phrase.
            </p>
          </div>
        </div>
      </div>

      <Button
        variant={"tsxButton"}
        className="flex gap-2 bottom-4 absolute left-1/2 translate-x-[-50%]"
        size={"tsx"}
        onClick={() => verifyAndNavigate()}
      >
        <FileText />
        <span>Show Recovery Phrase</span>
      </Button>
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </PageTransitionWrapper>
  );
};

export default WarningPage;
