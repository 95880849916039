import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { X } from "react-feather";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import { INPUT_VALUE } from "../../constants/Enums";
import {
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../utils/helper";
import {
  AddressInAddressBookType,
  AddAddressModalType,
  UserSettingsType,
} from "../../constants/Types";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const AddAddressModal = ({ isOpen, onClose }: AddAddressModalType) => {
  const isModalOpen = isOpen;
  const [enteredAddress, setEnteredAddress] = useState<string>("");
  const [enteredName, setEnteredName] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const { eoaAddress } = useWalletConfig();

  const isValidAddresses = (address: any) => {
    if (ethers.utils.isAddress(address)) {
      return true;
    }

    return false;
  };

  const handleInputChange = (type: INPUT_VALUE, e: any) => {
    const inputValue = e.target.value;

    if (type === INPUT_VALUE.ADDRESS) {
      setEnteredAddress(inputValue);
    } else if (type === INPUT_VALUE.NAME) {
      setEnteredName(inputValue);
    }
  };

  const updateAddressBook = async (
    _accountDetails: AddressInAddressBookType,
    _eoaAddress: string,
  ) => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: any) => user.address === _eoaAddress,
      );

      if (userIndex !== -1) {
        // TODO delete it before pushing to production and show some error User not found, create a new entry
        // User found, update the address book
        const userAddressBook = usersSettings[userIndex].settings.addressBook;

        // Check if any address in the address book already has the same name
        const existingName = userAddressBook.find(
          (entry: AddressInAddressBookType) =>
            entry.name === _accountDetails.name,
        );

        if (existingName) {
          toast.error(
            `Name "${_accountDetails.name}" is already used for another address.`,
          );
          return;
        }

        // Check if the address is already in the address book
        const existingAddress = userAddressBook.find(
          (entry: AddressInAddressBookType) =>
            entry.address === _accountDetails.address,
        );

        if (existingAddress) {
          toast.error(
            `Address is already added with the name "${existingAddress.name}".`,
          );
          return;
        }

        // Add the new entry to the address book
        userAddressBook.push(_accountDetails);
      }

      await setUserSettingsData(usersSettings);

      toast.success("Address is updated in the address book");
    } catch (error) {
      log("Error updating AddressBook:", error, "error");
    }
  };

  const handleSaveBtn = () => {
    const address = enteredAddress;
    const name = enteredName;
    const accountDetails = { address, name };

    updateAddressBook(accountDetails, eoaAddress);
    onClose();
  };

  useEffect(() => {
    if (isValidAddresses(enteredAddress)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [enteredAddress]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed top-0 w-full h-full  bg-primary-bg bg-opacity-50 z-50 flex justify-center items-center">
          <div className=" flex flex-col gap-3 p-4 w-[90%]  justify-center items-center  rounded-xl shadow-lg bg-secondary-bg ">
            <div className="relative w-full text-center m-auto">
              <span className=" text-md font-bold text-neutral-200 ">
                Add Address
              </span>
              <button
                className="absolute right-0 hover:opacity-70 "
                onClick={() => {
                  onClose();
                }}
              >
                <X />
              </button>
            </div>

            <div className=" w-full flex flex-col gap-2 mb-2 ">
              <label className="w-full flex  flex-col text-md">
                Name:
                <input
                  onChange={(e) => {
                    handleInputChange(INPUT_VALUE.NAME, e);
                  }}
                  className="w-full rounded-md  text-sm px-2 py-2 focus:outline-none bg-transparent border border-neutral-500"
                  type="text"
                  placeholder="Satoshi"
                  maxLength={15}
                />
              </label>
              <label className="w-full flex  flex-col">
                Address:
                <input
                  onChange={(e) => {
                    handleInputChange(INPUT_VALUE.ADDRESS, e);
                  }}
                  className="w-full rounded-md text-sm  px-2 py-2 focus:outline-none bg-transparent border border-neutral-500"
                  type="text"
                  placeholder=" Enter address"
                />
              </label>
            </div>

            <Button
              onClick={() => {
                handleSaveBtn();
              }}
              disabled={!isValid}
              className={`group w-full transition-transform  ${
                enteredAddress === ""
                  ? "bg-card-bg2  hover:bg-card-bg2 "
                  : ` ${
                      isValid ? "bg-card-bg2  hover:bg-card-bg2 " : "bg-red-500"
                    }
                  `
              }  `}
            >
              {enteredAddress === "" ? (
                <>
                  <span>Save</span>
                </>
              ) : (
                <>
                  {isValid ? (
                    <>
                      <span className="group-hover:scale-110">Save</span>
                    </>
                  ) : (
                    <>
                      <span>Please enter a valid address</span>
                    </>
                  )}
                </>
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAddressModal;
