import { useState, useEffect } from "react";
import { Copy, Edit2, ExternalLink, Maximize } from "react-feather";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../../utils/helper";
import RenameSmartAddress from "./RenameSmartAddress";

type ManageSmartAddressProps = {
  isOpen: boolean;
  onClose: () => void;
  address: string;
  name: string;
  explorerUrl: string;
  modalRef: React.RefObject<HTMLDivElement>;
  isEoa?: boolean;
};

type ManageOption = {
  icon: React.ElementType;
  text: string;
  onClick: (e: any) => void;
};

const ManageSmartAddress = ({
  isOpen,
  onClose,
  address,
  explorerUrl,
  name,
  modalRef,
  isEoa,
}: ManageSmartAddressProps) => {
  const navigate = useNavigate();

  const [isRenameSmartAddressModalOpen, setIsRenameSmartAddressModalOpen] =
    useState<boolean>(false);

  const openRenameSmartAddressModal = () => {
    setIsRenameSmartAddressModalOpen(true);
  };

  const manageOptions: ManageOption[] = [
    {
      icon: Edit2,
      text: "Rename Address",
      onClick: (e) => {
        e.stopPropagation();
        openRenameSmartAddressModal();
      },
    },
    {
      icon: Maximize,
      text: "View QR Code",
      onClick: () =>
        navigate("/dashboard/receive", {
          state: { address, from: "/dashboard/select-an-address" },
        }),
    },
    {
      icon: Copy,
      text: "Copy Address",
      onClick: (e) => {
        e.stopPropagation();
        copyToClipboard(address);
      },
    },
  ];

  useEffect(() => {
    const closeModalOnOutsideClick = (e: any) => {
      if (!(modalRef.current as any).contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", closeModalOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeModalOnOutsideClick);
    };
  }, [onClose, modalRef]);

  return (
    <div className="relative bg-card-bg2" ref={modalRef}>
      <div
        className={` ${
          isOpen ? "" : "hidden"
        } absolute top-[-1.4em] right-8 flex flex-col text-sm  p-2  justify-center items-start gap-1  rounded-xl shadow-xl bg-card-bg border-2 border-black z-50 `}
      >
        {manageOptions.map(({ icon: Icon, text, onClick }, index) => {
          if (isEoa && text === "Rename Address") return <></>;

          return (
            <button
              key={index}
              className="group flex w-full justify-start items-center gap-2 hover:bg-card-bg2 px-3 py-2 rounded-lg"
              onClick={onClick}
            >
              <Icon size={16} className="group-hover:scale-110" />
              <span>{text}</span>
            </button>
          );
        })}
        <hr className="border border-neutral-500 my-1 w-[95%] mx-auto" />
        <a
          href={explorerUrl}
          className="w-full"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="group flex w-full justify-start items-center gap-2 hover:bg-card-bg2 px-3 py-2 rounded-lg">
            <ExternalLink size={16} className="group-hover:scale-110" />
            <span>Explorer</span>
          </button>
        </a>
      </div>

      <RenameSmartAddress
        isOpen={isRenameSmartAddressModalOpen}
        onClose={() => {
          onClose();
          setIsRenameSmartAddressModalOpen(false);
        }}
        address={address}
        name={name}
      />
    </div>
  );
};

export default ManageSmartAddress;
