import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";
import PageTitle from "../../../components/PageTitle";

import WalletConnections from "../../../components/DappConnections/WalletConnections";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";

const WCConnections = () => (
  <PageTransitionWrapper>
    <div className="relative h-full ">
      <PageTitle
        title="Connected DApps"
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        leftDestinationRoute="/dashboard/settings"
        backRouteState={true}
      />

      <WalletConnections />
    </div>
  </PageTransitionWrapper>
);

export default WCConnections;
