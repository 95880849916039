import { useState } from "react";
import TokenForAssetSelection from "./TabContent/TokenForAssetSelection";
import NftsForAssetSelection from "./TabContent/NftsForAssetSelection";
import { TAB_LIST } from "../../../constants/Enums";
import { AssetSelectionType } from "../../../constants/Types";

const SelectAssets = ({
  transactionUID,
  assetUID,
  onClose,
  searchValue,
}: AssetSelectionType) => {
  // TODO: uncomment it in later release
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useState(TAB_LIST.TOKEN);

  // TODO: This tab needs to be added later so not deleting the code.
  // const handleTabClick = (tab: any) => {
  //   setActiveTab(tab);
  // };

  return (
    <div className="flex flex-col items-center ">
      <div className="flex space-x-4 w-full justify-between items-center px-8 gap-6 text-md tracking-wide ">
        {/* <div
          className={`cursor-pointer text-md truncate px-4 py-2 w-full text-center ${
            activeTab === TAB_LIST.TOKEN
              ? "text-blue-500 border-b-2 border-blue-500"
              : ""
          }`}
          onClick={() => handleTabClick(TAB_LIST.TOKEN)}
        >
          Tokens
        </div> */}
        {/* <div
          className={`cursor-pointer px-4 py-2 w-full text-center ${
            activeTab === TAB_LIST.NFT
              ? "text-blue-500 border-b-2 border-blue-500"
              : ""
          }`}
          onClick={() => handleTabClick(TAB_LIST.NFT)}
        >
          NFTs
        </div> */}
      </div>
      <div className="w-full">
        {activeTab === TAB_LIST.TOKEN && (
          <TokenForAssetSelection
            onClose={onClose}
            transactionUID={transactionUID}
            assetUID={assetUID}
            searchValue={searchValue}
          />
        )}
        {activeTab === TAB_LIST.NFT && (
          <NftsForAssetSelection
            onClose={onClose}
            transactionUID={transactionUID}
            assetUID={assetUID}
            searchValue={searchValue}
          />
        )}
      </div>
    </div>
  );
};

export default SelectAssets;
