/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */

// import WalletProxi from "./WalletProxiInit";
import Store from "../components/StoreComponent";
import { log } from "../helpers";

class EthProvider {
  handleFunc = false;

  rejectFunc = false;

  navigateFunc = null;

  init(navigation) {
    this.navigateFunc = navigation;

    // AppController.start('connect_controller');

    // const tempState = Store.getTempState();
    const state = Store.getState();
    const ref = this;

    // window.addEventListener('message', ( ev ) => setTimeout( function( ev ){
    if (!chrome.runtime || !chrome.runtime.onMessage) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    chrome.runtime.onMessage.addListener((val, sender, response) => {
      log("In EthProvider:chrome.runtime.onMessage.addListener() ", {
        val,
        sender,
        response,
        line: 34,
      });
      setTimeout(
        (ev) => {
          log("message from bg (eth-provider)", { ev, state });
          if (ev.data.type === "FROM_BG") {
            log(
              "In EthProvider:chrome.runtime.onMessage.addListener():setTimeout():if{} ",
              {
                ev,
                state,
                line: 44,
              },
            );
            if (false) {
              ref.respond(ev.data.id, {
                error: {
                  message: "Unauthorized",
                  code: 4100,
                  data: "The requested method and/or account has not been authorized by the user.",
                },
                locked: true,
              });
            } else {
              Store.clearTempState();
              if (
                [
                  "eth_chainId",
                  "net_version",
                  "eth_getBlockByNumber",
                  "eth_accounts",
                ].indexOf(ev.data.payload.method) !== -1
              ) {
                Store.saveTempState({
                  shouldNotRedirect: true,
                  method: ev.data.payload.method,
                });
              } else if (
                [
                  "eth_sign",
                  "personal_sign",
                  // "eth_signTypedData_v4", // TODO: uncomment it later
                  "eth_sendTransaction",
                  "wallet_switchEthereumChain",
                  "wallet_addEthereumChain",
                ].indexOf(ev.data.payload.method) === -1 &&
                state.connect_title &&
                state.connect_title === ev.data?.from?.title &&
                state.connect_origin === ev.data?.from?.origin
              ) {
                ref.handle_request(ev.data.id, ev.data.payload, ev.data.from);
              } else if (
                ev.data.payload.method === "eth_sign" ||
                // ev.data.payload.method === "eth_signTypedData_v4" || // TODO: uncomment it later
                ev.data.payload.method === "personal_sign"
              ) {
                log(
                  "In EthProvider:chrome.runtime.onMessage.addListener():setTimeout():if{eth_sign || personal_sign || eth_signTypedData_v4} ",
                  {
                    ev,
                    state,
                    line: 111,
                  },
                );
                if (ev.data.payload.method === "personal_sign") {
                  Store.saveTempState({
                    address: ev.data.payload.params[1],
                    message: ev.data.payload.params[0],
                    method: ev.data.payload.method,
                    connect_title: ev.data?.from?.title,
                    connect_origin: ev.data?.from?.origin,
                  });
                } else {
                  Store.saveTempState({
                    address: ev.data.payload.params[0],
                    message: ev.data.payload.params[1],
                    method: ev.data.payload.method,
                    connect_title: ev.data?.from?.title,
                    connect_origin: ev.data?.from?.origin,
                  });
                }

                this.navigateFunc("/dashboard/dapp/signature");

                ref.onInteraction(
                  (resp) => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, resp);
                  },
                  (reason) => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, {
                      error: {
                        message: "User Rejected Request",
                        code: 4001,
                        data: reason || "The user rejected the request.",
                      },
                    });
                  },
                  () => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, { message: "closing" });
                  },
                );
              } else if (ev.data.payload.method === "eth_sendTransaction") {
                Store.saveTempState({
                  transactionData: ev.data.payload.params[0],
                  useExternalFee: true,
                });
                // AppController.start("send_transaction_external");
                this.navigateFunc("/dashboard/dapp/transaction");

                ref.onInteraction(
                  (resp) => {
                    Store.clearTempState();
                    ref.respond(
                      ev.data.id,
                      { ...resp.receipt, payload: resp.receipt },
                      "sendTransaction",
                      true,
                    );
                    // ref.respond(ev.data.id, resp.receipt.transactionHash);
                    // ref.respond(ev.data.id, resp.transactionHash);
                  },
                  (reason) => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, {
                      error: {
                        message: "User Rejected Request",
                        code: 4001,
                        data: reason || "The user rejected the request.",
                      },
                    });
                  },
                  () => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, { message: "closing" });
                  },
                );
              } else if (
                ev.data.payload.method === "wallet_switchEthereumChain"
              ) {
                const chainId = ev.data.payload?.params[0]?.chainId;

                if (chainId) {
                  Store.saveTempState({
                    connect_title: ev.data?.from?.title,
                    connect_origin: ev.data?.from?.origin,
                    requestedChainId: chainId,
                    params: ev.data.payload?.params,
                  });
                } else {
                  Store.saveTempState({
                    connect_title: ev.data?.from?.title,
                    connect_origin: ev.data?.from?.origin,
                    requestedChainId: null,
                    params: ev.data.payload?.params,
                  });
                }

                // AppController.start("send_transaction_external");
                this.navigateFunc("/dashboard/dapp/switch-network");

                ref.onInteraction(
                  (resp) => {
                    Store.clearTempState();
                    ref.respond(
                      ev.data.id,
                      {
                        ...resp,
                        payload: {
                          param: resp.chainId,
                          authoritative: true,
                        },
                      },
                      "chainChanged",
                      true,
                    );
                  },
                  (reason) => {
                    Store.clearTempState();
                    Store.saveTempState({
                      connect_title: false,
                      connect_origin: false,
                    });
                    ref.respond(ev.data.id, {
                      error: {
                        message: "User Rejected Request",
                        code: 4001,
                        data: reason || "The user rejected the request.",
                      },
                    });
                  },
                  () => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, { message: "closing" });
                  },
                );
              } else if (ev.data.payload.method === "wallet_addEthereumChain") {
                const chainId = ev.data.payload?.params[0]?.chainId;

                if (chainId) {
                  Store.saveTempState({
                    connect_title: ev.data?.from?.title,
                    connect_origin: ev.data?.from?.origin,
                    requestedChainId: chainId,
                  });
                } else {
                  Store.saveTempState({
                    connect_title: ev.data?.from?.title,
                    connect_origin: ev.data?.from?.origin,
                    requestedChainId: null,
                  });
                }

                ref.onInteraction(
                  () => {
                    Store.clearTempState();
                    ref.handle_request(
                      ev.data.id,
                      ev.data.payload,
                      ev.data.from,
                    );
                  },
                  () => {
                    Store.clearTempState();
                    Store.saveTempState({
                      connect_title: false,
                      connect_origin: false,
                    });

                    ref.respond(ev.data.id, {
                      error: {
                        message: "User Rejected Request",
                        code: 4001,
                        data: "The user rejected the request.",
                      },
                    });
                  },
                  () => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, { message: "closing" });
                  },
                );
              } else {
                Store.saveTempState({
                  connect_title: ev.data?.from?.title,
                  connect_origin: ev.data?.from?.origin,
                });
                this.navigateFunc("dashboard/dapp/connect");

                ref.onInteraction(
                  (connectionData) => {
                    Store.clearTempState();
                    Store.saveState({
                      ...connectionData,
                      connect_title: ev.data?.from?.title,
                      connect_origin: ev.data?.from?.origin,
                    });
                    ref.handle_request(
                      ev.data.id,
                      ev.data.payload,
                      ev.data.from,
                      connectionData,
                    );
                  },
                  (reason) => {
                    Store.clearTempState();
                    Store.saveTempState({
                      connect_title: false,
                      connect_origin: false,
                    });

                    ref.respond(ev.data.id, {
                      error: {
                        message: "User Rejected Request",
                        code: 4001,
                        data: reason || "The user rejected the request.",
                      },
                    });
                  },
                  () => {
                    Store.clearTempState();
                    ref.respond(ev.data.id, { message: "closing" });
                  },
                );
              }
            }
          }
        },
        0,
        { data: val },
      );
    });
  }

  async handle_request(id, data, from, connectionData) {
    log("In EthProvider:handle_request() ", {
      id,
      data,
      from,
      connectionData,
      line: 333,
    });
    // window.WalletProxi = WalletProxi;

    // VARIABLES:
    let chains;
    let chainID;
    let chainRequested;

    switch (data.method) {
      case "eth_accounts":
        if (false) {
          // if (await WalletProxi.isEmptyVault()) {
          this.respond(id, {
            error: {
              message: "User Rejected Request",
              code: 4001,
              data: "The user rejected the request.",
            },
          });
        } else {
          if (!connectionData) {
            connectionData = Store.getState();
          }

          const { address, chainId, isConnected, balance, chainDetails } =
            connectionData;
          // const balance = connectionData.balance; // wait WalletProxi.getWalletBalance(w?.address, true);

          const acc = [address];
          const dataObj = {
            addresses: acc,
            // chainId: process.env.NETWORK === 'ropsten' ? '0x03' : '0x1',
            chainId,
            node_uri: chainDetails.rpc,
            balance,
            payload: acc,
            block: "", // await WalletProxi.getLatesBlock(),
            origin: from.origin,
            method: data.method,
            isConnected,
          };

          // setTimeout(() => {
          this.respond(id, dataObj, null, true);
          // }, 10000);
        }

        break;

      case "eth_requestAccounts":
        if (false) {
          // if (await WalletProxi.isEmptyVault()) {
          this.respond(id, {
            error: {
              message: "User Rejected Request",
              code: 4001,
              data: "The user rejected the request.",
            },
          });
        } else {
          if (!connectionData) {
            connectionData = Store.getState();
          }

          const { address, chainId, isConnected, balance, chainDetails } =
            connectionData;
          // const balance = connectionData.balance; // wait WalletProxi.getWalletBalance(w?.address, true);

          const acc = [address];
          const dataObj = {
            addresses: acc,
            // chainId: process.env.NETWORK === 'ropsten' ? '0x03' : '0x1',
            chainId,
            node_uri: chainDetails.rpc,
            balance,
            payload: acc,
            block: "", // await WalletProxi.getLatesBlock(),
            origin: from.origin,
            method: data.method,
            isConnected,
          };

          // setTimeout(() => {
          this.respond(id, dataObj, null, true);
          // }, 10000);
        }

        break;

      case "eth_getBalance":
        // await WalletProxi.loadVault();
        // eslint-disable-next-line no-case-declarations
        // const aW = ""; // WalletProxi.getActiveWallet();
        // eslint-disable-next-line no-case-declarations
        // const { address } = aW;
        // eslint-disable-next-line no-case-declarations
        const balance = ""; // await WalletProxi.getWalletBalance(address, true);

        this.respond(id, balance);
        break;

      // case "eth_sendTransaction":
      //   this.respond(id, data);
      //   break;

      case "wallet_addEthereumChain":
        chains = ""; // await WalletProxi.getChains();
        chainID = chains.reduce((acc, el, idx) => {
          if (
            parseInt(el.chainId, 10) === parseInt(data.params[0].chainId, 10)
          ) {
            acc = idx;
          }

          return acc;
        }, null);

        chainRequested = chains[chainID];
        if (chainID !== null) {
          // WalletProxi.setActiveChain(chainID, false, false);
          const activeNodeUri = ""; // await WalletProxi.getNodeURI();
          const activeWallet = ""; // WalletProxi.getActiveWallet();
          const walletBalance = ""; // await WalletProxi.getWalletBalance(
          // activeWallet.address,
          // );

          setTimeout(() => {
            this.respond(id, {
              method: "wallet_addEthereumChain",
              chainId: chainRequested.chainId,
              node_uri: activeNodeUri,
              addresses: [activeWallet.address],
              balance: walletBalance,
            });
          }, 100);
        } else if (!data.params[0].chainName) {
          this.respond(id, {
            error: {
              message: "Chain name cannot be empty",
              code: 4200,
              data: "Invalid chain data added.",
            },
          });
        } else if (!data.params[0].rpcUrls || !data.params[0].rpcUrls[0]) {
          this.respond(id, {
            error: {
              message: "Chain REC url cannot be empty",
              code: 4200,
              data: "Invalid chain RPC url added.",
            },
          });
        } else {
          // const RPC =
          //   typeof data.params[0].rpcUrls === "string"
          //     ? data.params[0].rpcUrls
          //     : data.params[0].rpcUrls[0];

          // await WalletProxi.addChain(
          //   data.params[0].chainName,
          //   data.params[0].chainName,
          //   data.params[0].chainId,
          //   RPC,
          // );

          // WalletProxi.setActiveChain(chainID, false, false);
          const activeNodeUri = ""; // await WalletProxi.getNodeURI();
          const activeWallet = ""; // WalletProxi.getActiveWallet();
          const walletBalance = "";
          // await WalletProxi.getWalletBalance(
          //   activeWallet.address,
          // );

          setTimeout(() => {
            this.respond(id, {
              method: "wallet_addEthereumChain",
              chainId: data.params[0].chainId,
              node_uri: activeNodeUri,
              addresses: [activeWallet.address],
              balance: walletBalance,
            });
          }, 100);
        }

        break;

      default:
        this.respond(id, {
          error: {
            message: "Unsupported Method",
            code: 4200,
            data: "The Provider does not support the requested method.",
          },
        });
        break;
    }
  }

  clearStorageState() {
    Store.saveToInitialState();
  }

  respond(evId, data) {
    // eslint-disable-next-line prefer-rest-params
    const events = arguments[2] ? { event: arguments[2] } : {};
    // eslint-disable-next-line prefer-rest-params
    const authoritative = arguments[3] ? { authoritative: true } : {};

    log("In EthProvider:respond() ", {
      evId,
      data,
      events,
      authoritative,
      line: 571,
    });

    const storageState = Store.getState();

    if (data.method === "disconnect") {
      this.clearStorageState();
    }

    if (
      chrome &&
      chrome.hasOwnProperty("runtime") &&
      chrome.runtime.hasOwnProperty("sendMessage")
    ) {
      chrome.runtime.sendMessage({
        store: storageState,
        type: "FROM_POPUP",
        id: evId,
        payload: data,
        ...events,
        ...authoritative,
      });
    }
  }

  extensionResponse(data) {
    log("In EthProvider:extensionResponse() ", {
      data,
      line: 598,
    });

    let storageState = Store.getState();

    if (
      data.method === "changeAddress" &&
      data.newAddress &&
      storageState.address &&
      storageState.address?.toLowerCase() !== data.newAddress?.toLowerCase()
    ) {
      this.clearStorageState();
    }

    Store.clearTempState();
    storageState = Store.getState();

    if (
      chrome &&
      chrome.hasOwnProperty("runtime") &&
      chrome.runtime.hasOwnProperty("sendMessage")
    ) {
      chrome.runtime.sendMessage({
        store: storageState,
        type: "FROM_POPUP",
        id: 1,
        payload: data,
        isInternal: true,
      });
    }
  }

  extensionResponseForChangeAddress(data) {
    log("In EthProvider:extensionResponse() ", {
      data,
      line: 598,
    });

    let storageState = Store.getState();

    if (
      data.method === "changeAddress" &&
      data.newAddress &&
      storageState.address &&
      storageState.address?.toLowerCase() !== data.newAddress?.toLowerCase()
    ) {
      this.clearStorageState();

      Store.clearTempState();
      storageState = Store.getState();

      if (
        chrome &&
        chrome.hasOwnProperty("runtime") &&
        chrome.runtime.hasOwnProperty("sendMessage")
      ) {
        chrome.runtime.sendMessage({
          store: storageState,
          type: "FROM_POPUP",
          id: 1,
          payload: data,
          isInternal: true,
        });
      }
    }
  }

  onInteraction(fnAcc, fnRej, fnClose) {
    log("In EthProvider:onInteraction() ", {
      line: 622,
    });
    this.handleFunc = fnAcc;
    this.rejectFunc = fnRej;
    this.closeFunc = fnClose;
  }

  onAccept(params) {
    if (this.handleFunc) this.handleFunc(params);
  }

  onCancel(data) {
    if (this.rejectFunc) this.rejectFunc(data);
  }

  onClose() {
    if (this.closeFunc) this.closeFunc();
  }
}

const providerInstance = new EthProvider();

export default providerInstance;
