import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { AmountAndBalanceType } from "../constants/Types";

const { persistAtom } = recoilPersist();

const amountAndBalanceState = atom<AmountAndBalanceType[]>({
  key: "amountAndBalanceState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export default amountAndBalanceState;
