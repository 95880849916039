/* eslint-disable import/prefer-default-export */
import axios from "axios";
import supportedChains from "../constants/chains";
import tokens from "../constants/tokens";
import {
  AdvertiserDetails,
  SupportedChainType,
  Tokens,
  CompletedTxnAPIType,
  DashboardAdAPIType,
} from "../constants/Types";
import * as config from "../config/env";
import MixPanel from "../utils/MixPanel";
import { MIXPANEL_KEY } from "../constants/Enums";

export const getChains = async (): Promise<SupportedChainType[]> => {
  const data = await axios.get(`${config.BASE_URL}/chains.json`);

  return data?.data?.supportedChains || supportedChains;
};

export const getTokens = async (): Promise<Tokens> => {
  const data = await axios.get(`${config.BASE_URL}/tokens.json`);

  return data?.data || tokens;
};

export const getAdvertiserDetails = async (): Promise<AdvertiserDetails> => {
  const data = await axios.get(
    `${config.BASE_API_URL}/advertiser/xtension-ad`,
    { headers: { extension: config.BASE_API_XTENSION_HEADER } },
  );

  return data?.data;
};

export const saveCompletedTxn = async ({
  data,
  address,
}: {
  data: CompletedTxnAPIType;
  address: string;
}): Promise<void> => {
  try {
    await axios.post(
      `${config.BASE_API_URL}/user/save-tx-data`,
      {
        ...data,
      },
      { headers: { address } },
    );
  } catch (error) {
    MixPanel.track(MIXPANEL_KEY.Backend_Update_Failed, {
      error,
      data,
    });
  }
};

export const getDashboardAdData = async (): Promise<DashboardAdAPIType[]> => {
  let data;

  try {
    data = await axios.get(
      `${config.BASE_API_URL}/advertiser/partnership-banners`,
    );
  } catch (error) {
    MixPanel.track(MIXPANEL_KEY.Backend_Update_Failed, {
      error,
    });
  }

  return data?.data.banners || [];
};
