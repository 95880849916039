import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "./slices/walletSlice";
import assetsReducer from "./slices/assetsSlice";
import { NODE_ENV } from "../../config/env";
import { ENVIRONMENT } from "../../constants/Enums";

export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    assets: assetsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg.provider", "payload.provider"],
        // Ignore these paths in the state
        ignoredPaths: ["wallet.provider"],
      },
    }),
  devTools: NODE_ENV === ENVIRONMENT.DEVELOPMENT,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
