import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { checkIsNativeToken } from "../../utils/helper";
import selectArrow from "../../assets/angle-down.svg";
import { GasTokenType } from "../../constants/Types";
import IllustrationLoader from "../IllustrationLoader";

const GasToken = ({
  transactionInProcess,
  openGasSelectionDrawer,
  selectedTokenForGas,
  isERC20TokenGasSupported,
  nativeAssetGas,
}: GasTokenType) => {
  const customButton = () => (
    <button
      className="flex  item-center gap-1"
      disabled={transactionInProcess}
      onClick={() => openGasSelectionDrawer()}
    >
      <p className="font-sans text-sm  font-semibold mr-1 flex items-center h-6">
        {checkIsNativeToken(selectedTokenForGas.tokenAddress) ? (
          <>
            {nativeAssetGas === "loading" ? (
              <>
                <IllustrationLoader height="1.4em" />
              </>
            ) : (
              <>{`< ${Number(nativeAssetGas).toFixed(6)}`}</>
            )}
          </>
        ) : (
          <>{`~ ${selectedTokenForGas.tokenGasValue.toFixed(6)}`}</>
        )}
      </p>
      <img
        className="h-6"
        src={selectedTokenForGas.icon}
        alt="selected gas token icon"
      />
      <p className="font-sans text-sm font-bold h-6">
        {selectedTokenForGas.tokenSymbol}
      </p>
      {isERC20TokenGasSupported && (
        <img className="h-6" src={selectArrow} alt="selectArrow" />
      )}
    </button>
  );

  return (
    <div
      className={` ${"transition-opacity ease-in duration-[1000ms] opacity-100"}  flex w-[85%] items-center  justify-between text-white  text-base pb-2 `}
    >
      <h1 className="font-sans font-semibold ">Gas</h1>
      <div>
        {!isERC20TokenGasSupported ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>{customButton()}</TooltipTrigger>
              <TooltipContent className="bg-secondary-bg text-white border-black ">
                <p className="whitespace-nowrap">
                  Tokens for gas payment is not supported for this network
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          customButton()
        )}
      </div>
    </div>
  );
};

export default GasToken;
