import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import localforage from "localforage";
import Dashboard from "../pages/Dashboard";
import PrivateLayout from "../pages/Layout/PrivateLayout";
import AddAddresses from "../pages/Transaction/AddAddress";
import AddTokens from "../pages/Transaction/AddToken";
import ApproveTransaction from "../pages/Transaction/ApproveTransaction";
import Settings from "../pages/Settings";
import Bridge from "../pages/UpcomingFeatures/Bridge";
import Swap from "../pages/UpcomingFeatures/Swap";
import Receive from "../pages/Receive";
import { getUserSettingsData } from "../utils/helper";
import Collectibles from "../pages/Collectibles";
import Activity from "../pages/Activity";
import AccountAndKeys from "../pages/AccountAndKeys";
import WarningPage from "../pages/AccountAndKeys/WarningPage";
import RecoveryPhrase from "../pages/AccountAndKeys/RecoveryPhrase";

import SignatureRequestDapp from "../pages/Dapp/Signature";
import TransactionRequestDapp from "../pages/Dapp/Transaction";
import ConnectRequestDapp from "../pages/Dapp/Connect";
import SwitchNetworkDapp from "../pages/Dapp/SwitchNetwork";
import SignatureRequestWC from "../pages/WalletConnect/Signature";
import TransactionRequestWC from "../pages/WalletConnect/Transaction";
import ConnectRequestWC from "../pages/WalletConnect/Connect";
import SwitchNetworkWC from "../pages/WalletConnect/SwitchNetwork";
import AddressBook from "../pages/Settings/AddressBook";
import WCConnections from "../pages/Settings/WCConnections";
import UserSupport from "../pages/Settings/UserSupport";
import Security from "../pages/Settings/Security";
import About from "../pages/Settings/AboutWalletX";
import Password from "../pages/Password";
import UpdatePassword from "../pages/Password/UpdatePassword";
import AutoLock from "../pages/Settings/AutoLock";
import WhatsNew from "../pages/WhatsNew";
import WelcomePage from "../pages/OnboardingAndAuthentication/Landing/WelcomePage";
import DeveloperOptions from "../pages/Settings/DeveloperOptions";
import TransactionSetting from "../pages/Settings/TransactionSetting";
import { UserSettingsType } from "../constants/Types";
import EditAccount from "../pages/Settings/EditAccount";
import SelectAnAddress from "../pages/SelectAnAddress";

// TODO remove this
import TemporaryLoaderPage from "../pages/TemporaryLoaderPage";

export default function PrivateRoutes() {
  const navigate = useNavigate();

  const [userDevices, setUserDevices] = useState<UserSettingsType[] | null>(
    null,
  );

  async function getUserDevices() {
    const devices: UserSettingsType[] = await getUserSettingsData();

    setUserDevices(devices);
  }

  useEffect(() => {
    if (!userDevices) getUserDevices();

    if (userDevices && userDevices.length <= 0) {
      localforage.clear();
      localStorage.clear();
      navigate("/register");
    }
  }, [userDevices, userDevices?.length]);

  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route
          path="/register"
          element={
            <Navigate
              to="/welcome"
              state={{
                showWelcome: window?.history?.state?.usr?.showWelcome,
              }}
              replace
            />
          }
        />
        <Route
          path="/import-wallet/create-smart-wallet"
          element={
            <Navigate
              to="/welcome"
              state={{
                showWelcome: window?.history?.state?.usr?.showWelcome,
              }}
              replace
            />
          }
        />
        <Route path="/dashboard/collectibles" element={<Collectibles />} />
        <Route path="/dashboard/activity" element={<Activity />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        <Route path="/dashboard/receive" element={<Receive />} />
        <Route path="/dashboard/bridge" element={<Bridge />} />
        <Route path="/dashboard/swap" element={<Swap />} />
        <Route path="/setting/edit-account" element={<EditAccount />} />
        <Route
          path="/dashboard/select-an-address"
          element={<SelectAnAddress />}
        />

        <Route
          path="/dashboard/transaction/add-address"
          element={<AddAddresses />}
        />
        <Route
          path="/dashboard/transaction/add-tokens"
          element={<AddTokens />}
        />

        <Route
          path="/dashboard/transaction/approve-transactions"
          element={<ApproveTransaction />}
        />
        <Route path="/settings/address-book" element={<AddressBook />} />
        <Route path="/settings/connections" element={<WCConnections />} />
        <Route path="/settings/user-support" element={<UserSupport />} />
        <Route path="/settings/security" element={<Security />} />
        <Route path="/settings/security/add-password" element={<Password />} />
        <Route
          path="/settings/security/update-password"
          element={<UpdatePassword />}
        />
        <Route path="/settings/security/auto-lock" element={<AutoLock />} />
        <Route
          path="/setting/security/transaction-setting"
          element={<TransactionSetting />}
        />
        <Route path="/settings/about" element={<About />} />
        <Route path="/settings/accountandkeys" element={<AccountAndKeys />} />
        <Route
          path="/settings/developer-options"
          element={<DeveloperOptions />}
        />
        <Route
          path="/setting/edit-account/recovery/warning-page"
          element={<WarningPage />}
        />
        <Route
          path="/settings/recoveryphrase-page"
          element={<RecoveryPhrase />}
        />
        <Route path="/whats-new" element={<WhatsNew />} />
        <Route path="/loader" element={<TemporaryLoaderPage />} />

        {/* DAPP connection routes  */}
        <Route
          path="/dashboard/dapp/signature"
          element={<SignatureRequestDapp />}
        />
        <Route
          path="/dashboard/dapp/transaction"
          element={<TransactionRequestDapp />}
        />
        <Route
          path="/dashboard/dapp/connect"
          element={<ConnectRequestDapp />}
        />
        <Route
          path="/dashboard/dapp/switch-network"
          element={<SwitchNetworkDapp />}
        />

        {/* WalletConnect connection routes */}
        <Route
          path="/dashboard/wc/signature"
          element={<SignatureRequestWC />}
        />
        <Route
          path="/dashboard/wc/transaction"
          element={<TransactionRequestWC />}
        />
        <Route path="/dashboard/wc/connect" element={<ConnectRequestWC />} />
        <Route
          path="/dashboard/wc/switch-network"
          element={<SwitchNetworkWC />}
        />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
