import { Copy, FileText } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { useEffect } from "react";
import { MIXPANEL_KEY, PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";
import { Button } from "../../components/ui/button";
import PageTitle from "../../components/PageTitle";
import {
  copyToClipboard,
  getUserSettingsData,
  log,
  setUserSettingsData,
} from "../../utils/helper";
import MixPanel from "../../utils/MixPanel";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";

import { UserSettingsType } from "../../constants/Types";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const RecoveryPhrase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const mnemonic = locationState.split(" ");
  const { eoaAddress } = useWalletConfig();

  useEffect(() => {
    MixPanel.track(MIXPANEL_KEY.Export_Mnemonic_Successful);
  }, []);

  const createArraysOfMnemonic = () => {
    const array1: any = [];
    const array2: any = [];
    const array3: any = [];

    mnemonic.map((word: any, index: any) => {
      if (index % 3 === 0) {
        array1.push(word);
      } else if (index % 3 === 1) {
        array2.push(word);
      } else {
        array3.push(word);
      }

      return null;
    });

    return { arr1: array1, arr2: array2, arr3: array3 };
  };

  const handLeBackUpSeedPhrase = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: any) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        usersSettings[userIndex].settings.recoveryPhrase.seedPhraseBackedUp =
          true;
        await setUserSettingsData(usersSettings);

        navigate("/settings/security");
      } else {
        toast.error("User settings not found in IndexedDB.");
      }
    } catch (error) {
      log("Error updating recovery phrase backed up status:", error, "error");
      toast.error(
        "An error occurred while updating the recovery phrase backed up status.",
      );
    }
  };
  const generateMnemonicView = () => {
    const { arr1, arr2, arr3 } = createArraysOfMnemonic();

    return (
      <>
        <div className="flex gap-2 text-sm divide-x divide-opacity-30 divide-gray-200">
          <div className="ml-2">
            {arr1.map((word: string, index: any) => (
              <div key={index} className="flex gap-2 w-24 my-1 ">
                <span className="w-[20%] text-center text-neutral-500">
                  {1 + index * 3}
                </span>
                <span>{word}</span>
              </div>
            ))}
          </div>
          <div>
            {arr2.map((word: string, index: any) => (
              <div key={index} className="flex gap-2 w-24 ml-2 my-1">
                <span className="w-[20%] text-center text-neutral-500">
                  {2 + index * 3}
                </span>
                <span>{word}</span>
              </div>
            ))}
          </div>
          <div>
            {arr3.map((word: string, index: any) => (
              <div key={index} className="flex gap-2 w-24 ml-2 my-1">
                <span className="w-[20%] text-center text-neutral-500">
                  {3 + index * 3}
                </span>
                <span>{word}</span>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageTransitionWrapper>
        <PageTitle
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.X}
          leftDestinationRoute="/setting/edit-account/recovery/warning-page"
          title=""
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        />
        <div className="w-full mx-auto">
          <div className="flex flex-col gap-3 justify-center items-center w-[90%] mx-auto mt-5">
            <p className="text-sm font-semibold tracking-wide flex gap-2">
              <FileText size={20} />
              <span>Your Secret Recovery Phrase</span>
            </p>
            <p className="text-xs text-center">
              Anyone who has these words can access your entire wallet! You
              should never share them
            </p>
          </div>

          <div className="w-[95%] mx-auto flex gap-3 justify-center items-center py-4 px-2 bg-card-bg rounded-xl divide-x divide-neutral-500 divide-opacity-50 mt-10 ">
            {generateMnemonicView()}
          </div>

          <button
            onClick={() => {
              copyToClipboard(locationState, "Copied to clipboard");
            }}
            className="flex gap-2 text-sm mt-10 mx-auto justify-center items-center hover:scale-105 "
          >
            <Copy size={20} />
            <span>Copy To Clipboard</span>
          </button>
        </div>

        <Button
          variant={"tsxButton"}
          className="flex gap-2 bottom-4 absolute left-1/2 translate-x-[-50%] "
          size={"tsx"}
          onClick={handLeBackUpSeedPhrase}
        >
          <FileText />
          <span>I've saved these words</span>
        </Button>
      </PageTransitionWrapper>
    </>
  );
};

export default RecoveryPhrase;
