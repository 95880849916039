import { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import dappConnection from "../assets/illustrations/dapp-connection.png";
import gasless from "../assets/illustrations/gasless.png";
import multiChain from "../assets/illustrations/multiChain.png";
import smartWallet from "../assets/illustrations/smart-wallets.png";
import stateOfArt from "../assets/illustrations/state-of-art.png";
import {
  encryptData,
  getTime,
  getItemFromStorage,
  getUserSettingsData,
  hashFingerPrint,
  log,
  setUserSettingsData,
} from "../utils/helper";
import {
  DEVICE_AUTH_TYPE,
  MIXPANEL_KEY,
  STORAGE_KEYS,
  TIME,
  WALLET_TYPE,
} from "../constants/Enums";
import MixPanel from "../utils/MixPanel";
import useWallet from "../lib/store/hooks/useWallet";
import { UserSettingsType } from "../constants/Types";
import * as Config from "../config/env";

const CreateSmartWallet = () => {
  const fingerPrint = FingerprintJS.load();

  const navigate = useNavigate();
  const location = useLocation();
  const mnemonicPhrase = location.state.mnemonic;

  const { init, login, smartAccountAddress, eoaAddress, isConnected } =
    useWallet();

  const [defaultChainId] = useMemo(() => [Config.DEFAULT_NETWORK.CHAIN_ID], []);
  const [credId, setCredId] = useState<string | null>(null);

  const [buttonTitle, setButtonTitle] = useState("Continue with Smart Wallet");
  const [walletName, setWalletName] = useState<string | null>(null);

  const smartAccountAddressInStorage = getItemFromStorage(
    STORAGE_KEYS.SMART_ACCOUNT,
  );

  const [exciteImages, setExciteImages] = useState(false);

  const saveUsersSettings = async (_deviceName: string, _credID: string) => {
    const time = getTime(TIME.HOUR_24);

    const newUserSettings: UserSettingsType = {
      address: eoaAddress ?? "",
      walletType: WALLET_TYPE.IMPORTED,
      walletName: _deviceName,
      activeSmartAccountAddress: smartAccountAddress ?? "",
      id: _credID,
      mnemonic: encryptData(mnemonicPhrase, _credID),
      accounts: [
        {
          address: smartAccountAddress ?? "",
          name: "Account 1",
          position: 1,
        },
      ],
      settings: {
        preferences: {
          theme: "default",
          currency: null,
        },
        security: {
          autoLockTimer: time.minutes,
          autoLockTimerLabel: time.time,
          authenticationType: DEVICE_AUTH_TYPE.NONE,
          password: null,
          biometrics: null,
        },
        recoveryPhrase: {
          seedPhraseBackedUp: true,
        },
        transactionSettings: {
          connect: DEVICE_AUTH_TYPE.NONE,
          signMsg: DEVICE_AUTH_TYPE.NONE,
          approveTransaction: DEVICE_AUTH_TYPE.NONE,
        },
        addressBook: [],
        developerMode: {
          showTestnet: false,
          turnOffAds: false,
        },
      },
    };

    try {
      const existingSettings = await getUserSettingsData();

      if (!existingSettings || !existingSettings.length) {
        await setUserSettingsData([newUserSettings]);
        log("Users settings saved to IndexedDB");
      } else {
        await setUserSettingsData([...existingSettings, newUserSettings]);
        log("Users settings added to existing settings in IndexedDB");
      }
    } catch (error) {
      log("Error saving users settings to IndexedDB:", error);
    }
  };

  const generateFingerPrint = async () => {
    const fp = await fingerPrint;
    const result = await fp.get();

    return hashFingerPrint(result.visitorId);
  };

  const createNewWallet = async () => {
    try {
      if (!mnemonicPhrase) {
        toast.error("Invalid Mnemonic Phrase");
        return;
      }

      const fingerPrintID = await generateFingerPrint();
      const existingSettings = await getUserSettingsData();

      const uniqueId = `${fingerPrintID}-${existingSettings.length + 1}`;

      const name = `Wallet ${existingSettings.length + 1}`;

      setWalletName(name);
      setCredId(uniqueId);

      if (fingerPrintID) {
        setButtonTitle("Creating...");

        await init({
          walletName: name,
          credentialId: uniqueId,
          seedPhrase: mnemonicPhrase,
          chainId: defaultChainId,
        });

        MixPanel.track(MIXPANEL_KEY.Successful_Import, {
          name: walletName?.toString(),
        });
      }
    } catch (error) {
      log("Error creating new wallet:", error);
    }
  };

  useEffect(() => {
    async function initializeWallet() {
      if (smartAccountAddressInStorage && walletName && credId && eoaAddress) {
        setButtonTitle("Done");
        await saveUsersSettings(walletName, credId);

        login();

        navigate(`/welcome`, {
          state: {
            showWelcome: true,
          },
        });
      }
    }
    initializeWallet();
  }, [smartAccountAddressInStorage, eoaAddress, walletName, isConnected]);

  const imgVariants1 = {
    bounce: {
      y: [0, 10, -10, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse" as const,
      },
    },
  };

  const imgVariants2 = {
    bounce: {
      y: [0, -10, 10, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse" as const,
      },
    },
  };

  return (
    <div className="bg-primary-bg px-4 h-full ">
      <div className="flex justify-center gap-1 pt-12">
        <motion.img
          src={multiChain}
          alt="multiChain"
          className={`h-12 w-12 mt-4 ${exciteImages ? "" : "img-grey-scale"}`}
          variants={imgVariants1}
          animate={exciteImages ? "bounce" : ""}
        />

        <motion.img
          src={stateOfArt}
          alt="stateOfArt"
          className={`h-12 w-12 mb-4 ${exciteImages ? "" : "img-grey-scale"}`}
          variants={imgVariants2}
          animate={exciteImages ? "bounce" : ""}
        />
        <motion.img
          src={gasless}
          alt="gasless"
          className={`h-12 w-12 mt-4 ${exciteImages ? "" : "img-grey-scale"}`}
          variants={imgVariants1}
          animate={exciteImages ? "bounce" : ""}
        />
        <motion.img
          src={smartWallet}
          alt="smartWallet"
          className={`h-12 w-12 mb-4 ${exciteImages ? "" : "img-grey-scale"}`}
          variants={imgVariants2}
          animate={exciteImages ? "bounce" : ""}
        />
        <motion.img
          src={dappConnection}
          alt="dappConnection"
          className={`h-12 w-12 mt-4 ${exciteImages ? "" : "img-grey-scale"}`}
          variants={imgVariants1}
          animate={exciteImages ? "bounce" : ""}
        />
      </div>

      <div className="flex flex-col items-center mt-8">
        <h1 className="text-white text-lg font-semibold text-center">
          Account Imported Successfully!
        </h1>
        <p className="flex flex-col gap-3 text-white text-sm font-normal mt-4 px-2 text-justify">
          <span>
            Congratulations! You've imported your wallet using your seedphrase.
          </span>
          <span>
            WalletX has created your 'Smart Wallet' enabling magical features
            like gasless transactions.{" "}
          </span>
          <span>
            If you're from legacy wallets like Metamask, your address may
            differ, but your tokens are safe and accessible via Metamask.{" "}
            <a
              href="https://getwalletx.medium.com/explained-i-just-imported-my-metamask-wallet-to-walletx-but-i-cannot-see-my-crypto-bf981f43d66d"
              target="_blank"
              className="text-blue-500"
            >
              Learn More
            </a>
            .
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-2 absolute w-full bottom-0 left-0 p-4">
        <motion.button
          onHoverStart={() => {
            setExciteImages(true);
          }}
          onHoverEnd={() => {
            setExciteImages(false);
          }}
          onClick={() => createNewWallet()}
          className="p-3 rounded-full text-sm bg-white hover:bg-opacity-80 text-black"
        >
          {buttonTitle}
        </motion.button>
        <button className="p-3 rounded-full hover:bg-zinc-800">Go Back</button>
      </div>
    </div>
  );
};

export default CreateSmartWallet;
