import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import downArrow from "../assets/angle-down.svg";
import SingleNftModal from "./SingleNftModal";
import { CollectionType, NFT } from "../constants/Types";
import { NftDefault } from "../constants/TypesDefaults";

const Collection = ({
  name,
  nftsData,
  canOpenNFTDetailsPage,
  onNFTClick,
}: CollectionType) => {
  const [showDropbox, setShowDropbox] = useState(true);
  const [showSingleNftModal, setShowSingleNftModal] = useState(false);
  const [singleNftModalData, setSingleNftModal] = useState<NFT>(NftDefault);

  const toggleDropbox = () => {
    setShowDropbox(!showDropbox);
  };

  const toggleShowSingleNftModal = () => {
    setShowSingleNftModal(!showSingleNftModal);
  };

  const showNftData = (nftData: NFT) => {
    toggleShowSingleNftModal();
    setSingleNftModal(nftData);
  };

  return (
    <div className=" p-2 bg-card-bg rounded-lg">
      <motion.div>
        <div>
          <div className="flex items-center justify-between gap-2 p-2 px-4 rounded-lg">
            <p className="text-m max-w-[300px] text-wrap w-full">
              {name} ({nftsData && nftsData.length})
            </p>

            <motion.img
              animate={{
                rotate: showDropbox ? -180 : 0,
              }}
              transition={{ duration: 0.3 }}
              src={downArrow}
              className="h-6"
              onClick={toggleDropbox}
            />
          </div>

          <AnimatePresence>
            {showDropbox && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className="grid grid-cols-3 justify-items-center gap-3 pb-4 pt-2 px-7 overflow-hidden"
              >
                {nftsData &&
                  nftsData
                    .map((nft: NFT, index: number) => (
                      <div key={index} className="relative group">
                        <motion.img
                          layoutId={`nft-image-${nft.id}`}
                          src={nft.imageUrl}
                          className="h-20 w-20 bg-primary-bg overflow-hidden aspect-square object-cover object-center border border-card-bg2 rounded"
                        />
                        <div
                          onClick={() => {
                            if (canOpenNFTDetailsPage === true) {
                              showNftData({
                                collectionName: nft.collectionName,
                                imageUrl: nft.imageUrl,
                                id: nft.id,
                                address: nft.address,
                                name: nft.name,
                                description: nft.description,
                              });
                            } else {
                              onNFTClick(nft.id);
                            }
                          }}
                          className="absolute h-full w-full top-0 left-0 flex justify-center  items-center text-white p-1 z-[10] opacity-0 group-hover:opacity-100 bg-card-bg2 bg-opacity-70 rounded  duration-200"
                        >
                          #{nft.id}
                        </div>
                      </div>
                    ))
                    .reverse()}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {showSingleNftModal && (
        <div className="absolute z-10 top-0 left-0 min-h-full w-full p-2 bg-primary-bg">
          <SingleNftModal
            collectionName={singleNftModalData.collectionName}
            tokenName={singleNftModalData.name}
            tokenDescription={singleNftModalData.description}
            imgSrc={singleNftModalData.imageUrl}
            tokenId={singleNftModalData.id}
            tokenAddress={singleNftModalData.address}
            onClose={toggleShowSingleNftModal}
          />
        </div>
      )}
    </div>
  );
};

export default Collection;
