import { useState } from "react";
import { X } from "react-feather";
import Moralis from "moralis";
import defaultNft from "../../assets/NftDiscoveryPage/nft_default.jpg";
import { log } from "../../utils/helper";
import { NFT } from "../../constants/Types";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import { Button } from "../ui/button";
import {
  NFT_IMPORT_MODAL_FIELD_ERROR,
  NFT_IMPORT_MODAL_RESPONSE_ERROR,
} from "../../constants/Enums";

type ImportNftsModalParams = {
  isOpen: boolean;
  onClose: () => any;
  updateSingleImportedNftData: (data: any) => void;
};

const ImportNfts = ({
  isOpen,
  onClose,
  updateSingleImportedNftData,
}: ImportNftsModalParams) => {
  const [address, setAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [showError, setShowError] = useState<{
    hasError: boolean;
    errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR;
  }>({ hasError: false, errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.NULL });
  const { smartAccountAddress, chainID } = useWalletConfig();
  const [hasError, setHasError] = useState<NFT_IMPORT_MODAL_FIELD_ERROR>(
    NFT_IMPORT_MODAL_FIELD_ERROR.NULL,
  );

  const handleAddressChange = (event: any) => {
    setAddress(event.target.value);
    setShowError({
      hasError: false,
      errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.NULL,
    });

    if (!event.target.value) {
      if (
        hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH ||
        hasError === NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID
      )
        setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.BOTH);
      else setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS);
    } else if (
      hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH ||
      hasError === NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID
    )
      setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID);
    else setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.NULL);
  };

  const handleTokenIdChange = (event: any) => {
    setTokenId(event.target.value);
    setShowError({
      hasError: false,
      errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.NULL,
    });

    if (!event.target.value) {
      if (
        hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH ||
        hasError === NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS
      )
        setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.BOTH);
      else setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID);
    } else if (
      hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH ||
      hasError === NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS
    )
      setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS);
    else setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.NULL);
  };

  const handleClose = () => {
    setAddress("");
    setTokenId("");
    setShowError({
      hasError: false,
      errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.NULL,
    });
    onClose();
  };

  const importSingleNft = async (e: any) => {
    e.preventDefault();
    try {
      if (!address && tokenId === "")
        setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.BOTH);
      else if (!address) setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS);
      else if (!tokenId) setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID);
      else {
        setHasError(NFT_IMPORT_MODAL_FIELD_ERROR.NULL);
        const response: any = await Moralis.EvmApi.nft.getNFTMetadata({
          chain: chainID,
          format: "decimal",
          normalizeMetadata: true,
          mediaItems: true,
          address,
          tokenId,
        });

        if (!response) {
          setShowError({
            hasError: true,
            errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.NOT_FOUND,
          });
          return;
        }

        if (smartAccountAddress === response.raw.owner_of) {
          const fetchedData: NFT = {
            collectionName: response.raw.name,
            address: response.raw.token_address,
            id: response.raw.token_id,
            imageUrl: response.raw.media.original_media_url || defaultNft,
            name: response.raw.normalized_metadata.name || "",
            description: response.raw.normalized_metadata.description || "",
          };

          updateSingleImportedNftData(fetchedData);
          setAddress("");
          setTokenId("");
          onClose();
        } else {
          setShowError({
            hasError: true,
            errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.INVALID_OWNER,
          });
        }
      }
    } catch (error: any) {
      setShowError({
        hasError: true,
        errorMessage: NFT_IMPORT_MODAL_RESPONSE_ERROR.TRY_AGAIN,
      });
      log("Error in ImportNfts", error, "error");
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center z-50 bg-primary-bg bg-opacity-80 p-4 text-md">
          <form
            className={`rounded-xl shadow-lg bg-secondary-bg w-full p-4 my-auto ${
              showError.hasError && " border border-red-700 "
            }`}
            onSubmit={importSingleNft}
          >
            <div className="flex justify-between mb-5 px-4">
              <div className="font-bold m-auto">Import NFT</div>
              <button
                className="hover:opacity-70 text-lg"
                onClick={() => handleClose()}
              >
                <X style={{ color: "#FFFFFF", fill: "#FFFFFF" }} />
              </button>
            </div>

            <div className="mb-4">
              <p className="mb-1 ml-2 text-sm">Address</p>
              <input
                className={`p-2 text-[12px] rounded w-full text-black ${
                  hasError === NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS ||
                  hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH
                    ? " border border-2 border-red-700 "
                    : " focus:outline-black "
                } `}
                type="text"
                value={address}
                onChange={handleAddressChange}
              />
              {(hasError === NFT_IMPORT_MODAL_FIELD_ERROR.ADDRESS ||
                hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH) && (
                <label className="text-[10px] text-red-700">
                  Token Address is required
                </label>
              )}
            </div>

            <div>
              <p className="mb-1 ml-2 text-sm">Token ID</p>
              <input
                className={`p-2 text-[12px] rounded w-full text-black ${
                  hasError === NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID ||
                  hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH
                    ? " border border-2 border-red-700 "
                    : " focus:outline-black "
                }  `}
                type="text"
                value={tokenId}
                onChange={handleTokenIdChange}
              />
              {(hasError === NFT_IMPORT_MODAL_FIELD_ERROR.TOKEN_ID ||
                hasError === NFT_IMPORT_MODAL_FIELD_ERROR.BOTH) && (
                <label className="text-[10px] text-red-700">
                  Token ID is required
                </label>
              )}
            </div>

            {showError.hasError && (
              <p className="mt-4 text-center text-red-500 font-semibold text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${showError.errorMessage}`,
                  }}
                />
              </p>
            )}

            <div className="flex gap-2 mt-6">
              <Button
                onClick={() => onClose()}
                variant={"default"}
                className=" flex-grow bg-card-bg2 hover:bg-card-bg2 hover:scale-105 duration-300"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant={"default"}
                className=" flex-grow bg-green-500 hover:bg-green-500 hover:bg-opacity-80 hover:scale-105 duration-300"
              >
                Import
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ImportNfts;
