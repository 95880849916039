import React from "react";
import IllustrationLoader from "../components/IllustrationLoader";

const TemporaryLoaderPage = () => (
  <div className="h-full w-full flex justify-center items-center">
    <IllustrationLoader height="h-[2em]" />
  </div>
);

export default TemporaryLoaderPage;
