import { useState } from "react";
import CryptoJS from "crypto-js";
import { getUserSettingsData, log } from "../../utils/helper";
import { UserSettingsType } from "../../constants/Types";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

export const usePasswordVerification = () => {
  const [isVerifyPassModalOpen, setIsVerifyPassModalOpen] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const { eoaAddress } = useWalletConfig();

  const verifyPassword = (): Promise<boolean> =>
    new Promise((resolve) => {
      setIsVerifyPassModalOpen(true);
      setShowPasswordError(false);

      const handleVerify = async (password: string) => {
        try {
          setIsVerifying(true);
          setShowPasswordError(false);
          const usersSettings: UserSettingsType[] = await getUserSettingsData();
          const userIndex = usersSettings.findIndex(
            (user: UserSettingsType) => user.address === eoaAddress,
          );

          if (userIndex !== -1) {
            const encryptedPassword =
              usersSettings[userIndex].settings.security.password;

            if (encryptedPassword) {
              const bytes = CryptoJS.AES.decrypt(encryptedPassword, password);
              const isValid = bytes.toString(CryptoJS.enc.Utf8) === eoaAddress;

              if (isValid) {
                setIsVerifyPassModalOpen(false);
                resolve(true);
              } else {
                setShowPasswordError(true);
              }
            } else {
              setShowPasswordError(true);
            }
          } else {
            setShowPasswordError(true);
          }
        } catch (error) {
          log("Error verifying password:", error);
          setShowPasswordError(true);
        } finally {
          setIsVerifying(false);
        }
      };

      const handleClose = () => {
        setIsVerifyPassModalOpen(false);
        setShowPasswordError(false);
        resolve(false);
      };

      window.verifyPasswordCallback = { handleVerify, handleClose };
    });

  return {
    isVerifyPassModalOpen,
    isVerifying,
    showPasswordError,
    verifyPassword,
  };
};

export default usePasswordVerification;
