const faqData = [
  {
    title: "What is WalletX?",
    description:
      "WalletX is a non-custodial on-chain gasless smart wallet designed to enhance the Web3 experience by eliminating gas fees and providing seamless, secure transactions.",
  },
  {
    title: "How does WalletX eliminate gas fees?",
    description:
      "WalletX uses a unique peer-to-peer advertisement layer where advertisers sponsor gas fees in exchange for users watching short ads before transactions.",
  },
  {
    title: "What are WalletX Soul NFTs?",
    description:
      "Soul NFTs are unique, gasless-minted NFTs that are stealable within 24 hours of minting. After 24 hours, they become SoulBound to the wallet they reside in.",
  },
  {
    title: "What chains does WalletX support?",
    description:
      "WalletX supports multiple chains, including Ethereum, Polygon, BSC, Arbitrum, Optimism, Avalanche, Base, and Linea.",
  },
  {
    title: "Can I manage multiple wallets with WalletX?",
    description:
      "Yes, WalletX allows you to create and manage several wallets, each with unique seed phrases, all under one roof.",
  },
  {
    title: "What is the WalletX Purple?",
    description:
      "The WalletX Purple is the testing environment for all the latest features of WalletX. Advance users can download WalletX purple and help test the latest features before they appear in WalletX black. You can download the WalletX Purple from here (add link)",
  },
  {
    title: "How can advertisers benefit from WalletX?",
    description:
      "Advertisers can target specific on-chain users with their ads, sponsor gas fees, and achieve cost-effective reach with high engagement.",
  },
  {
    title: "What is the role of $WAX tokens in WalletX?",
    description:
      "$WAX tokens are used within the WalletX ecosystem for various purposes, including staking for benefits, participating in governance, and as rewards for user engagement.",
  },
  {
    title: "Is WalletX secure?",
    description:
      "Yes, WalletX is a non-custodial wallet ensuring users have full control over their funds with added security features like fingerprint authentication and passkeys.",
  },
  {
    title: "How can I get involved with WalletX?",
    description:
      "You can get involved by using the WalletX wallet, participating in our community on social media, and applying for the WalletX Purple Program if you are a student.",
  },
];

export default faqData;
