import { Button } from "./ui/button";
import { RemoveModalType } from "../constants/Types";

const RemoveModal = ({
  onCancel,
  onRemove,
  isOpen,
  message,
  actionBtnName,
}: RemoveModalType) => (
  <div
    className={`fixed top-0 left-0 w-full h-full  bg-primary-bg bg-opacity-50 z-50 flex justify-center items-center  ${
      isOpen ? "" : "hidden"
    }`}
  >
    <div className=" flex flex-col gap-3 py-3 px-2 w-[90%]  justify-center items-center  rounded-lg shadow-lg bg-secondary-bg">
      <div className="text-center py-4 px-2 ">
        <p className="text-md tracking-wide font-semibold ">{message}</p>
        <div className=" flex gap-5 justify-center item-center mt-5 ">
          <Button
            onClick={() => {
              onCancel();
            }}
            variant={"secondary"}
            size={"lg"}
            className="w-full bg-neutral-700 hover:bg-card-bg2 text-white"
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              onRemove();
            }}
            variant={"destructive"}
            size={"lg"}
            className="bg-[#b91c1c] hover:bg-red-800 w-full"
          >
            {actionBtnName}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default RemoveModal;
