import { useEffect, useState } from "react";
import Store from "../../../dapp-connection/js/components/StoreComponent";
import EthProvider from "../../../dapp-connection/js/services/EthProvider";
import VerifyPassword from "../../../components/Modals/VerifyPassword";
import ConnectScreen from "../components/ConnectScreen";

import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import {
  getCoinBalance,
  getUserSettingsData,
  log,
} from "../../../utils/helper";
import { validateBiometric } from "../../../hooks/functional-hooks";
import { UserSettingsType } from "../../../constants/Types";
import {
  ERROR_EVENTS,
  ERROR_MESSAGE,
  DEVICE_AUTH_TYPE,
} from "../../../constants/Enums";
import Loader from "../../../components/common/Loader";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

type ConnectRequestDataType = {
  connect_title: string | null;
  connect_origin: string | null;
};

export default function DappConnect() {
  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");
  const [dappData, setDappData] = useState<ConnectRequestDataType>({
    connect_title: null,
    connect_origin: null,
  });
  const biometricAuth = validateBiometric();
  const [activeSmartWallet, setActiveSmartWallet] =
    useState<UserSettingsType | null>(null);

  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();
  const [credId, setCredId] = useState<string>("");

  const {
    isInitialized,
    init,
    smartAccountAddress,
    provider,
    eoaAddress,
    chainID: chainId,
    chainData: chainDetails,
    userSettings,
  } = useWalletConfig();

  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE | null>(
    null,
  );

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          setActiveSmartWallet(usersSettings[userIndex]);

          const { biometrics } = usersSettings[userIndex].settings.security;

          const { connect } =
            usersSettings[userIndex].settings.transactionSettings;

          setWalletAuthType(connect);

          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [walletAuthType, eoaAddress]);

  useEffect(() => {
    if (provider) {
      getCoinBalance(
        userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
        provider,
        setBalance,
      );
    }
  }, [
    provider,
    userSettings?.isEoaSelected,
    eoaAddress,
    smartAccountAddress,
    chainId,
  ]);

  useEffect(() => {
    const data: any = Store.getTempState();

    // log("Connect dapp request useEffect", { data, dappData });
    if (data)
      setDappData({
        connect_title: data.connect_title,
        connect_origin: data.connect_origin,
      });
  }, [dappData.connect_title, dappData.connect_origin]);

  const rejectConnectionRequest = (reason: string | null = null) => {
    EthProvider.onCancel(reason);
  };

  const handleError = () => {
    rejectConnectionRequest(ERROR_MESSAGE.DAPP_NETWORK_ERROR);
  };

  useEffect(() => {
    window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    return () => {
      window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    };
  });

  useEffect(() => {
    async function initializeSmartWallet() {
      if (!smartAccountAddress) {
        init({
          walletName: activeSmartWallet?.walletName || "",
          chainId,
        });
      }
    }

    setSmartWalletAddress(smartAccountAddress);

    initializeSmartWallet();

    // if (provider && smartAccountAddress) setIsLoading(false);
  }, [smartAccountAddress, smartWalletAddress]);

  const connectWallet = async () => {
    let isValid = false;

    if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
      isValid = await verifyPassword();
    } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
      isValid = true;
    }

    if (!isValid) {
      rejectConnectionRequest("Authentication Failed");
      return;
    }

    EthProvider.onAccept({
      address: userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
      chainId,
      isConnected: true,
      balance,
      chainDetails,
    });
  };

  useEffect(() => {
    if (isInitialized && provider && smartAccountAddress) {
      setIsLoading(false);
    }
  }, [isInitialized, smartAccountAddress, provider]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <ConnectScreen
          connectOrigin={dappData.connect_origin}
          smartWalletAddress={
            userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress
          }
          rejectConnectionRequest={rejectConnectionRequest}
          connectWallet={connectWallet}
        />
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
}
