import { Tokens } from "./Types";

const tokens: Tokens = {
  1: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "BUSD",
      symbol: "BUSD",
      address: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  137: [
    {
      name: "MATIC",
      symbol: "MATIC",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
      balance: 0,
    },
    {
      name: "Wrapped Matic",
      symbol: "WMATIC",
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  42161: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      address: "0x912ce59144191c1204e64559fe8253a0e49e6548",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  42170: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png",
      balance: 0,
    },
  ],
  1101: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png",
      balance: 0,
    },
    {
      name: "MATIC",
      symbol: "MATIC",
      address: "0xa2036f0538221a77A3937F1379699f44945018d0",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
      balance: 0,
    },
    {
      name: "Wrapped ETH",
      symbol: "WETH",
      address: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0xC5015b9d9161Dca7e18e32f6f25C4aD850731Fd4",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  56: [
    {
      name: "BNB",
      symbol: "BNB",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png",
      balance: 0,
    },
    {
      name: "Wrapped BNB",
      symbol: "WBNB",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  10: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "Optimism",
      symbol: "OP",
      address: "0x4200000000000000000000000000000000000042",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
      balance: 0,
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0x4200000000000000000000000000000000000006",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
  ],
  43114: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
      balance: 0,
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      address: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png",
      balance: 0,
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
  ],
  8453: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "Coinbase Wrapped Staked ETH",
      symbol: "cbETH",
      address: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/21535.png",
      balance: 0,
    },
    {
      name: "USD Base Coin",
      symbol: "USDbC",
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimal: 6,
      logo: "https://basescan.org/token/images/usdbc_ofc_32.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  1284: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png",
      balance: 0,
    },
  ],
  59144: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
  ],
  5000: [
    {
      name: "MNT",
      symbol: "MNT",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/27075.png",
      balance: 0,
    },
  ],
  204: [
    {
      name: "BNB",
      symbol: "BNB",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png",
      balance: 0,
    },
    {
      name: "Wrapped BNB",
      symbol: "WBNB",
      address: "0xb97fe5f3e5bf1ddac8ea1d2fa77f0a45cdb1dcec",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png",
      balance: 0,
    },
    {
      name: "Tether",
      symbol: "USDT",
      address: "0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "First Digital USD",
      symbol: "FDUSD",
      address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/26081.png",
      balance: 0,
    },
    {
      name: "CubiSwap Finance Token",
      symbol: "CUBI",
      address: "0xb01d49c26416a352fac4fbb3d555d5f2543e3247",
      decimal: 18,
      logo: "https://opbnb.bscscan.com/token/images/cubiswap_32.png",
      balance: 0,
    },
  ],
  81457: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "USD Bancor",
      symbol: "USDB",
      address: "0x4300000000000000000000000000000000000003",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/29599.png",
      balance: 0,
    },
  ],
  534352: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
  ],

  // TESTNETS
  11155111: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
      balance: 0,
    },
  ],
  80002: [
    {
      name: "MATIC",
      symbol: "MATIC",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x747A4168DB14F57871fa8cda8B5455D8C2a8e90a",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0xf555F8d9Cf90f9d95D34488e6C852796D9acBd31",
      decimal: 6,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x6Dcc603fdbC7B0847b66aB04F4fB4C668bC6cAA6",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  97: [
    {
      name: "BNB",
      symbol: "BNB",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x03bbb5660b8687c2aa453a0e42dcb6e0732b1266",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x1ffa9c87ead57adc9e4f9a7d26ec3a52150db3b0",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
      balance: 0,
    },
    {
      name: "Dai Stablecoin",
      symbol: "DAI",
      address: "0x355c8c8395fadf2eaa6bb27f86e53e432e3de4e6",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
      balance: 0,
    },
  ],
  1442: [
    {
      name: "Matic",
      symbol: "MATIC",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
      balance: 0,
    },
  ],
  11155420: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
  ],
  43113: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
      balance: 0,
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0xa7b3002ec739077057689a389460a0e7c0367369",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      balance: 0,
    },
  ],
  421614: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
  ],
  84532: [
    {
      name: "ETH",
      symbol: "ETH",
      address: "0x0000000000000000000000000000000000001010",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
  ],
  168587773: [
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      decimal: 18,
      logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      balance: 0,
    },
  ],
};

export default tokens;
