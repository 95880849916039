import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BiconomySmartAccountV2,
  createSmartAccountClient,
} from "@biconomy/account";
import { ethers } from "ethers";
import { AppDispatch, RootState } from "../store";
import {
  initializeWallet,
  initializeEOA,
  initializeSmartWallet,
  addNewSmartAccount,
  setUserSettings,
  login,
  logout,
} from "../slices/walletSlice";
import { UserSettingsType } from "../../../constants/Types";
import { log } from "../../../../src/utils/helper";

const useWallet = () => {
  const dispatch = useDispatch<AppDispatch>();
  const wallet = useSelector((state: RootState) => state.wallet);

  const init = useCallback(
    async ({
      walletName,
      credentialId,
      seedPhrase,
      smartAccountIndex,
      chainId,
    }: {
      walletName: string;
      chainId: number;
      credentialId?: string;
      seedPhrase?: string;
      smartAccountIndex?: number;
    }) => {
      await dispatch(initializeWallet({ chainId, walletName }));
      await dispatch(initializeEOA({ credentialId, seedPhrase }));
      await dispatch(initializeSmartWallet({ smartAccountIndex }));
    },
    [dispatch],
  );

  const addNewAccount = useCallback(async () => {
    try {
      await dispatch(addNewSmartAccount());
    } catch (error) {
      log("Error adding new smart account:", error);
    }
  }, [dispatch]);

  const updateUserSettings = useCallback(
    (settings: UserSettingsType) => {
      dispatch(setUserSettings(settings));
    },
    [dispatch],
  );

  const handleLogin = useCallback(() => {
    dispatch(login());
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const getProvider = useCallback(() => {
    const { provider } = wallet;

    if (provider && provider?.connection?.url === wallet?.chainData?.rpc) {
      return provider;
    }

    if (wallet.chainData && wallet.chainData.rpc) {
      return new ethers.providers.JsonRpcProvider(wallet.chainData.rpc);
    }

    return null;
  }, [wallet.chainData]);

  const getSigner = useCallback(() => {
    const provider = getProvider();

    if (wallet.eoaPrivateKey && provider) {
      return new ethers.Wallet(wallet.eoaPrivateKey, provider);
    }

    return null;
  }, [wallet.eoaPrivateKey, getProvider]);

  const getSmartAccountProvider = useCallback(async () => {
    const signer = getSigner();

    if (signer && wallet.chainData) {
      try {
        const smartAccountProvider: BiconomySmartAccountV2 =
          await createSmartAccountClient({
            signer,
            chainId: wallet.chainData.chainId,
            rpcUrl: wallet.chainData.rpc,
            bundlerUrl: wallet.chainData.bundlerUrl,
            biconomyPaymasterApiKey: wallet.chainData.paymasterApiKey,
            index: wallet.activeSmartAccountIndex,
          });

        return smartAccountProvider;
      } catch (error) {
        log("Error initializing smart account provider:", error);
        return null;
      }
    }

    return null;
  }, [dispatch, wallet.smartAccountAddress]);

  const getWalletProviders = useCallback(async () => {
    const provider = getProvider();
    const signer = getSigner();
    const smartAccountProvider = await getSmartAccountProvider();

    return {
      provider,
      signer,
      smartAccountProvider,
    };
  }, [dispatch, getProvider, getSigner, getSmartAccountProvider]);

  useEffect(() => {
    if (wallet.isInitialized && !wallet.isConnected) {
      dispatch(initializeSmartWallet({}));
    }
  }, [wallet.isInitialized, wallet.isConnected, dispatch]);

  return {
    ...wallet,
    init,
    addNewAccount,
    updateUserSettings,
    login: handleLogin,
    logout: handleLogout,
    getProvider,
    getSmartAccountProvider,
    getWalletProviders,
  };
};

export default useWallet;
