import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { GaslessType } from "../../../constants/Types";
import Ad from "./Ad";
import ToggleSwitch from "../../ToggleSwitch";

const Gasless = ({
  isEoa,
  isGaslessDisabled,
  isGaslessActive,
  toggleGaslessSwitch,
  adModal,
  handleAdComplete,
  rewardInfo,
}: GaslessType) => {
  const goGaslessButton = () => (
    <>
      <p
        className={` font-sans font-semibold tracking-wide ${
          isGaslessDisabled ? ` text-gray-500 ` : ""
        }`}
      >
        Go Gasless
      </p>

      <ToggleSwitch
        width={40}
        value={isGaslessActive}
        isDisable={isGaslessDisabled}
        onChange={toggleGaslessSwitch}
      />
    </>
  );

  return (
    <>
      <div
        className={`flex items-center justify-between w-[85%] pr-1 pb-2 text-white text-base  `}
      >
        {isEoa && isGaslessDisabled ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="w-[100%] flex justify-between">
                {goGaslessButton()}
              </TooltipTrigger>
              <TooltipContent className="bg-secondary-bg text-white border-black ">
                <p className="whitespace-nowrap">
                  {isEoa
                    ? "Gasless transaction not available for EOA"
                    : "Gasless transaction not available as this moment."}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          goGaslessButton()
        )}
      </div>
      <Ad
        adModal={adModal}
        handleAdComplete={handleAdComplete}
        rewardInfo={rewardInfo}
      />
    </>
  );
};

export default Gasless;
