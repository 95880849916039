import { useEffect, useState } from "react";
import { ChevronRight, Edit2 } from "react-feather";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";
import { UserSettingsType } from "../../../constants/Types";
import PageTitle from "../../../components/PageTitle";
import ThemeModal from "../../../components/Modals/ThemeModal";
import Web3AvatarComponent from "../../../components/Web3AvatarComponent";
import { getUserSettingsData, log } from "../../../utils/helper";
import RenameSmartAddress from "../../../components/Modals/RenameSmartAddress";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";

const EditAccount = () => {
  const [showRenameBox, setShowRenameBox] = useState(false);
  const { smartAccountAddress, eoaAddress } = useWalletConfig();
  const location = useLocation();
  const [name, setName] = useState<string>("");
  const [showThemeModal, setShowThemeModal] = useState<boolean>(false);

  const fetchName = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const { walletName } = usersSettings[userIndex];

        setName(walletName);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  useEffect(() => {
    fetchName();
  }, [showRenameBox]);

  const navigate = useNavigate();

  const editAccountOptions = [
    {
      title: name,
      navigate: "",
      onclick: () => setShowRenameBox(true),
    },
    {
      title: "Smart Addresses",
      navigate: "",
      onclick: () =>
        navigate("/dashboard/select-an-address", {
          state: { from: "/setting/edit-account" },
        }),
    },
    {
      title: "Secret Recovery Phrase",
      navigate: "/setting/edit-account/recovery/warning-page",
    },
    {
      title: "Theme",
      navigate: "",
      onclick: () => setShowThemeModal(true),
    },
  ];

  return (
    <div className="h-full select-none">
      <PageTitle
        title="Account"
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        leftDestinationRoute={
          location.state?.goToHome ? "/" : "/dashboard/settings"
        }
      />
      <Web3AvatarComponent
        address={smartAccountAddress}
        className="w-16 h-16 mx-auto my-4"
      />

      <div className="px-2">
        {editAccountOptions.map((option, index) => (
          <div
            key={index}
            onClick={
              option.navigate === ""
                ? option.onclick
                : () => navigate(option.navigate)
            }
            className={`flex justify-between gap-1 text-gray-100  hover:bg-card-bg  cursor-pointer rounded-lg px-2 py-3  w-full`}
          >
            <div className="w-[80%] px-2 my-auto">
              <h1 className="font-medium text-md ">{option.title}</h1>
            </div>
            <div className=" flex justify-end items-center w-[13%] text-xl font-semibold text-gray-300">
              {index === 0 ? <Edit2 className="h-6 pr-1" /> : <ChevronRight />}
            </div>
          </div>
        ))}
      </div>

      {showRenameBox && (
        <RenameSmartAddress
          isOpen={showRenameBox}
          name={name}
          address={smartAccountAddress}
          onClose={() => {
            setShowRenameBox(false);
          }}
          isEoa={true}
        />
      )}

      <ThemeModal
        isOpen={showThemeModal}
        onClose={() => setShowThemeModal(false)}
      />
    </div>
  );
};

export default EditAccount;
