import React, { useState } from "react";
import { motion } from "framer-motion";
import PageTitle from "../../../components/PageTitle";
import { PAGE_TITLE_BUTTON_TYPE } from "../../../constants/Enums";

import logo from "../../../assets/icons/walletX-Full.png";

import twitterLogo from "../../../assets/SocialIcons/twitter.svg";
import telegramLogo from "../../../assets/SocialIcons/telegram.svg";
import discordLogo from "../../../assets/SocialIcons/discord.svg";
import mediumLogo from "../../../assets/medium.svg";
import websiteLogo from "../../../assets/SocialIcons/website.svg";
import dashboardLogo from "../../../assets/dashboard.svg";

import manifest from "../../../manifest/manifest.json";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import illustrationsData from "../../../constants/AboutIconData";

const About = () => {
  const [selectedIllustration, setSelectedIllustration] = useState<
    number | null
  >(null);

  const handleBack = () => {
    setSelectedIllustration(null);
  };

  return (
    <PageTransitionWrapper>
      <PageTitle
        leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
        leftDestinationRoute={
          selectedIllustration !== null ? undefined : "/dashboard/settings"
        }
        onLeftButtonClick={
          selectedIllustration !== null ? handleBack : undefined
        }
        title="About"
        rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        backRouteState={true}
      />

      <div className="flex flex-col">
        {selectedIllustration === null && selectedIllustration !== 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="flex justify-center items-center mt-10 mb-5"
          >
            <img className="h-16 bg-primary-bg z-20" src={logo} alt="walletX" />
          </motion.div>
        )}

        <div
          className={`${
            selectedIllustration === null && " mt-4 gap-2 "
          } flex justify-center `}
          onClick={() => {
            if (selectedIllustration !== null) {
              setSelectedIllustration(null);
            }
          }}
        >
          {illustrationsData.map(
            (item) =>
              (selectedIllustration === null ||
                selectedIllustration === item.index) && (
                <motion.img
                  layout
                  key={item.name}
                  src={item.icon}
                  alt={item.name}
                  onClick={() => {
                    if (selectedIllustration === item.index) {
                      setSelectedIllustration(null);
                    } else {
                      setSelectedIllustration(Number(item.index));
                    }
                  }}
                  className={`
                    h-12 w-12 cursor-pointer select-none
                    ${
                      selectedIllustration === null &&
                      (Number(item.index) % 2 === 0 ? "mb-3" : "mt-3")
                    }
                    ${
                      selectedIllustration === item.index
                        ? "h-28 w-28 mt-6"
                        : "hover:scale-110"
                    }
                  `}
                />
              ),
          )}
        </div>

        {(selectedIllustration || selectedIllustration === 0) && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className=" p-2 px-6 leading-loose mt-10 !font-semibold capitalize"
          >
            {illustrationsData[selectedIllustration].name}
          </motion.p>
        )}

        {(selectedIllustration || selectedIllustration === 0) && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="text-sm px-6 leading-loose text-left"
          >
            {illustrationsData[selectedIllustration].desc}
          </motion.p>
        )}

        {selectedIllustration === null && selectedIllustration !== 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="flex flex-col gap-4 mt-10 mx-2"
          >
            <a
              href="https://getwalletx.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="flex items-center bg-card-bg hover:bg-card-bg2 p-2 w-full gap-4 rounded-md"
            >
              <img className="h-6 mr-1" src={websiteLogo} alt="web" />
              <p>Website</p>
            </a>
            <a
              href="https://user.getwalletx.com/"
              rel="noopener noreferrer"
              className="flex items-center bg-card-bg hover:bg-card-bg2 p-2 w-full gap-4 rounded-md"
            >
              <img className="h-6 mr-1" src={dashboardLogo} alt="web" />
              <p>User Dashboard</p>
            </a>
            <a
              href="https://getwalletx.medium.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="flex items-center bg-card-bg hover:bg-card-bg2 p-2 w-full gap-4 rounded-md"
            >
              <img className="h-6 mr-1" src={mediumLogo} alt="web" />
              <p>Medium Articles</p>
            </a>
          </motion.div>
        )}
      </div>

      <div className="absolute bottom-12 left-1/2 -translate-x-1/2 flex mx-auto w-3/5 mt-10 justify-between ">
        <a
          href="https://twitter.com/getWalletX"
          target="_blank"
          rel="noopener noreferrer"
          className="flex gap-10 justify-center items-center"
        >
          <img className="h-6" src={twitterLogo} alt="twitter logo" />
        </a>

        <a
          href="https://t.me/walletx"
          target="_blank"
          rel="noopener noreferrer"
          className="flex gap-10 justify-center items-center"
        >
          <img className="h-7" src={telegramLogo} alt="telegram logo" />
        </a>

        <a
          href="https://discord.gg/398VZ55WFT"
          target="_blank"
          rel="noopener noreferrer"
          className="flex gap-10 justify-center items-center"
        >
          <img className="h-8" src={discordLogo} alt="discord logo" />
        </a>
      </div>
      <p className="text-gray-500  absolute bottom-4 left-1/2 translate-x-[-50%] text-xs text-center">
        Version {manifest.version}
      </p>
    </PageTransitionWrapper>
  );
};

export default About;
