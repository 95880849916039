import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Registration from "../pages/OnboardingAndAuthentication/Landing/Registration";
import Login from "../pages/OnboardingAndAuthentication/Login";
import PublicLayout from "../pages/Layout/PublicLayout";
import { getUserSettingsData, log } from "../utils/helper";
import UseExistingWallet from "../pages/OnboardingAndAuthentication/UseExistingWallet";
import ImportWallet from "../pages/OnboardingAndAuthentication/ImportWallet/ImportWallet";
import CreateSmartWallet from "../components/CreateSmartWallet";
import { UserSettingsType } from "../constants/Types";

function PublicRoutes() {
  const [existingUserSettings, setExistingUserSettings] = useState<
    UserSettingsType[]
  >([]);

  useEffect(() => {
    const fetchAutoLockTime = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        setExistingUserSettings(usersSettings);
      } catch (error) {
        log("Error fetching auto-lock time:", error);
      }
    };

    fetchAutoLockTime();
  }, []);

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          element={
            !existingUserSettings || !existingUserSettings.length ? (
              <Registration />
            ) : (
              <Login />
            )
          }
          path="/"
        />
        <Route element={<Login />} path="/login" />
        <Route element={<Registration />} path="/register" />
        <Route element={<UseExistingWallet />} path="/use-existing-wallet" />
        <Route element={<ImportWallet />} path="/import-wallet" />
        <Route
          path="/import-wallet/create-smart-wallet"
          element={<CreateSmartWallet />}
        />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default PublicRoutes;
