import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import copy from "../assets/copy-blue.svg";
import close from "../assets/NftDiscoveryPage/close.svg";
import { copyToClipboard, getShortDisplayString } from "../utils/helper";
import { NFT, SingleNftModalType } from "../constants/Types";

const SingleNftModal = ({
  collectionName,
  imgSrc,
  tokenId,
  tokenAddress,
  tokenName,
  tokenDescription,
  onClose,
}: SingleNftModalType) => {
  const navigate = useNavigate();

  const transferNFTDataAndNavigate = () => {
    const nftData: NFT = {
      collectionName,
      address: tokenAddress,
      id: tokenId,
      imageUrl: imgSrc,
      name: tokenName,
      description: tokenDescription,
    };

    navigate("/dashboard/transaction/add-address", {
      state: nftData,
    });
  };

  return (
    <div className="pb-20 bg-primary-bg">
      <div className="flex justify-between mb-4">
        <p>{collectionName}</p>
        <img src={close} className="h-6 float-right" onClick={onClose} />
      </div>
      <motion.img
        layoutId={`nft-image-${tokenId}`}
        src={imgSrc}
        className="border border-card-bg mb-6 aspect-square rounded object-scale-down w-4/5 mx-auto"
      />
      <div className="px-2">
        <p className="text-slate-200">{tokenName}</p>
        <p className="text-slate-500 text-sm"># {tokenId}</p>

        {tokenDescription !== "" && (
          <>
            <p className="text-slate-200 mt-4">Description </p>
            <p className="text-slate-500 text-sm max-h-[75px] overflow-y-scroll">
              {tokenDescription}
            </p>
          </>
        )}
        <div className="mt-4 flex justify-between">
          <p>Contract Address: </p>
          <div className="flex gap-2">
            <p className="text-ellipsis overflow-hidden whitespace-nowrap ">
              {getShortDisplayString(tokenAddress)}
            </p>
            <img
              src={copy}
              onClick={() => copyToClipboard(tokenAddress || "")}
              className="h-6 "
            />
          </div>
        </div>
      </div>

      <button
        className=" p-2 px-4 mt-8 block mx-auto bg-secondary-bg rounded"
        onClick={() => {
          transferNFTDataAndNavigate();
        }}
      >
        Send
      </button>
    </div>
  );
};

export default SingleNftModal;
