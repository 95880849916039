import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Mousewheel,
} from "swiper/modules";
import { ChevronRight } from "react-feather";
import ToggleSwitch from "../ToggleSwitch";

import { UserSettingsType } from "../../constants/Types";
import {
  getUserSettingsData,
  setUserSettingsData,
  log,
} from "../../utils/helper";
import { THEMECOLOR } from "../../constants/Enums";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

type ThemeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ThemeModal = ({ isOpen, onClose }: ThemeModalProps) => {
  const [selected, setSelected] = useState<THEMECOLOR>(THEMECOLOR.DEFAULT);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { eoaAddress } = useWalletConfig();
  const swiperRef = useRef<SwiperCore>();
  const [isSorted, setIsSorted] = useState(false);
  const [isDragged, setIsDragged] = useState<boolean>(false);

  const handleColorChange = (color: THEMECOLOR) => {
    setSelected(color);
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const fetchTheme = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const userTheme =
          usersSettings[userIndex].settings?.preferences?.theme ||
          THEMECOLOR.DEFAULT;

        setSelected(userTheme as THEMECOLOR);
      } else {
        setSelected(THEMECOLOR.DEFAULT);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  const updateTheme = async (theme: THEMECOLOR) => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        usersSettings[userIndex].settings.preferences.theme = theme;
      }

      await setUserSettingsData(usersSettings);
    } catch (error) {
      log("Error updating theme:", error);
    }
  };

  const sortColors = (a: any, b: any) => {
    if (isSorted) return 0;

    setIsSorted(true);

    if (a.color === selected) return -1;

    if (b.color === selected) return 1;

    return 0;
  };

  const colors = Object.entries(THEMECOLOR)
    .filter(([name]) => name !== "DEFAULT")
    .map(([name, color]) => ({
      name: name.charAt(0) + name.slice(1).toLowerCase(),
      color,
      formattedName: name
        .replace(/_/g, " ") // Replace all underscores with spaces
        .toLowerCase() // Convert to lowercase
        .split(" ") // Split into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
        .join(" "), // Join the words back together
    }))
    .sort(sortColors);

  useEffect(() => {
    fetchTheme();
  }, []);

  useEffect(() => {
    updateTheme(selected);
  }, [selected]);

  useEffect(() => {
    // Update the selected color when activeIndex changes
    const selectedColor = colors[activeIndex].color;

    handleColorChange(selectedColor as THEMECOLOR);
  }, [activeIndex]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed left-1/2 translate-x-[-50%] top-0 h-full w-[94%] bg-primary-bg bg-opacity-70 flex items-end select-none"
        >
          <motion.div
            initial={{ translateY: "110%" }}
            animate={{ translateY: "0%" }}
            exit={{ translateY: "110%" }}
            transition={{ duration: 0.5 }}
            onClick={stopPropagation}
            className="flex flex-col gap-1 w-full p-4 pb-6 pt-4 bg-secondary-bg rounded-t-xl"
          >
            <p className="px-2 text-center font-semibold">Select Theme</p>
            <div
              className="h-44 w-full  flex justify-center items-center"
              style={{
                maskImage:
                  "linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 30%, hsl(0 0% 0% / 1) 70%, hsl(0 0% 0% / 0))",
              }}
            >
              <Swiper
                onSwiper={(swiper: any) => {
                  swiperRef.current = swiper;
                }}
                modules={[EffectCoverflow, Pagination, Navigation, Mousewheel]}
                effect="coverflow"
                centeredSlides={true}
                slidesPerView="auto"
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                loop
                onSlideChange={(swiper: any) =>
                  setActiveIndex(swiper.realIndex)
                }
                initialSlide={colors.findIndex(
                  (color) => color.color === selected,
                )}
                mousewheel={true}
                className="flex justify-center items-center w-full h-full"
                onTouchStart={() => {
                  setIsDragged(true);
                }}
                onTouchEnd={() => {
                  setIsDragged(false);
                }}
              >
                {colors.map((color, index) => (
                  <SwiperSlide
                    key={index}
                    className={`flex flex-col items-center justify-center ${
                      isDragged ? " cursor-grabbing " : "cursor-grab"
                    }`}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      overflow: "none",
                      width: "110px",
                      filter:
                        selected === color.color
                          ? `drop-shadow(0 0 17px${color.color})`
                          : "none",
                    }}
                    onClick={() => {
                      if (selected === THEMECOLOR.DEFAULT) {
                        handleColorChange(color.color as THEMECOLOR);
                      }
                    }}
                  >
                    <div
                      style={{
                        border:
                          selected === color.color ? "5px solid white" : "none",
                        padding: "5px", // Add space between color and border
                        borderRadius: "50%", // Ensure the outer border is also rounded
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: color.color,
                          width: selected === color.color ? "90px" : "70px",
                          height: selected === color.color ? "90px" : "70px",
                          borderRadius: "50%",
                          transition: "all 0.3s ease-in-out",
                        }}
                      ></div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="h-12 w-full flex justify-between items-center">
              <ChevronRight
                className="h-7 w-7 cursor-pointer rotate-180"
                style={{
                  color: colors[activeIndex - 1]
                    ? colors[activeIndex - 1].color
                    : colors[colors.length - 1].color,
                  transition: "all 0.3s ease-in-out",
                }}
                onClick={() => swiperRef.current?.slidePrev()}
              />
              <AnimatePresence>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  className="text-center text-white font-semibold"
                >
                  {colors[activeIndex].formattedName}
                </motion.p>
              </AnimatePresence>
              <ChevronRight
                className="h-7 w-7 cursor-pointer"
                style={{
                  color: colors[activeIndex + 1]
                    ? colors[activeIndex + 1].color
                    : colors[0].color,
                  transition: "all 0.3s ease-in-out",
                }}
                onClick={() => swiperRef.current?.slideNext()}
              />
            </div>
            <div className="flex w-full gap-2 justify-between items-center mt-4">
              <p>Use Default</p>
              <ToggleSwitch
                width={50}
                value={selected === THEMECOLOR.DEFAULT}
                onChange={() => handleColorChange(THEMECOLOR.DEFAULT)}
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ThemeModal;
