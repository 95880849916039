import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MoreVertical, UserPlus } from "react-feather";
import {
  getShortDisplayString,
  getUserSettingsData,
  log,
} from "../utils/helper";
import ManageAddressModal from "./Modals/ManageAddressModal";
import RenameAddressModal from "./Modals/RenameAddressModal";
import {
  AddressInAddressBookType,
  AddressCardType,
  UserSettingsType,
} from "../constants/Types";
import Web3AvatarComponent from "./Web3AvatarComponent";
import useWalletConfig from "../lib/store/hooks/useWalletConfig";

const AddressCard = ({
  name,
  address,
  getClickedAddress,
  isSelected,
  onClick,
  isAddAddressModalActive,
  usersDomainName: userHandle,
}: AddressCardType) => {
  const [isManageAddressModalOpen, setIsManageAddressModalOpen] =
    useState<boolean>(false);
  const [isRenameAddressModalOpen, setIsRenameAddressModalOpen] =
    useState<boolean>(false);
  const [isAddressPresentInAddressBook, setIsAddressPresentInAddressBook] =
    useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);

  const { smartAccountAddress, eoaAddress } = useWalletConfig();
  const location = useLocation();
  const currentPath = location.pathname;

  const sendAddress = () => {
    const clickedAddress = address;

    getClickedAddress(clickedAddress);
  };
  const openManageAddressModal = (e: any) => {
    e.stopPropagation();
    setIsManageAddressModalOpen(true);
  };

  const closeManageAddressModal = () => {
    setIsManageAddressModalOpen(false);
  };

  const openRenameAddressModal = (e: any) => {
    e.stopPropagation();
    setIsRenameAddressModalOpen(true);
  };

  const closeRenameAddressModal = () => {
    setIsRenameAddressModalOpen(false);
    if (isAddAddressModalActive) {
      isAddAddressModalActive(false);
    }
  };

  const checkAddressInAddressBook = async (_address: string) => {
    try {
      const currentAddressBook: UserSettingsType[] =
        await getUserSettingsData();

      const userIndex = currentAddressBook.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      const addressBookOfEOA =
        currentAddressBook[userIndex].settings.addressBook;

      const addressExists = addressBookOfEOA.some(
        (entry: AddressInAddressBookType) => entry.address === _address,
      );

      setIsAddressPresentInAddressBook(addressExists);
    } catch (error) {
      log("Error checking address in AddressBook:", error, "error");
    }
  };

  useEffect(() => {
    checkAddressInAddressBook(address);
  }, [address, smartAccountAddress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 1);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className=" relative max-w-[92%] mx-auto m-3 ">
        <div
          onClick={() => {
            sendAddress();
            onClick();
          }}
          className={`flex flex-row gap-3 h-16 max-h-16 items-center bg-card-bg text-white rounded-xl shadow-md pl-3 pr-2 py-2 border-2 border-black hover:border-neutral-500 ${
            isSelected ? "border-2 border-solid border-gray-300 " : ""
          }`}
        >
          <Web3AvatarComponent address={address} className=" w-11 h-9" />

          <div className="w-full flex justify-between items-center leading-[1.2em]">
            <div>
              {name || userHandle ? (
                <>
                  {name !== "" ? (
                    <>
                      <p className="text-md font-semibold">{name}</p>
                    </>
                  ) : (
                    <>
                      <p className="text-md font-semibold">{userHandle}</p>
                    </>
                  )}
                  <p className="text-gray-200 font-semibold w-fit text-sm">
                    {getShortDisplayString(address)}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-gray-200 font-semibold w-fit text-md">
                    {getShortDisplayString(address)}
                  </p>
                </>
              )}
            </div>
            {currentPath === "/dashboard/transaction/add-address" ? (
              <>
                {isAddressPresentInAddressBook !== true &&
                showButton === true ? (
                  <button
                    className="transition"
                    style={{ animation: "fadeIn ease-in" }}
                    onClick={(e) => {
                      openRenameAddressModal(e);
                      if (isAddAddressModalActive) {
                        isAddAddressModalActive(true);
                      }
                    }}
                  >
                    <UserPlus size={20} className="text-neutral-300  " />
                  </button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <button onClick={(e) => openManageAddressModal(e)}>
                  <MoreVertical className="text-neutral-300" />
                </button>
              </>
            )}
          </div>
        </div>
        <ManageAddressModal
          isOpen={isManageAddressModalOpen}
          onClose={() => closeManageAddressModal()}
          address={address}
          name={name}
        />
        <RenameAddressModal
          isOpen={isRenameAddressModalOpen}
          onClose={() => closeRenameAddressModal()}
          address={address}
          name={name}
        />
      </div>
    </>
  );
};

export default AddressCard;
