import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Search, X } from "react-feather";
import { useRecoilState } from "recoil";
import { ethers } from "ethers";
import { motion, AnimatePresence } from "framer-motion";

import {
  getItemFromStorage,
  getUserSettingsData,
  log,
  setItemInStorage,
} from "../../utils/helper";
import { supportedChainsState } from "../../state/SupportedChainsState";
import ComingSoon from "../common/ComingSoon";
import {
  ERROR_MESSAGE,
  ERROR_TOAST_MESSAGES,
  MIXPANEL_KEY,
  STORAGE_KEYS,
  TAB_LIST,
} from "../../constants/Enums";
import MixPanel from "../../utils/MixPanel";
import { UserSettingsType } from "../../constants/Types";
import useWallet from "../../lib/store/hooks/useWallet";

type chainSelectionModalParams = {
  isOpen: boolean;
  onClose: () => void;
};

const ChainSelection = ({ isOpen, onClose }: chainSelectionModalParams) => {
  const [Chains] = useRecoilState(supportedChainsState);
  const [activeTab, setActiveTab] = useState<TAB_LIST>(TAB_LIST.MAINNET);
  const [showTestNet, setShowTestNet] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const storageChainId = getItemFromStorage(STORAGE_KEYS.NETWORK);
  const { init, eoaAddress, userSettings } = useWallet();

  useEffect(() => {
    const fetchShowTestNet = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: any) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { showTestnet: showTestnetInDB } =
            usersSettings[userIndex].settings.developerMode;

          setShowTestNet(showTestnetInDB);
        }
      } catch (error) {
        log("Error fetching showTestNet:", error);
      }
    };

    fetchShowTestNet();
  }, []);

  const handleNetworkSwitch = async (
    chainId: number,
    chainName: string,
    rpcUrl: string,
  ) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

      const { chainId: network } = await provider.getNetwork();

      if (Number(network) !== Number(chainId))
        throw new Error(ERROR_MESSAGE.INCORRECT_RPC);
    } catch (e) {
      toast.error(ERROR_TOAST_MESSAGES.NETWORK_ERROR_TOAST);
      return;
    }

    await setItemInStorage(STORAGE_KEYS.OLD_NETWORK, storageChainId);
    init({ chainId, walletName: userSettings?.walletName || "" });

    MixPanel.track(MIXPANEL_KEY.Chains_Switched, {
      chainId,
      chainName,
    });

    await setItemInStorage(STORAGE_KEYS.NETWORK, chainId);
  };

  const handleTabClick = async (tab: TAB_LIST) => {
    setActiveTab(tab);
  };

  const renderChains = (chain: any) => (
    <motion.div key={chain.chainId}>
      <div
        className={`flex w-full mx-auto py-4 bg-secondary-bg hover:bg-card-bg2 hover:bg-opacity-50 cursor-pointer select-none ${
          Number(chain.chainId) === Number(storageChainId)
            ? "border-l-4 border-zinc-700 "
            : ""
        }`}
        onClick={async () => {
          if (Number(chain.chainId) !== Number(storageChainId))
            await handleNetworkSwitch(chain.chainId, chain.name, chain.rpc);

          onClose();
        }}
      >
        <div className="w-[20%] flex justify-center items-center">
          <img
            className="h-8 rounded-full"
            src={chain.chainUri}
            alt={`${chain.name} logo`}
          />
        </div>
        <h1 className="my-auto text-md">{chain.name}</h1>
      </div>
    </motion.div>
  );

  function renderFilteredChainsMainnet(value: string) {
    const valueLower = value.toLowerCase();
    const matchingChains: any = [];
    const nonMatchingChains: any = [];

    Chains.forEach((chain) => {
      if (!chain.isMainnet) return;

      const chainNameLower = chain.name.toLowerCase();
      let score = 0;

      if (chainNameLower.includes(valueLower)) {
        // Calculate score only for matching chains
        score = 1000 - chainNameLower.indexOf(valueLower);
        if (chain.isEnabled) {
          score += 100;
          matchingChains.push({ ...chain, score });
        }
      } else {
        // Keep non-matching chains separate without a score
        nonMatchingChains.push(chain);
      }
    });

    // Sort only the matching chains by score in descending order
    const sortedMatchingChains = matchingChains.sort(
      (a: any, b: any) => b.score - a.score,
    );

    // Merge matching and non-matching chains, with matching ones first
    const finalChains = sortedMatchingChains.concat(nonMatchingChains);

    // Map to renderChains function
    return finalChains.map((chain: any) => renderChains(chain));
  }

  function renderFilteredChainsTestnet(value: string) {
    const valueLower = value.toLowerCase();
    const matchingChains: any = [];
    const nonMatchingChains: any = [];

    Chains.forEach((chain) => {
      if (chain.isMainnet) return;

      const chainNameLower = chain.name.toLowerCase();
      let score = 0;

      if (chainNameLower.includes(valueLower)) {
        // Calculate score only for matching chains
        score = 1000 - chainNameLower.indexOf(valueLower);
        if (chain.isEnabled) {
          score += 100;
          matchingChains.push({ ...chain, score });
        }
      } else {
        // Keep non-matching chains separate without a score
        nonMatchingChains.push(chain);
      }
    });

    // Sort only the matching chains by score in descending order
    const sortedMatchingChains = matchingChains.sort(
      (a: any, b: any) => b.score - a.score,
    );

    // Merge matching and non-matching chains, with matching ones first
    const finalChains = sortedMatchingChains.concat(nonMatchingChains);

    // Map to renderChains function
    return finalChains.map((chain: any) => renderChains(chain));
  }

  return (
    <>
      <motion.div
        initial={{ y: "0.8%" }}
        animate={{ y: isOpen ? 0 : "100%" }}
        exit={{ y: "100%" }}
        transition={{ type: "tween", duration: 0.3 }}
        className={`absolute h-[600px] inset-0 flex items-start justify-center py-2 px-2  rounded-lg z-30 ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className="fixed inset-0 bg-primary-bg opacity-50 "></div>
        <div className="bg-secondary-bg h-full w-full shadow-lg relative rounded-md ">
          <div className="flex justify-between mb-5 px-4 pt-3">
            <div className="text-lg font-bold m-auto select-none">
              Select a Network
            </div>
            <button
              className="hover:opacity-70 text-xl"
              onClick={() => {
                onClose();
              }}
            >
              <X style={{ color: "#FFFFFF", fill: "#FFFFFF" }} />
            </button>
          </div>

          <div className="flex items-center w-[90%] mx-auto border border-gray-300 rounded-lg mt-2 mb-4 p-2">
            <button className="min-w-fit pr-1 opacity-60">
              <Search className="h-5 mx-auto my-auto" />
            </button>
            <input
              type="text"
              placeholder="Search..."
              className="w-full focus:outline-none pl-1 bg-transparent"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>

          <div className="flex space-x-4 w-full justify-between items-center pb-2 px-8 gap-6 text-md tracking-wide">
            {showTestNet && (
              <>
                <div
                  className={`cursor-pointer px-4 py-2 w-full text-center ${
                    activeTab === TAB_LIST.MAINNET
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : ""
                  }`}
                  onClick={() => handleTabClick(TAB_LIST.MAINNET)}
                >
                  Mainnets
                </div>

                <div
                  className={`cursor-pointer text-md truncate px-4 py-2 w-full text-center ${
                    activeTab === TAB_LIST.TESTNET
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : ""
                  }`}
                  onClick={() => handleTabClick(TAB_LIST.TESTNET)}
                >
                  Testnets
                </div>
              </>
            )}
          </div>

          {activeTab === TAB_LIST.TESTNET && showTestNet ? (
            <div className={`flex flex-col w-full h-[380px] overflow-y-scroll`}>
              <AnimatePresence>
                {renderFilteredChainsTestnet(searchValue)}
              </AnimatePresence>
            </div>
          ) : (
            <div
              className={`flex flex-col w-full ${
                showTestNet ? "h-[380px]" : "h-[430px]"
              }  overflow-y-scroll`}
            >
              {Chains.filter((chain) => chain.isMainnet && chain.isEnabled)
                .length <= 0 ? (
                <ComingSoon />
              ) : (
                <AnimatePresence>
                  {renderFilteredChainsMainnet(searchValue)}
                </AnimatePresence>
              )}
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default ChainSelection;
